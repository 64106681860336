import BaseModel from '@/entities/BaseModel.ts';

export default class Suppliable extends BaseModel {
    additional_information: AdditionalInformation = {};

    company: string | null = null;

    display_name: string | null = null;

    export_response: string | null = null;

    exported: boolean = false;

    exported_on: string | null = null;

    import_response: string | null = null;

    imported: boolean = false;

    imported_on: string | null = null;

    name: string | null = null;

    related_id: string | null = null;

    status: string | null = null;

    suppliable_id: number | null = null;

    supplier_id: number | null = null;

    updated_at: string | null = null;

    constructor(initialValues: any = {}) {
        super();

        this.init(initialValues);
    }
}

interface AdditionalInformation {
    type?: string;
}
