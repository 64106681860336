<template>
    <div>
        <activix-modal
            portal="modal-event"
            :opened="opened"
            :closable="isClosable"
            :loading="showLoading"
            :loading-spinner="false"
            :destroyable="true"
            @open="onOpen"
            @opened="onOpened"
            @close="close"
            @closed="onClosed"
            @canceled="onCanceled"
        >
            <template slot="header">
                <h4
                    class="modal-title | flex items-center justify-center"
                    :class="{ 'mr-6': mdLayout && taskEvent.automation_action_id }"
                >
                    {{ modalTitle }}

                    <router-link :to=" {name: 'automations.update', params: {id: taskEvent.automation_action.automation_id }} " v-if="taskEvent.automation_action_id">
                        <activix-tooltip :content="$t('modal.modifyAutomatedEventTask')">
                            <icon class="ml-2 text-base" name="regular/video-game-magic-wand" />
                        </activix-tooltip>
                    </router-link>
                </h4>
            </template>

            <template slot="body">
                <div class="-mb-4 -mx-3">
                    <div class="alert alert-danger" v-if="hasValidationErrors">
                        <div class="font-bold">
                            {{ $t('modal.errors') }}
                        </div>
                        <ul>
                            <li :key="index" v-for="(error, index) in validationErrors">
                                {{ error }}
                            </li>
                        </ul>
                    </div>

                    <!-- Type / Next step -->
                    <div class="flex items-center mb-4">
                        <label class="col-xs-3 | text-right m-0">
                            {{ isNextStep ? $t('modal.nextStep') : $t('modal.type') }}
                            <span class="form-star" v-if="!isNextStep">*</span>
                        </label>

                        <div class="col-xs-9 col-sm-7">
                            <div class="flex">
                                <activix-tooltip :content="isMainEvent ? $t('modal.disabledTaskType') : ''">
                                    <activix-multiselect
                                        class="attached-r flex-1"
                                        :disabled="isReadOnly || isMainEvent"
                                        :options="formattedTaskEventTypes"
                                        :selected="selectedTaskEventType"
                                        @update="updateTaskEventType($event)"
                                    />
                                </activix-tooltip>
                                <activix-tooltip :content="contactableDisabledTooltip || leadIsAlreadyCommunicatingTooltip" v-if="contactableEvent">
                                    <div class="flex">
                                        <activix-button
                                            class="attached-l"
                                            :disabled="leadIsAlreadyCommunicating"
                                            @click="openContactModal"
                                        >
                                            <icon :name="contactableIcon" />
                                        </activix-button>
                                    </div>
                                </activix-tooltip>

                                <div class="flex" v-if="isTaskTypeCSI">
                                    <activix-tooltip :content="!hasValidPhones ? $t('modal.no_phone') : ''">
                                        <div>
                                            <activix-button
                                                class="attached-l attached-r h-full"
                                                :disabled="!hasValidPhones"
                                                @click="openContactModal('phone')"
                                            >
                                                <icon name="regular/phone" />
                                            </activix-button>
                                        </div>
                                    </activix-tooltip>
                                    <activix-tooltip :content="!hasValidEmails ? $t('modal.no_email') : ''">
                                        <div>
                                            <activix-button
                                                class="attached-l attached-r h-full"
                                                :disabled="!hasValidEmails"
                                                @click="openContactModal('email')"
                                            >
                                                <icon name="regular/email-action-unread" />
                                            </activix-button>
                                        </div>
                                    </activix-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Child account -->
                    <div class="flex items-center mb-4" v-if="isParentAccount">
                        <label class="col-xs-3 | text-right m-0">
                            {{ $t('modal.account') }}
                            <span class="form-star" v-if="!isNextStep">*</span>
                        </label>
                        <div class="col-xs-9 col-sm-7">
                            <activix-multiselect
                                :disabled="isSelected"
                                :options="formattedChildAccounts"
                                :selected="selectedAccount"
                                @update="updateSelectedAccount"
                            />
                        </div>
                    </div>

                    <div v-if="isValidType">
                        <!-- Test drive or delivery location / address -->
                        <div class="flex items-center mb-4" v-if="hasLocation">
                            <label class="col-xs-3 | text-right m-0">
                                {{ $t('modal.location') }}
                            </label>

                            <activix-radio
                                class="ml-3 mr-2"
                                name="taskEventLocation"
                                value="dealership"
                                :disabled="isReadOnly"
                                :model-value="taskEvent.location"
                                @input="taskEvent.location = $event"
                            />

                            {{ $t('taskEventLocations.dealership') }}

                            <activix-radio
                                class="ml-3 mr-2"
                                name="taskEventLocation"
                                value="customer"
                                :disabled="isReadOnly"
                                :model-value="taskEvent.location"
                                @input="taskEvent.location = $event"
                            />

                            {{ $t('taskEventLocations.customer') }}

                            <activix-radio
                                class="ml-3 mr-2"
                                name="taskEventLocation"
                                value="custom"
                                :disabled="isReadOnly"
                                :model-value="taskEvent.location"
                                @input="taskEvent.location = $event"
                            />

                            {{ $t('taskEventLocations.custom') }}
                        </div>

                        <div class="flex items-center mb-4" v-if="hasAddress">
                            <label class="col-xs-3 | text-right m-0">
                                {{ $t('modal.address') }}
                            </label>

                            <div class="col-xs-9 col-sm-7">
                                <activix-input
                                    type="text"
                                    :disabled="!hasCustomAddress || isReadOnly"
                                    v-model="taskEvent.address"
                                    v-if="hasCustomAddress"
                                />
                                <activix-input
                                    type="text"
                                    :disabled="true"
                                    :value="leadAddress"
                                    :placeholder="$t('modal.noAddress')"
                                    v-else
                                />
                            </div>
                        </div>

                        <!-- Vehicles -->
                        <div class="flex items-center mb-4" key="vehicles" v-if="isEvent">
                            <label class="col-xs-3 | text-right m-0">
                                {{ $t('modal.vehicles') }}
                            </label>

                            <div class="col-xs-9 col-sm-7">
                                <activix-multiselect
                                    :allow-empty="true"
                                    :close-on-select="false"
                                    :disabled="isReadOnly"
                                    :multiple="true"
                                    :options="formattedWantedVehicles"
                                    :selected="selectedVehicles"
                                    :taggable="true"
                                    @update="updateVehicles"
                                />
                            </div>
                        </div>

                        <!-- Client -->
                        <div class="flex items-center mb-4">
                            <label class="col-xs-3 | text-right m-0">
                                {{ $t('modal.client') }}
                                <span class="form-star" v-if="!isOther">*</span>
                            </label>

                            <div class="col-xs-9 col-sm-7">
                                <div class="flex">
                                    <input
                                        readonly
                                        type="text"
                                        class="form-control | attached-r"
                                        :class="{
                                            'cursor-pointer': canOpenClientCard,
                                            'cursor-pointer text-blue-500 | hover:text-blue-400': leadUrl,
                                        }"
                                        :value="leadName"
                                        @click.exact="openLeadOrModal(false)"
                                        @click.middle.exact="openLeadOrModal(true)"
                                        @click.meta.exact="openLeadOrModal(true)"
                                        @click.ctrl.exact="openLeadOrModal(true)"
                                    />
                                    <activix-tooltip :content="$t('modal.newTab')" v-if="!!leadUrl && !$browser.isMobileWebView()">
                                        <activix-button
                                            class="attached-l"
                                            :class="{ 'attached-r': !forcedLeadId }"
                                            @click="openLeadOrModal(true)"
                                        >
                                            <icon name="regular/expand-6" />
                                        </activix-button>
                                    </activix-tooltip>
                                    <activix-tooltip :content="$t('searchLead.chooseALead')" v-if="!forcedLeadId">
                                        <activix-button
                                            class="attached-l"
                                            :disabled="isReadOnly || taskEvent.automation_action_id != null"
                                            @click="modals.searchLeadOpened = true"
                                        >
                                            <icon name="regular/composition-window-man" />
                                        </activix-button>
                                    </activix-tooltip>
                                </div>
                            </div>
                        </div>

                        <!-- Owner -->
                        <div class="flex items-center mb-4" key="owner">
                            <label class="col-xs-3 | text-right m-0">
                                {{ $t('modal.task_owner') }}
                                <span class="form-star">*</span>
                            </label>

                            <div class="col-xs-9 col-sm-7">
                                <activix-multiselect
                                    :allow-empty="false"
                                    :custom-label="userFormater"
                                    :disabled="isReadOnly || authUser.isAdvisor()"
                                    :options="ownerUsers"
                                    :selected="selectedOwner"
                                    @update="value => (taskEvent.userId = value.id)"
                                />
                            </div>
                        </div>

                        <!-- Guests -->
                        <div class="flex items-center mb-4" key="guest" v-if="!isTask && isValidType">
                            <label class="col-xs-3 | text-right m-0">
                                {{ $t('modal.guests') }}
                            </label>

                            <div class="col-xs-9 col-sm-7">
                                <activix-multiselect
                                    :allow-empty="true"
                                    :close-on-select="false"
                                    :custom-label="guestFormater"
                                    :disabled="isReadOnly"
                                    :multiple="true"
                                    :options="guestUsers"
                                    :selected="selectedGuests"
                                    :taggable="true"
                                    @update="onUpdateGuest"
                                />
                            </div>
                        </div>

                        <!-- Delivered by -->
                        <div class="flex items-center mb-4" v-if="isDelivery">
                            <label class="col-xs-3 | text-right m-0">
                                {{ $t('clientCard.deliveredBy') }}
                            </label>

                            <div class="col-xs-9 col-sm-7">
                                <activix-multiselect
                                    :allow-empty="true"
                                    :custom-label="userFormater"
                                    :disabled="isReadOnly || !$can('leads.delivered_by_id:update')"
                                    :options="deliveryUsers"
                                    :selected="selectedDeliverer"
                                    @update="value => (lead.deliveredById = value.id)"
                                />
                            </div>
                        </div>

                        <!-- Title -->
                        <div class="flex items-center mb-4">
                            <label class="col-xs-3 | text-right m-0">
                                {{ $t('modal.title') }}
                                <span class="form-star">*</span>
                            </label>

                            <div class="col-xs-9 col-sm-7">
                                <input
                                    type="text"
                                    class="form-control"
                                    maxlength="255"
                                    :disabled="isReadOnly"
                                    v-model="taskEvent.title"
                                />
                            </div>
                        </div>

                        <!-- Priority -->
                        <div class="flex items-center mb-4" key="priority">
                            <label class="col-xs-3 | text-right m-0">
                                {{ $t('modal.priority') }}
                            </label>

                            <div class="col-xs-9 col-sm-7">
                                <activix-multiselect
                                    identifier="value"
                                    :allow-empty="false"
                                    :disabled="isReadOnly"
                                    :options="formattedPriorities"
                                    :selected="selectedPriority"
                                    @update="value => (taskEvent.priority = value.value)"
                                />
                            </div>
                        </div>

                        <!-- Description -->
                        <div class="flex items-center mb-4">
                            <label class="col-xs-3 | text-right m-0">
                                {{ $t('modal.desc') }}
                            </label>

                            <div class="col-xs-9 col-sm-7">
                                <textarea
                                    class="form-control"
                                    rows="3"
                                    maxlength="250"
                                    :disabled="isReadOnly"
                                    v-model="taskEvent.description"
                                />
                            </div>
                        </div>

                        <!-- Start at -->
                        <div class="flex items-center mb-4">
                            <label class="col-xs-3 | text-right m-0">
                                {{ isEvent ? $t('modal.start_at') : $t('modal.date') }}
                                <span class="form-star">*</span>
                            </label>

                            <div class="col-xs-9 col-sm-7">
                                <date-time-picker
                                    picker-position="top-left"
                                    :disabled="isReadOnly"
                                    :value="taskEvent.start_at"
                                    :start-date="startDate"
                                    @input="updateStartAt"
                                />
                            </div>
                        </div>

                        <!-- End at -->
                        <div class="flex items-center mb-4" v-if="(isEvent || isOther) && isValidType">
                            <label class="col-xs-3 | text-right m-0">
                                {{ $t('modal.end_at') }}
                                <span class="form-star">*</span>
                            </label>

                            <div class="col-xs-9 col-sm-7">
                                <date-time-picker
                                    picker-position="top-left"
                                    :disabled="isReadOnly"
                                    :value="taskEvent.end_at"
                                    :start-date="startDate"
                                    @input="updateEndAt"
                                />
                            </div>
                        </div>

                        <div class="flex items-center mb-4" v-if="showTimeZoneWarning">
                            <time-zone-difference-warning
                                :start-time="taskEvent.start_at"
                                :end-time="taskEndTime"
                                :time-zone="lead.account.timezone"
                                :city="lead.account.city"
                                class="col-xs-9 col-sm-7 col-xs-offset-3"
                            />
                        </div>

                        <div class="flex items-center mb-4" v-if="showConfirmation">
                            <!-- Send confirmation -->
                            <label class="col-xs-3 | text-right m-0">
                                {{ $t('modal.sendConfirmation') }}
                            </label>
                            <div class="col-xs-9 col-sm-7 | flex items-center">
                                <activix-tooltip :content="sendConfirmationTooltip">
                                    <activix-checkbox
                                        class="inline-flex"
                                        :disabled="disabledSendConfirmation"
                                        v-model="sendConfirmation"
                                    />
                                </activix-tooltip>

                                <!-- Confirmation time frame -->
                                <div class="flex-1 pl-2">
                                    <activix-tooltip
                                        class="flex-1"
                                        :disabled="!sendConfirmationTooltip"
                                        :content="sendConfirmationTooltip"
                                    >
                                        <nio-text-timeframe
                                            :timeframe="taskEvent.confirmation_timeframe"
                                            :disabled="disabledSendConfirmation || !sendConfirmation"
                                            @update="taskEvent.confirmation_timeframe = $event"
                                        />
                                    </activix-tooltip>
                                </div>
                            </div>
                        </div>

                        <div class="flex items-center mb-4" v-if="showIcsInvite">
                            <!-- ICS invitation -->
                            <label class="col-xs-3 | text-right m-0">
                                {{ $t('modal.icsInvite') }}
                            </label>
                            <div>
                                <activix-tooltip class="col-xs-3" :content="icsTooltip">
                                    <activix-checkbox
                                        :disabled="icsIsDisabled"
                                        v-model="taskEvent.send_ics"
                                    />
                                </activix-tooltip>
                            </div>
                        </div>

                        <div class="flex items-center mb-4" v-if="isAppointment || isPhoneAppointment">
                            <!-- Confirmed -->
                            <label class="col-xs-3 | text-right m-0">
                                {{ $t('modal.confirmed') }}
                            </label>
                            <div class="flex items-center" :class="isPhoneAppointment ? 'col-xs-1' : 'col-xs-3'">
                                <activix-tooltip :content="confirmedTooltip">
                                    <activix-checkbox
                                        :disabled="confirmedIsDisabled"
                                        @click="trackStatusUpdate"
                                        v-model="taskEvent.confirmed"
                                    />
                                </activix-tooltip>
                            </div>

                            <!-- Completed -->
                            <template v-if="isPhoneAppointment">
                                <label class="col-xs-2 | text-right m-0">
                                    {{ $t('modal.completed') }}
                                </label>
                                <div class="col-xs-1 | flex items-center">
                                    <activix-tooltip :content="$t('modal.completed')">
                                        <activix-checkbox
                                            :disabled="(isReadOnly && !isInvited) || taskEvent.no_show || taskEvent.canceled"
                                            @click="trackStatusUpdate"
                                            v-model="taskEvent.status"
                                        />
                                    </activix-tooltip>
                                </div>
                            </template>

                            <!-- Canceled -->
                            <label class="text-right m-0" :class="isPhoneAppointment ? 'col-xs-2' : 'col-xs-3'">
                                {{ $t('modal.canceled') }}
                            </label>
                            <div class="col-xs-1 | flex items-center">
                                <activix-tooltip :content="$t('modal.canceledTooltip')">
                                    <activix-checkbox
                                        :disabled="canceledDisabled"
                                        @click="trackStatusUpdate"
                                        v-model="taskEvent.canceled"
                                    />
                                </activix-tooltip>
                            </div>
                        </div>

                        <div class="flex items-center mb-4">
                            <!-- Gas -->
                            <template v-if="showGas">
                                <label class="col-xs-3 | text-right m-0">
                                    {{ $t('saleTable.gas') }}
                                </label>
                                <div class="col-xs-1 | flex items-center">
                                    <activix-checkbox :disabled="!$can('leads.gas:update')" v-model="lead.gas" />
                                </div>
                            </template>

                            <template v-if="showPrepared">
                                <label class="col-xs-2 | text-right m-0">
                                    {{ $t('clientCard.prepared') }}
                                </label>
                                <div class="col-xs-1 | flex items-center">
                                    <activix-checkbox
                                        :disabled="taskEvent.no_show || !$can('leads.prepared:update')"
                                        v-model="lead.prepared"
                                    />
                                </div>
                            </template>

                            <!-- Done -->
                            <template v-if="showDone">
                                <label class="text-right m-0" :class="showPrepared ? 'col-xs-2' : 'col-xs-3'">
                                    {{ doneTitle }}
                                </label>
                                <div class="col-xs-1 | flex items-center">
                                    <activix-tooltip :content="statusTooltip">
                                        <activix-checkbox
                                            :disabled="statusIsDisabled"
                                            @click="trackStatusUpdate"
                                            v-model="taskEvent.status"
                                        />
                                    </activix-tooltip>
                                </div>
                            </template>

                            <!-- No show -->
                            <template v-if="showNoShow">
                                <label class="col-xs-5 | text-right m-0">
                                    {{ $t('modal.noShow') }}
                                </label>
                                <div class="col-xs-1 | flex items-center">
                                    <activix-tooltip :content="$t('modal.noShowTooltip')">
                                        <activix-checkbox
                                            :disabled="(isReadOnly && !isInvited) || taskEvent.canceled || !!taskEvent.status"
                                            @click="trackStatusUpdate"
                                            v-model="taskEvent.no_show"
                                        />
                                    </activix-tooltip>
                                </div>
                            </template>
                        </div>

                        <!-- Main event date -->
                        <div class="flex items-center mb-4" v-if="showMainEventDate">
                            <label class="col-xs-3 | text-right m-0">
                                {{ $t('modal.date') }}
                                <span class="form-star">*</span>
                            </label>
                            <div class="col-xs-9 col-sm-7">
                                <date-time-picker
                                    picker-position="top-left"
                                    :disabled="isReadOnly && isInDelivery && !authUserIsTheDeliveryMan"
                                    :end-date="currentTime"
                                    v-model="mainEventDate"
                                >
                                    <template #before-button v-if="isAppointment && !lead.isService()">
                                        <activix-tooltip :content="$t('filters.presented.otherPresented')">
                                            <span class="w-12 attached-l attached-r">
                                                <activix-button
                                                    class="h-full"
                                                    :active="isOtherPresented"
                                                    @click="isOtherPresented = !isOtherPresented"
                                                >
                                                    <icon name="regular/house-chimney-2" />
                                                </activix-button>
                                            </span>
                                        </activix-tooltip>
                                    </template>
                                </date-time-picker>
                            </div>
                        </div>

                        <hr />

                        <div
                            class="flex items-center mb-4"
                            v-if="!lgLayout || (lgLayout && !isReadOnly && taskEvent.userId)"
                        >
                            <div class="text-center col-xs-12">
                                <a :class="{ disabled: isReadOnly || !taskEvent.userId }" @click="addReminder">
                                    + {{ $t('modal.addReminder') }}
                                </a>
                            </div>
                        </div>

                        <div
                            class="flex flex-wrap items-center mb-4"
                            :key="reminder.id"
                            v-for="(reminder, index) in filteredReminders"
                        >
                            <label class="col-xs-3 | text-right m-0">
                                {{ $t('modal.reminder') }}
                            </label>

                            <div class="col-xs-9 col-sm-7">
                                <date-time-picker
                                    picker-position="top-left"
                                    :disabled="isReadOnly"
                                    v-model="reminder.date"
                                />
                            </div>

                            <div class="col-xs-offset-3 col-xs-9 col-sm-7 | flex items-center mt-2">
                                <div class="flex-1 flex mr-4 | md:justify-between">
                                    <template v-if="mdLayout">
                                        <activix-tooltip :content="$t('modal.notification')">
                                            <icon
                                                class="ml-2 text-2xl"
                                                :class="reminder.notification ? 'text-blue-500' : 'text-grey-600'"
                                                name="regular/screen-1"
                                                @click="
                                                    () => {
                                                        !isReadOnly
                                                            ? (reminder.notification = !reminder.notification)
                                                            : '';
                                                    }
                                                "
                                                v-if="lgLayout"
                                            />
                                            <activix-checkbox
                                                class="flex items-center ml-2"
                                                size="sm"
                                                :disabled="isReadOnly"
                                                v-model="reminder.notification"
                                                v-else
                                            >
                                                <icon class="text-lg text-grey-600" name="regular/screen-1" />
                                            </activix-checkbox>
                                        </activix-tooltip>

                                        <activix-tooltip :content="$t('clientCard.sms')">
                                            <icon
                                                class="ml-8 text-2xl"
                                                :class="reminder.sms ? 'text-blue-500' : 'text-grey-600'"
                                                :name="$icons.sms"
                                                @click="
                                                    () => {
                                                        !isReadOnly ? (reminder.sms = !reminder.sms) : '';
                                                    }
                                                "
                                                v-if="lgLayout"
                                            />
                                            <activix-checkbox
                                                class="flex items-center ml-6"
                                                size="sm"
                                                :disabled="isReadOnly"
                                                v-model="reminder.sms"
                                                v-else
                                            >
                                                <icon class="text-lg text-grey-600" :name="$icons.sms" />
                                            </activix-checkbox>
                                        </activix-tooltip>

                                        <activix-tooltip :content="$t('clientCard.push')">
                                            <icon
                                                class="ml-8 text-2xl"
                                                :class="reminder.push ? 'text-blue-500' : 'text-grey-600'"
                                                name="regular/mobile-phone"
                                                @click="
                                                    () => {
                                                        !isReadOnly ? (reminder.push = !reminder.push) : '';
                                                    }
                                                "
                                                v-if="lgLayout"
                                            />
                                            <activix-checkbox
                                                class="flex items-center ml-6"
                                                size="sm"
                                                :disabled="isReadOnly"
                                                v-model="reminder.push"
                                                v-else
                                            >
                                                <icon class="text-lg text-grey-600" name="regular/mobile-phone" />
                                            </activix-checkbox>
                                        </activix-tooltip>

                                        <activix-tooltip :content="$t('clientCard.email')">
                                            <icon
                                                class="ml-8 text-2xl"
                                                :class="reminder.email ? 'text-blue-500' : 'text-grey-600'"
                                                :name="$icons.email"
                                                @click="
                                                    () => {
                                                        !isReadOnly ? (reminder.email = !reminder.email) : '';
                                                    }
                                                "
                                                v-if="lgLayout"
                                            />
                                            <activix-checkbox
                                                class="flex items-center ml-6"
                                                size="sm"
                                                :disabled="isReadOnly"
                                                v-model="reminder.email"
                                                v-else
                                            >
                                                <icon class="text-lg text-grey-600" :name="$icons.email" />
                                            </activix-checkbox>
                                        </activix-tooltip>
                                    </template>

                                    <template v-else>
                                        <activix-checkbox
                                            class="flex"
                                            :disabled="isReadOnly"
                                            v-model="reminder.notification"
                                        >
                                            {{ $t('modal.notification') }}
                                        </activix-checkbox>
                                        <activix-checkbox class="flex" :disabled="isReadOnly" v-model="reminder.sms">
                                            {{ $t('modal.sms') }}
                                        </activix-checkbox>
                                        <activix-checkbox class="flex" :disabled="isReadOnly" v-model="reminder.push">
                                            {{ $t('modal.push') }}
                                        </activix-checkbox>
                                        <activix-checkbox class="flex" :disabled="isReadOnly" v-model="reminder.email">
                                            {{ $t('modal.email') }}
                                        </activix-checkbox>
                                    </template>
                                </div>

                                <icon
                                    :name="$icons.trash"
                                    class="link-grey-light | hover:text-red-500"
                                    @click="deleteReminder(index)"
                                    v-if="!isReadOnly"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="flex items-center mb-4" v-if="isValidType">
                        <div class="col-xs-offset-3 col-xs-9 col-sm-7">
                            <div class="relative">
                                <textarea
                                    class="form-control"
                                    :class="{ 'pr-8': lead.id }"
                                    :disabled="isReadOnly && !isInvited"
                                    :placeholder="$t('clientCard.addComment')"
                                    v-model="nextStepComment"
                                />
                                <activix-tooltip :content="$t('modal.allNotes')" v-if="lead.id">
                                    <button
                                        class="flex absolute right-0 top-0 mt-3 mr-4 text-sm link-grey"
                                        @click="$modal.show('modalEvent:comments', lead.id)"
                                    >
                                        <icon name="regular/notes-book-text" />
                                    </button>
                                </activix-tooltip>
                            </div>
                        </div>
                    </div>

                    <div class="mt-4" v-if="showVirtualConferenceLinks && virtualAppointmentAdvisorLink && virtualAppointmentCustomerLink">
                        <hr />
                        <div class="flex items-center mb-4">
                            <div class="text-center col-xs-12">
                                <div class="relative">
                                    <h5 class="m-0">
                                        {{ $t('modal.virtualAppointment') }}
                                    </h5>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="flex items-center mb-4">
                                <label class="col-xs-3 | text-right m-0">{{ $t('modal.virtualAppointmentAdvisorLink') }}</label>
                                <div class="col-xs-9 col-sm-7 flex flex-column">
                                    <input
                                        type="text"
                                        class="form-control | flex-1"
                                        maxlength="255"
                                        readonly
                                        v-model="virtualAppointmentAdvisorLink"
                                    />
                                    <activix-tooltip :content="$t('modal.newTab')" v-if="!$browser.isMobileWebView()">
                                        <activix-button
                                            class="w-12 attached-l bg-gray-100 border-gray-100"
                                            @click="openVirtualAppointmentAdvisorLink"
                                        >
                                            <icon name="regular/expand-6" />
                                        </activix-button>
                                    </activix-tooltip>

                                    <activix-tooltip :content="$t('general.copyToClipboard')">
                                        <activix-button
                                            class="w-12 attached-l bg-gray-100 border-gray-100"
                                            @click="copyVirtualAppointmentAdvisorLinkToClipboard"
                                        >
                                            <icon name="regular/copy-paste" />
                                        </activix-button>
                                    </activix-tooltip>
                                </div>
                            </div>
                            <div class="flex items-center mb-4">
                                <label class="col-xs-3 | text-right m-0">{{ $t('modal.virtualAppointmentCustomerLink') }}</label>
                                <div class="col-xs-9 col-sm-7 flex flex-column">
                                    <input
                                        type="text"
                                        class="form-control | flex-1"
                                        maxlength="255"
                                        readonly
                                        v-model="virtualAppointmentCustomerLink"
                                    />
                                    <activix-tooltip :content="$t('modal.newTab')" v-if="!$browser.isMobileWebView()">
                                        <activix-button
                                            class="w-12 attached-l bg-gray-100 border-gray-100"
                                            @click="openVirtualAppointmentCustomerLink"
                                        >
                                            <icon name="regular/expand-6" />
                                        </activix-button>
                                    </activix-tooltip>
                                    <activix-tooltip :content="$t('general.copyToClipboard')">
                                        <activix-button
                                            class="w-12 attached-l bg-gray-100 border-gray-100"
                                            @click="copyVirtualAppointmentCustomerLinkToClipboard"
                                        >
                                            <icon name="regular/copy-paste" />
                                        </activix-button>
                                    </activix-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template slot="footer">
                <div class="flex items-center" :class="showTrash ? 'justify-between' : 'justify-center'">
                    <icon
                        :name="$icons.trash"
                        class="link-grey-light | hover:text-red-500"
                        @click="modals.deleteTaskOpened = true"
                        v-if="showTrash"
                    />
                    <div>
                        <activix-button
                            :disabled="$wait.is(['creating.taskEvent', 'updating.taskEvent'])"
                            @click="cancel"
                            v-if="isClosable"
                        >
                            {{ $t('general.cancel') }}
                        </activix-button>
                        <activix-tooltip :content="savingToolTip">
                            <span class="ml-2">
                                <activix-button
                                    type="primary"
                                    :loading="$wait.is(['creating.taskEvent', 'updating.taskEvent', 'fetching.taskEventLeads'])"
                                    :disabled="!canSave && !isInvited"
                                    @click="save"
                                >
                                    {{ $t('modal.save') }}
                                </activix-button>
                            </span>
                        </activix-tooltip>
                    </div>
                </div>
            </template>
        </activix-modal>

        <search-lead
            portal="modal-event-sub"
            :opened.sync="modals.searchLeadOpened"
            :selected-account="selectedAccount.id"
            @select-lead="setLead"
        />

        <call-lead name="modalEvent:callLead" />

        <email-lead name="modalEvent:emailLead" />

        <comment-modal :users="users" name="modalEvent:comments" />

        <automated-event-modal
            :show.sync="modals.showUpdateFutureEvent"
            :payload="pendingPayload"
            @save="triggerAutomatedEventModification"
        />

        <activix-confirm-modal
            portal="modal-event-sub"
            :approve-text="$t('modal.delete')"
            :content="deleteTaskModalContent"
            :opened.sync="modals.deleteTaskOpened"
            :title="$t('modal.deleting')"
            @approve="triggerDeleteTaskEvent"
        />

        <activix-confirm-modal
            approve-text="OK"
            :opened.sync="modals.saleNeededOpened"
            :content="saleNeededMessage"
            :show-deny="false"
            type="warning"
        />

        <activix-confirm-modal
            approve-text="OK"
            :opened.sync="modals.existingDeliveryTaskOpened"
            :content="$t('modal.hasDeliveryTask')"
            :show-deny="false"
            type="warning"
        />

        <overlapping-task-event-confirm-modal
            :opened.sync="modals.showOverlappingEventsModal"
            :overlapping-events="overlappingEvents"
            @approve="approvedOverlappingEvent"
            @deny="denyOverlappingEvent"
        />
    </div>
</template>

<script>
// Utils
    import { cloneDeep, differenceBy, get, uniqBy } from 'lodash-es';
    import LeadCommunicating from '@/mixins/LeadCommunicating.js';
    import { mapActions, mapState } from 'pinia';
    import { useLeadStore } from '@/store/modules/lead/store.js';
    import { getIconMarkup } from '@/utils/icon.js';
    import UserRepository from '@/graphql/repositories/UserRepository.js';

    // Pinia
    import { useContextStore } from '@/store/modules/context/store.js';
    import { useGlobalStore } from '@/store/store.js';
    import { useClientCardStore } from '@/store/modules/clientCard/store.js';

    // Components
    import LeadVehicleType from '@/entities/LeadVehicleType.js';
    import TaskEventLocation from '@/entities/TaskEventLocation.js';
    import OverlappingTaskEventConfirmModal from '@/components/task_event/OverlappingTaskEventConfirmModal.vue';
    import TimeZoneDifferenceWarning from '@/components/timezone/TimeZoneDifferenceWarning.vue';
    import { useTimeZoneWarning } from '@/components/timezone/hooks/useTimeZoneWarning.ts';
    import { findContextAccount } from '@/pinia/storeHelper.js';
    import DateTimePicker from '../inputs/DateTimePicker.vue';
    import NioTextTimeframe from '../nio_text/NioTextTimeframe.vue';
    import SearchLead from './SearchLeads.vue';
    import CallLead from './CallLead.vue';
    import EmailLead from './EmailLead.vue';
    import AutomatedEventModal from './AutomatedEventModal.vue';
    import CommentModal from './CommentModal.vue';

    // Entities
    import DeliveryTimeframe from '../../entities/DeliveryTimeframe.js';
    import Division from '../../entities/Division.js';
    import Lead from '../../entities/Lead.js';
    import LeadType from '../../entities/LeadType.js';
    import Reminder from '../../entities/Reminder.js';
    import TaskEvent from '../../entities/TaskEvent.js';
    import TaskEventType from '../../entities/TaskEventType.js';
    import TaskEventTypeIcon from '../../entities/TaskEventTypeIcon.js';
    import User from '../../entities/User.js';

    const defaults = {
        lead: new Lead(),
        taskEvent: new TaskEvent(),

        afterCallNextStep: false,
        appointmentCall: false,
        validationErrors: [],
        mainEventDate: '',
        nextStepComment: '',
        sendConfirmation: true,
        defaultLead: null,
        specificTypeGroup: null,
        userSelectedStartDate: null,
        updateAutomatedSiblings: null,
    };

    export default {
        components: {
            TimeZoneDifferenceWarning,
            OverlappingTaskEventConfirmModal,
            CommentModal,
            CallLead,
            EmailLead,
            DateTimePicker,
            NioTextTimeframe,
            SearchLead,
            AutomatedEventModal,
        },

        mixins: [LeadCommunicating],

        data() {
            return {
                currentTime: now().toString(),
                isNextStep: false,
                isOtherPresented: false,
                initialOwnerId: null,
                opened: false,
                pendingPayload: {},
                users: [],
                specificTypeGroup: null,
                canAddAppointmentOnSave: false,
                modals: {
                    searchLeadOpened: false,
                    deleteTaskOpened: false,
                    showUpdateFutureEvent: false,
                    saleNeededOpened: false,
                    existingDeliveryTaskOpened: false,
                    showOverlappingEventsModal: false,
                },
                selectedAccount: {},
                selectedGuests: [],
                shouldOpenNextStepOnClose: false,
                taskOwner: new User(),
                deliveredBy: new User(),
                advisor: new User(),
                overlappingEvents: [],
                homepage: false,

                ...cloneDeep(defaults),
            };
        },

        computed: {
            ...mapState(useClientCardStore, ['authorizedAction']),
            ...mapState(useGlobalStore, ['parentAuthUser', 'configs', 'authUser', 'guest']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextLeadId: 'leadId',
            }),
            ...mapState(useLeadStore, ['communicatingLead']),

            savingToolTip() {
                if (this.verifiedSale) {
                    return this.$t('clientCard.verifiedSaleUneditable');
                }

                return (this.isReadOnly && !this.isInvited) && !this.canSave ? this.$t('modal.readOnlyNotAllowed') : '';
            },

            verifiedSale() {
                return !!this.lead?.disabledBySaleVerified(this.authUser);
            },

            verifiedSaleReason() {
                return this.verifiedSale ? this.$t('clientCard.verifiedSaleUneditable') : this.$t('clientCard.boxes.activities.addActivity');
            },

            currentAccountId() {
                return this.lead.account_id || this.selectedAccount.id || this.contextAccount.id;
            },

            showIcsInvite() {
                return this.$feature.isEnabled('ics-invitation') && (this.isAppointment || this.isPhoneAppointment || this.isDelivery || this.isVirtualAppointment || this.isTestDrive);
            },

            leadIsAlreadyCommunicating() {
                return !!this.communicatingLead;
            },

            leadIsAlreadyCommunicatingTooltip() {
                if (!this.leadIsAlreadyCommunicating) {
                    return '';
                }

                return this.getLeadCommunicatingMessage(this.communicatingLead);
            },

            hasValidEmails() {
                return !!this.lead.lead_emails.some(email => email.valid);
            },

            hasValidPhones() {
                return !!this.lead.lead_phones.some(phone => phone.valid);
            },

            hasDeliveryTask() {
                return !!this.lead.task_events.find(task => task.task_event_type_id == TaskEventType.DELIVERY);
            },

            leadUrl() {
                if (!this.canOpenClientCard || !this.lead.id) {
                    return '';
                }

                return {
                    name: 'leads.update',
                    params: { id: this.lead.id },
                };
            },

            leadName() {
                if (!this.lead.id) {
                    return '';
                }

                return this.lead.fullName || this.$t('general.unknown');
            },

            forcedLeadId() {
                if (this.defaultLead && this.lead.id == this.defaultLead) {
                    return this.lead.id;
                }

                return null;
            },

            hasLocation() {
                return [TaskEventType.DELIVERY, TaskEventType.TEST_DRIVE].includes(this.taskEvent.task_event_type_id);
            },

            hasAddress() {
                return [TaskEventLocation.CUSTOMER, TaskEventLocation.CUSTOM].includes(this.taskEvent.location);
            },

            hasCustomAddress() {
                return this.taskEvent.location === TaskEventLocation.CUSTOM;
            },

            leadAddress() {
                return this.lead.address1 || this.lead.address_line1;
            },

            isInDelivery() {
                return (
                    this.isDelivery &&
                    (!this.lead.sale_date || (this.lead.sale_date && this.isReadOnly && !this.authUserIsTheDeliveryMan))
                );
            },

            isMainEvent() {
                return this.taskEvent.main_event;
            },

            canOpenClientCard() {
                return !this.forcedLeadId || this.forcedLeadId != this.contextLeadId;
            },

            showTimeZoneWarning() {
                return useTimeZoneWarning().canShow(this.lead);
            },

            taskEndTime() {
                return this.isTask ? this.taskEvent.start_at : this.taskEvent.end_at;
            },

            canSave() {
                if (!this.isValidType) {
                    return false;
                }

                if (this.isDelivery && !this.lead.sale_date) {
                    return false;
                }

                if (this.isReadOnly) {
                    if (!this.isDelivery) {
                        return false;
                    }

                    if (this.verifiedSale) {
                        return false;
                    }

                    const hasGasPermission = this.showGas && this.$can('leads.gas:update');
                    const hasPreparedPermission = this.showPrepared && this.$can('leads.prepared:update');

                    if (!this.authUserIsTheDeliveryMan && !hasGasPermission && !hasPreparedPermission) {
                        return false;
                    }
                }

                if ((this.isPhoneAppointment || this.isAppointment) && this.saleVerifiedDisabled) {
                    return false;
                }

                return true;
            },

            isClosable() {
                return !this.isNextStep || !this.authUser.next_step_mandatory;
            },

            hasValidationErrors() {
                return !!this.validationErrors.length;
            },

            isUpdate() {
                return !!this.taskEvent.id;
            },

            ownerCanDeleteTask() {
                if (
                    TaskEventType.getEventTypes().includes(this.taskEvent.task_event_type_id) &&
                    this.taskEvent.created_by !== this.authUser.id &&
                    this.authUser.isAdvisor()
                ) {
                    return false;
                }

                return true;
            },

            showLoading() {
                return (
                    !this.modals.searchLeadOpened &&
                    this.$wait.is(['fetching.calendarLead', 'fetching.calendarTaskEvent'])
                );
            },

            canceledDisabled() {
                return (
                    (this.isReadOnly && !this.isInvited) ||
                    this.taskEvent.no_show ||
                    this.taskEvent.confirmed ||
                    this.taskEvent.status ||
                    this.taskEvent.completed
                );
            },

            selectedOwner() {
                return this.ownerUsers.find(u => u.id === this.taskEvent.userId) || new User();
            },

            selectedDeliverer() {
                return this.deliveryUsers.find(u => u.id == this.lead.deliveredById) || new User();
            },

            selectedTaskEventType() {
                return this.formattedTaskEventTypes.find(type => type.id == this.taskEvent.task_event_type_id);
            },

            selectedPriority() {
                return this.formattedPriorities.find(priority => priority.value == this.taskEvent.priority);
            },

            selectedVehicles() {
                if (!this.taskEvent.wanted_vehicle_ids) {
                    return [];
                }

                return this.taskEvent.wanted_vehicle_ids.map(vehicleId => {
                    return this.formattedWantedVehicles.find(vehicle => vehicle.id == vehicleId);
                });
            },

            contactableEvent() {
                return [TaskEventType.CALL, TaskEventType.EMAIL].includes(this.taskEvent.task_event_type_id);
            },

            contactableIcon() {
                if (this.taskEvent.task_event_type_id == TaskEventType.CALL) {
                    return 'regular/phone';
                }

                if (this.taskEvent.task_event_type_id == TaskEventType.EMAIL) {
                    return 'regular/email-action-unread';
                }

                return '';
            },

            contactableDisabledTooltip() {
                switch (this.taskEvent.task_event_type_id) {
                    case TaskEventType.CALL:
                        if (!this.hasValidPhones) {
                            return this.$t('modal.no_phone');
                        }

                        if (this.lead.unsubscribe_all_date || this.lead.unsubscribe_call_date) {
                            return this.$t('clientCard.clientIsCallUnsubscribed');
                        }
                        break;

                    case TaskEventType.EMAIL:
                        if (!this.hasValidEmails) {
                            return this.$t('modal.no_email');
                        }

                        if (this.lead.unsubscribe_all_date || this.lead.unsubscribe_email_date) {
                            return this.$t('clientCard.clientIsEmailUnsubscribed');
                        }
                        break;
                }

                return '';
            },

            baseUsers() {
                return this.users.filter(user => {
                    return user.account_id == this.currentAccountId &&
                        (
                            (user.active && !user.hide_in_user_select) ||
                            user.id == this.taskEvent.userId ||
                            (user.active && this.taskEvent.hasGuest(user.id))
                        );
                });
            },

            ownerUsers() {
                let users = this.baseUsers;

                if (this.taskOwner.id && !users.some(user => user.id == this.taskOwner.id)) {
                    users.push(this.taskOwner);
                }

                users = users.map(user => {
                    return new User(user);
                });

                return users.sort((a, b) => a.fullName.localeCompare(b.fullName));
            },

            guestGroups() {
                const childAccount = this.contextAccount.children.find(account => {
                    return account.id == this.selectedAccount.id;
                });

                return (childAccount || this.contextAccount).guest_groups || [];
            },

            guestUsers() {
                const guestGroups = this.guestGroups.map(group => {
                    return {
                        id: `G${group.id}`,
                        isGroup: true,
                        name: `[${group.name}]`,
                        roles: group.roles,
                        users: group.users,
                        posts: group.posts,
                    };
                });

                const activeUsers = this.baseUsers.filter(user => {
                    return user.active || this.taskEvent.hasGuest(user.id);
                }).map(user => {
                    return new User(user);
                }).sort((a, b) => a.fullName.localeCompare(b.fullName));

                activeUsers.concat(this.selectedGuests);

                return guestGroups.concat(activeUsers);
            },

            deliveryUsers() {
                let users = this.baseUsers;

                if (this.deliveredBy.id && !users.some(user => user.id == this.deliveredBy.id)) {
                    users.push(this.deliveredBy);
                }

                users = users.map(user => {
                    return new User(user);
                });

                return users.sort((a, b) => a.fullName.localeCompare(b.fullName));
            },

            authUserIsOwner() {
                return (
                    [this.taskEvent.userId, this.initialOwnerId].includes(this.authUser.id) ||
                    this.authUser.hasUserAccess(this.taskEvent.userId)
                );
            },

            isInvited() {
                return this.selectedGuests.some(guest => guest.id == this.authUser.id);
            },

            filteredReminders() {
                if (!this.taskEvent.userId) {
                    return [];
                }

                return this.taskEvent.reminders.filter(reminder => reminder.user_id == this.taskEvent.userId);
            },

            formattedPriorities() {
                return ['normal', 'high'].map(value => {
                    return {
                        value,
                        label: this.$t(`modal.priorities.${value}`),
                    };
                });
            },

            formattedTaskEventTypes() {
                return this.configs.taskEventTypes
                    .filter(taskEventType => {
                        // Filter CSI
                        if (taskEventType.name === 'csi' && !this.contextAccount.hasProcessField('csi')) {
                            return false;
                        }

                        // Filter Virtual Appointment
                        if (this.specificTypeGroup !== 'task' && taskEventType.name === 'virtual_appointment') {
                            return this.hasVideoconference || this.contextAccount.hasHyundaiSupplier();
                        }

                        // For homepage; show only the types requested
                        if (this.specificTypeGroup === 'task') {
                            return TaskEventType.getTaskTypes().includes(taskEventType.id);
                        }

                        // For homepage; show only the types requested
                        if (this.specificTypeGroup === 'event') {
                            return TaskEventType.getEventTypes(true).includes(taskEventType.id);
                        }

                        return true;
                    })
                    .sort((a, b) => {
                        return a.order - b.order;
                    })
                    .map(type => {
                        let suffixIcon = null;

                        if (type.id === TaskEventType.PHONE_APPOINTMENT) {
                            suffixIcon = getIconMarkup('regular/phone-landline', { class: 'event-icon' });
                        }

                        return {
                            id: type.id,
                            label: this.typeTrans(type.id),
                            icon: TaskEventTypeIcon.entries[type.name],
                            type: type.type,
                            suffixIcon,
                        };
                    });
            },

            formattedChildAccounts() {
                return this.contextAccount.children
                    .map(account => {
                        return {
                            id: account.id,
                            label: account.name,
                        };
                    })
                    .sort((a, b) => a.label.localeCompare(b.label));
            },

            formattedWantedVehicles() {
                return this.lead.vehicles
                    .filter(vehicle => {
                        return vehicle.type === LeadVehicleType.WANTED &&
                            vehicle.year && vehicle.make && vehicle.model;
                    })
                    .sort((a, b) => b.created_at.localeCompare(a.created_at))
                    .map(vehicle => {
                        return {
                            id: vehicle.id,
                            label: `${vehicle.year || ''} ${vehicle.make || ''} ${vehicle.model || ''}`,
                        };
                    });
            },

            isReadOnly() {
                if (this.verifiedSale) {
                    return true;
                }

                return (
                    !this.authUser.hasAccessToEditAccount(this.contextAccount.id) ||
                    (this.isUpdate &&
                        !this.authUserIsOwner &&
                        !this.authUser.isAdmin() &&
                        !this.authUser.isDirector() &&
                        !this.authUser.isBdcDirector() &&
                        !this.authUser.isSuperBdc() &&
                        !this.authUser.isCommercialDirector() &&
                        !this.authUser.isSuperService())
                );
            },

            isSelected() {
                return (this.selectedAccount.id && (this.isValidType || !!this.lead.id)) || this.isReadOnly;
            },

            authUserIsTheDeliveryMan() {
                return this.lead.hasDeliveryMan(this.authUser.id);
            },

            isEvent() {
                return TaskEventType.getEventTypes().includes(this.taskEvent.task_event_type_id);
            },

            isPhoneAppointment() {
                return this.taskEvent.task_event_type_id === TaskEventType.PHONE_APPOINTMENT;
            },

            isTask() {
                return TaskEventType.getTaskTypes().includes(this.taskEvent.task_event_type_id);
            },

            isParentAccount() {
                return !!this.contextAccount.children.length;
            },

            isValidType() {
                return this.contextAccount.children.length
                    ? !!this.taskEvent.task_event_type_id && !!this.selectedAccount.id
                    : !!this.taskEvent.task_event_type_id;
            },

            doneTitle() {
                if (this.isAppointment) {
                    return this.$t('modal.presented');
                }

                if (this.isDelivery) {
                    return this.$t('modal.delivered');
                }

                if (this.isTestDrive) {
                    return this.$t('modal.testDriveCompleted');
                }

                if (this.isVirtualAppointment) {
                    return this.$t('modal.virtualDone');
                }

                return this.$t('modal.done');
            },

            showSeparator() {
                return (
                    !this.isNextStep &&
                    (this.confirmationEnabled || this.showMainEventDate || this.showDone || this.showNoShow) &&
                    !this.isPhoneAppointment
                );
            },

            showConfirmation() {
                return (
                    this.confirmationEnabled &&
                    this.lead.division_id != Division.SERVICE &&
                    this.contextAccount.confirmation_appt_sale
                );
            },

            hasNioText() {
                return this.selectedOwner.hasNioText() && this.contextAccount.hasNioText();
            },

            confirmationEnabled() {
                return this.hasNioText && (this.isAppointment || this.isPhoneAppointment || this.isDelivery);
            },

            showGas() {
                return (
                    !this.isNextStep &&
                    this.isDelivery &&
                    this.isUpdate &&
                    this.lead.division_id != Division.SERVICE &&
                    this.contextAccount.client_card_fields.process.gas &&
                    this.taskEvent.main_event
                );
            },

            showDone() {
                return !this.isNextStep && this.isUpdate && !this.isPhoneAppointment;
            },

            showNoShow() {
                return !this.isNextStep && this.isAppointment && this.isUpdate;
            },

            showCancel() {
                return !this.isNextStep && this.isPhoneAppointment && this.isUpdate;
            },

            showMainEventDate() {
                return this.taskEvent.main_event && this.taskEvent.status && (this.isAppointment || this.isDelivery || this.isTestDrive);
            },

            showPrepared() {
                return (
                    !this.isNextStep &&
                    this.isDelivery &&
                    this.isUpdate &&
                    this.lead.division_id != Division.SERVICE &&
                    this.taskEvent.main_event
                );
            },

            showTrash() {
                const userHasAdminAccess =
                    this.authUser.isAdmin() ||
                    this.authUser.isDirector() ||
                    this.authUser.isBdcDirector() ||
                    this.authUser.isCommercialDirector() ||
                    this.taskEvent.userId === this.authUser.id;

                return userHasAdminAccess && this.isUpdate && this.isValidType && !this.isReadOnly && this.ownerCanDeleteTask && !this.verifiedSale;
            },

            virtualAppointmentAdvisorLink() {
                return this.taskEvent?.user_virtual_event_link;
            },

            virtualAppointmentCustomerLink() {
                return this.taskEvent?.customer_virtual_event_link;
            },

            showVirtualConferenceLinks() {
                return this.selectedTaskEventType?.id === TaskEventType.VIRTUAL_APPOINTMENT &&
                    this.contextAccount.hasHyundaiSupplier() &&
                    this.taskEvent.id;
            },

            disabledSendConfirmation() {
                return (
                    !this.hasValidMobileNumber ||
                    this.taskEvent.no_show ||
                    this.isReadOnly ||
                    !this.authUser.hasNioText() ||
                    !this.contextAccount.hasNioText()
                );
            },

            sendConfirmationTooltip() {
                if (this.disabledSendConfirmation) {
                    if (!this.authUser.hasNioText() || !this.contextAccount.hasNioText()) {
                        return this.$t('users.edit.needNioText');
                    }

                    if (!this.hasValidMobileNumber) {
                        return this.$t('modal.confirmationDisabled');
                    }
                }

                return '';
            },

            statusTooltip() {
                if (this.isDelivery) {
                    if (!this.lead.sale_date) {
                        if (this.lead.isOfType(LeadType.RENEWAL)) {
                            return this.$t('clientCard.leadMustBeRenewed');
                        }

                        return this.$t('clientCard.leadMustBeSold');
                    }
                    return this.$t('modal.presentedTooltip');
                }

                if (this.isAppointment) {
                    return this.$t('modal.deliveredTooltip');
                }

                return '';
            },

            hasAllPhonesValidated() {
                return !this.lead.phones.some(p => !p.validated);
            },

            hasValidMobileNumber() {
                return this.lead.phones.some(p => p.validated && p.mobile);
            },

            hasVideoconference() {
                return this.contextAccount.video_conference;
            },

            isAppointment() {
                return this.taskEvent.task_event_type_id === TaskEventType.APPOINTMENT;
            },

            isVirtualAppointment() {
                return this.taskEvent.task_event_type_id === TaskEventType.VIRTUAL_APPOINTMENT;
            },

            isDelivery() {
                return this.taskEvent.task_event_type_id === TaskEventType.DELIVERY;
            },

            isTestDrive() {
                return this.taskEvent.task_event_type_id === TaskEventType.TEST_DRIVE;
            },

            isOther() {
                return this.taskEvent.task_event_type_id === TaskEventType.OTHER;
            },

            modalTitle() {
                if (this.isUpdate) {
                    return this.$t('modal.editTaskEventTitle');
                }

                if (this.specificTypeGroup === 'event') {
                    return this.$t('homepage.appointments.addAppointment');
                }

                if (this.specificTypeGroup === 'task') {
                    return this.$t('homepage.tasks.addTask');
                }

                return this.$t('modal.addTaskEventTitle');
            },

            deleteTaskModalContent() {
                let content = this.$t('modal.delete_confirm_message');

                if (this.taskEvent.main_event) {
                    content += `
                        <br><br>
                        <span class="text-red-500">
                            ${this.$t('modal.deleteConfirmMessageMainEvent')}
                        </span>
                    `;
                }

                return content;
            },

            showUpdateFutureEventContent() {
                if (get(this.taskEvent, 'automated_siblings')) {
                    return this.$tc('modal.update_future_confirm_message', this.taskEvent.automated_siblings.length, [
                        this.taskEvent.automated_siblings.length,
                    ]);
                }
                return '';
            },

            statusIsDisabled() {
                return (
                    this.isInDelivery ||
                    (!this.isDelivery && ((this.isReadOnly && !this.isInvited) || this.taskEvent.no_show || this.taskEvent.canceled))
                );
            },

            confirmedTooltip() {
                return this.hasNioText ? this.$t('modal.confirmedTooltip') : this.$t('modal.niotextNeeded');
            },

            confirmedIsDisabled() {
                return (this.isReadOnly && !this.isInvited) || !this.hasNioText || this.taskEvent.no_show || this.taskEvent.canceled || this.taskEvent.status;
            },

            icsTooltip() {
                if (!this.hasValidEmails) {
                    return this.$t('modal.error_email');
                }

                if (this.contextAccount.hasHyundaiSupplier && this.isVirtualAppointment) {
                    return this.$t('modal.sendIcsVirtualAppointment');
                }

                if (this.icsIsDisabled) {
                    return this.$t('modal.error_is_canceled');
                }

                return this.$t('modal.icsTooltip');
            },

            icsIsDisabled() {
                return this.taskEvent.no_show || this.taskEvent.canceled || !this.hasValidEmails || !this.taskEvent.userId ||
                    (this.contextAccount.hasHyundaiSupplier() && this.isVirtualAppointment);
            },

            saleNeededMessage() {
                if (this.lead.isOfType(LeadType.RENEWAL)) {
                    return this.$t('modal.renewalIsNeededForDelivery');
                }

                return this.$t('modal.saleIsNeededForDelivery');
            },

            startDate() {
                if (!this.isVirtualAppointment) {
                    return '';
                }

                return now().toString();
            },

            isTaskTypeCSI() {
                return [TaskEventType.CSI].includes(this.taskEvent.task_event_type_id);
            },
        },

        watch: {
            'selectedAccount.id': {
                immediate: true,
                async handler(newValue) {
                    this.setUsers(newValue);
                },
            },

            'contextAccount.id': {
                immediate: true,
                handler(newValue) {
                    this.setUsers(newValue);
                },
            },

            'contextAccount.default_deliveryman_user_id': {
                immediate: true,
                handler() {
                    this.setDefaultDeliverer();
                },
            },

            lead(newLead, oldLead) {
                this.setDefaultTitle();

                if (newLead.id || this.taskEvent.task_event_type_id == TaskEventType.OTHER) {
                    this.setDefaultOwner();
                }

                if (newLead.id != oldLead.id) {
                    this.validatePhones();
                }

                if (this.isDelivery && this.lead.id && !this.lead.sale_date) {
                    this.modals.saleNeededOpened = true;
                }

                if (this.isDelivery && this.hasDeliveryTask && !this.isUpdate) {
                    this.modals.existingDeliveryTaskOpened = true;
                }
            },

            'lead.account_id': {
                immediate: true,
                handler(newValue) {
                    this.setUsers(newValue);
                },
            },

            'taskEvent.userId': {
                immediate: true,
                handler() {
                    this.setDefaultReminders();
                    this.setDefaultGuests();
                },
            },

            'taskEvent.status'() {
                if (this.showMainEventDate) {
                    this.mainEventDate = this.taskEvent.start_at;
                }
            },

            'taskEvent.task_event_type_id': {
                immediate: true,
                handler(newValue) {
                    this.validatePhones();
                    this.adjustDatesBasedOnTaskEventType();
                    this.setDefaultReminders();
                    this.setDefaultGuests();
                    this.setDefaultVehicles();

                    if (newValue === TaskEventType.OTHER) {
                        this.setDefaultOwner();
                    }

                    this.taskEvent.send_ics = !!(newValue === TaskEventType.VIRTUAL_APPOINTMENT && this.contextAccount.hasHyundaiSupplier() && this.hasValidEmails);

                    if (this.hasLocation) {
                        this.taskEvent.location = TaskEventLocation.DEALERSHIP;
                    } else {
                        this.taskEvent.location = null;
                    }

                    if (this.isDelivery && this.lead.id && !this.lead.sale_date) {
                        this.modals.saleNeededOpened = true;
                    }

                    if (this.taskEvent.main_event) {
                        if (this.isAppointment) {
                            if (as_locale(this.lead.presented_date, 'presented_date').isValid()) {
                                this.mainEventDate = this.lead.presented_date;
                            } else {
                                this.mainEventDate = this.taskEvent.start_at;
                            }
                        } else if (this.isDelivery) {
                            if (as_locale(this.lead.delivered_date, 'delivered_date').isValid()) {
                                this.mainEventDate = this.lead.delivered_date;
                            } else {
                                this.mainEventDate = this.taskEvent.start_at;
                            }
                        }

                        if (this.taskEvent.status) {
                            this.mainEventDate = this.taskEvent.start_at;
                        }
                    }
                },
            },

            mainEventDate(newValue) {
                if (locale_dt(newValue).isFuture()) {
                    this.mainEventDate = now().toString();
                }
            },

            'taskEvent.end_at'() {
                this.adjustDatesBasedOnEndAt();
            },

            'taskEvent.start_at'(newValue) {
                this.userSelectedStartDate = newValue;
            },

            'taskEvent.location'() {
                if (!this.hasCustomAddress) {
                    this.taskEvent.address = null;
                }
            },
        },

        methods: {
            ...mapActions(useGlobalStore, [
                'commentCreated',
                'reloadLead',
                'taskEventCreated',
                'taskEventDeleted',
                'taskEventUpdated',
                'setTaskEventToBeCompleted',
            ]),

            copyVirtualAppointmentAdvisorLinkToClipboard() {
                navigator.clipboard.writeText(this.virtualAppointmentAdvisorLink);
                this.$notify.success(this.$t('general.copiedSuccessfully'));
            },

            openVirtualAppointmentAdvisorLink() {
                window.open(this.virtualAppointmentAdvisorLink, '_blank');
            },

            copyVirtualAppointmentCustomerLinkToClipboard() {
                navigator.clipboard.writeText(this.virtualAppointmentCustomerLink);
                this.$notify.success(this.$t('general.copiedSuccessfully'));
            },

            openVirtualAppointmentCustomerLink() {
                window.open(this.virtualAppointmentCustomerLink, '_blank');
            },

            updateTaskEventType(event) {
                this.taskEvent.task_event_type_id = event ? event.id : null;

                if (this.isDelivery && this.hasDeliveryTask) {
                    this.modals.existingDeliveryTaskOpened = true;
                }

                if (this.isTask) {
                    this.selectedGuests = [];
                }
            },

            triggerAutomatedEventModification(payload) {
                if (payload.delete) {
                    this.deleteTaskEvent(payload);
                    return;
                }

                this.updateTaskEvent(payload);
            },

            triggerDeleteTaskEvent() {
                this.$nextTick(() => {
                    if (this.taskEvent.automated_siblings.length) {
                        this.modals.showUpdateFutureEvent = true;
                        this.pendingPayload = { delete: true };

                        return;
                    }

                    this.deleteTaskEvent();
                });
            },

            openContactModal(type = null) {
                if (this.leadIsAlreadyCommunicating || this.contactableDisabledTooltip) {
                    return;
                }

                this.setTaskEventToBeCompleted(this.taskEvent.id);

                if (this.taskEvent.task_event_type_id == TaskEventType.CALL) {
                    this.openCallLeadModal();
                } else if (this.taskEvent.task_event_type_id == TaskEventType.EMAIL) {
                    this.openEmailLeadModal();
                } else if (this.isTaskTypeCSI) {
                    if (type === 'phone') {
                        this.openCallLeadModal();
                    } if (type === 'email') {
                        this.openEmailLeadModal();
                    }
                }

                this.$behavior.track('Calendar', { action: 'quick-action', type: this.taskEvent.task_event_type_id, lead: this.lead.id, location: 'modal-event' });
            },

            openCallLeadModal() {
                if (
                    (this.guest && !this.authorizedAction) ||
                    this.lead.unsubscribe_call ||
                    this.lead.unsubscribe_all_date
                ) {
                    return;
                }

                if (this.lead.account.outgoingPhoneProviders.length > 0 && this.hasValidPhones) {
                    this.$modal.show('modalEvent:callLead', this.lead.id);
                }
            },

            openEmailLeadModal() {
                if (
                    (this.guest && !this.authorizedAction) ||
                    this.lead.unsubscribe_email ||
                    this.lead.unsubscribe_all_date
                ) {
                    return;
                }

                if (this.hasValidEmails) {
                    this.$modal.show('modalEvent:emailLead', { leadId: this.lead.id });
                }
            },

            async setUsers(accountId) {
                if (!accountId || accountId == this.contextAccount.id) {
                    this.users = await this.contextAccount.getUsers(['first_name', 'last_name', 'active', 'hide_in_user_select', 'reminder_preferences', 'has_niotext', 'niotext']);
                    return;
                }

                this.users = await this.$api.users.index({
                    filter: {
                        accountId,
                    },
                    include: ['parent'],
                });
            },

            async setLead(leadId) {
                if (!leadId) {
                    this.lead = new Lead();
                    this.advisor = new User();
                    this.deliveredBy = new User();
                    return;
                }

                this.$wait.start('fetching.calendarLead');

                this.lead = new Lead(
                    await this.$api.leads.show(leadId, {
                        include: ['leadPhones', 'leadEmails', 'taskEvents'],
                    }),
                );

                const [advisor, deliveredBy] = await Promise.all([
                    this.lead.getUser(['first_name', 'last_name']),
                    this.lead.getDeliveredByUser(['first_name', 'last_name']),
                ]);

                this.advisor = advisor;
                this.deliveredBy = deliveredBy;

                this.$nextTick(() => {
                    this.setDefaultGuests();
                    this.setDefaultDeliverer();

                    this.$wait.end('fetching.calendarLead');
                });
            },

            async setTaskEvent(taskEventId) {
                if (!taskEventId) {
                    this.taskEvent = new TaskEvent();
                    return;
                }

                this.$wait.start('fetching.calendarTaskEvent');

                this.taskEvent = new TaskEvent(
                    await this.$api.taskEvents.show(taskEventId, {
                        include: [
                            'lead.leadPhones',
                            'lead.leadEmails',
                            'lead.taskEvents',
                            'lead.leadVehicles',
                            'automationAction',
                            'user',
                            'guests',
                            'reminders',
                        ],
                    }),
                );

                this.canAddAppointmentOnSave = !this.taskEvent.status;
                this.initialOwnerId = this.taskEvent.userId;
                this.lead = this.taskEvent.lead;

                const [taskOwner, ...guests] = await Promise.all([
                    this.taskEvent.getUser(['first_name', 'last_name', 'account.id', 'account.name', 'reminder_preferences', 'has_niotext', 'niotext']),
                    ...this.taskEvent.guestIds.map(guestId => UserRepository.findWithFields(guestId, ['first_name', 'last_name'])),
                ]);

                this.taskOwner = taskOwner;
                this.selectedGuests = guests.sort((a, b) => {
                    return this.getFullName(a, false).localeCompare(this.getFullName(b, false));
                });

                this.$nextTick(() => {
                    this.setSendConfirmation();
                    this.setAppointmentCall();
                    this.setPresentedOther();
                    this.setMainEventDate();

                    this.$wait.end('fetching.calendarTaskEvent');
                });
            },

            setAppointmentCall() {
                if (this.isAppointment && this.taskEvent.main_event) {
                    this.appointmentCall = this.lead.appt_call;
                } else {
                    this.appointmentCall = false;
                }
            },

            setPresentedOther() {
                if (this.isAppointment && this.taskEvent.main_event) {
                    this.isPresentedOther = !!this.lead.presented_other_date;
                } else {
                    this.isPresentedOther = false;
                }
            },

            setMainEventDate() {
                if (!this.lead.id) {
                    return;
                }

                if (
                    this.taskEvent.task_event_type_id == TaskEventType.APPOINTMENT &&
                    as_locale(this.lead.presented_date, 'presented_date').isValid()
                ) {
                    this.mainEventDate = this.lead.presented_date;
                } else if (
                    this.taskEvent.task_event_type_id == TaskEventType.DELIVERY &&
                    as_locale(this.lead.delivered_date, 'presented_date').isValid()
                ) {
                    this.mainEventDate = this.lead.delivered_date;
                }
            },

            setSendConfirmation() {
                if (this.taskEvent.confirmation_timeframe) {
                    this.sendConfirmation = true;
                } else {
                    this.setDefaultConfirmationTimeFrame();
                    this.sendConfirmation = false;
                }
            },

            setDefaultTitle() {
                if (!this.taskEvent.title || !this.isUpdate) {
                    this.taskEvent.title = this.lead.fullName || this.$t('general.unknown');
                }
            },

            setDefaultDeliverer() {
                if (this.isUpdate) {
                    return;
                }

                if (!this.lead.deliveredById) {
                    this.lead.deliveredById = this.contextAccount.default_deliveryman_user_id;
                }
            },

            setDefaultReminders() {
                if (
                    !this.selectedOwner.id ||
                    !this.taskEvent.task_event_type_id ||
                    this.$wait.is('opening.taskEventModal')
                ) {
                    return;
                }

                if (!this.selectedOwner.reminder_preferences) {
                    if (this.isTestDrive && !this.taskEvent.reminders.length) {
                        const date = as_locale(this.taskEvent.start_at, 'start_at');
                        this.taskEvent.reminders = [
                            new Reminder({
                                date: date.subDays().toString(),
                                sms: false,
                                email: true,
                                notification: true,
                                user_id: this.selectedOwner.id,
                                push: true,
                            }),
                        ];
                    }

                    return;
                }

                const defaultReminders = this.selectedOwner.reminder_preferences
                    .filter(reminder => {
                        return (this.isEvent && reminder.type == 'event') || (!this.isEvent && reminder.type == 'task');
                    })
                    .map(reminder => {
                        let date = as_locale(this.taskEvent.start_at, 'start_at');

                        if (reminder.date_range == Reminder.NINE_AM) {
                            date = date.hour(9).minute(0);
                        } else if (reminder.date_range == Reminder.NINE_PM) {
                            date = date.subDays().hour(21).minute(0);
                        } else {
                            date = date.subMinutes(reminder.date_range);
                        }

                        return new Reminder({
                            date: date.toString(),
                            sms: reminder.sms,
                            email: reminder.email,
                            notification: reminder.notification,
                            user_id: this.selectedOwner.id,
                            push: reminder.push,
                        });
                    });

                if (this.isTestDrive) {
                    const date = as_locale(this.taskEvent.start_at, 'start_at');
                    defaultReminders.push(new Reminder({
                        date: date.subDays().toString(),
                        sms: false,
                        email: true,
                        notification: true,
                        user_id: this.selectedOwner.id,
                        push: true,
                    }));
                }

                const filteredExistingReminders = this.taskEvent.reminders.filter(reminder => {
                    return reminder.user_id != this.selectedOwner.id;
                });

                this.taskEvent.reminders = [...filteredExistingReminders, ...defaultReminders];
            },

            setDefaultGuests() {
                if (this.isUpdate) {
                    return;
                }

                this.selectedGuests = this.selectedGuests.filter(guest => guest.id != this.taskEvent.userId);

                if (
                    this.contextAccount.delivery_guest_advisor &&
                    this.isDelivery &&
                    this.advisor.id
                ) {
                    this.selectedGuests.push(this.advisor);
                }

                if (this.isDelivery && this.contextAccount.delivery_guest_group_id) {
                    const defaultDeliveryGroup = this.guestGroups
                        .map(group => {
                            return {
                                id: `G${group.id}`,
                                isGroup: true,
                                name: `[${group.name}]`,
                                roles: group.roles,
                                users: group.users,
                                posts: group.posts,
                            };
                        }).find(guestGroup => guestGroup.id == `G${this.contextAccount.delivery_guest_group_id}`);

                    const combinedUsersToChecked = this.getUniqueUsersToModifyChecked(defaultDeliveryGroup);

                    this.selectedGuests = uniqBy(this.selectedGuests.concat(combinedUsersToChecked).concat(defaultDeliveryGroup), 'id');
                }

                this.selectedGuests = uniqBy(this.selectedGuests.filter(guest => guest.id != this.taskEvent.userId), 'id');
            },

            setDefaultVehicles() {
                if (this.isUpdate) {
                    return;
                }

                if (this.isEvent) {
                    this.updateVehicles(this.formattedWantedVehicles.length
                        ? [this.formattedWantedVehicles[0]] : []);
                } else {
                    this.updateVehicles([]);
                }
            },

            setDefaultOwner() {
                if (
                    this.taskEvent.userId &&
                    this.selectedOwner.id &&
                    this.taskEvent.userId == this.selectedOwner.id
                ) {
                    return;
                }

                if (this.isUpdate) {
                    return;
                }

                if (this.lead.id) {
                    // Self
                    if (this.lead.isAssignedTo(this.authUser.id)) {
                        this.taskEvent.userId = this.authUser.id;
                        return;
                    }

                    // Advisor
                    if (this.lead.userId && (this.authUser.isAdmin() || this.authUser.isDirector())) {
                        this.taskEvent.userId = this.lead.userId;
                        return;
                    }

                    // BDC Agent
                    if (this.lead.bdcUserId && (this.authUser.isAdmin() || this.authUser.isBdcDirector())) {
                        this.taskEvent.userId = this.lead.bdcUserId;
                        return;
                    }

                    if (this.authUser.account_id != this.lead.account_id && !this.authUser.isAdmin()) {
                        this.taskEvent.userId = this.parentAuthUser.childIdForAccount(this.lead.account_id) || null;
                        return;
                    }
                }

                this.taskEvent.userId = this.authUser.isAdmin() ? null : this.authUser.id;
            },

            setDefaultConfirmationTimeFrame() {
                if (this.authUser.confirmation_timeframe) {
                    this.taskEvent.confirmation_timeframe = cloneDeep(this.authUser.confirmation_timeframe);
                } else {
                    this.taskEvent.confirmation_timeframe = {
                        when: '24_hours_before',
                        time: null,
                    };
                }
            },

            updateStartAt(newStartAt) {
                const oldStartAt = this.taskEvent.start_at;
                this.taskEvent.start_at = newStartAt;

                this.adjustDatesBaseOnStartAt(oldStartAt);
            },

            updateEndAt(newEndAt) {
                this.taskEvent.end_at = newEndAt;

                this.adjustDatesBasedOnEndAt();
            },

            userFormater(user) {
                return this.getFullName(user, false);
            },

            guestFormater(guest) {
                if (guest.isGroup) {
                    return guest.name;
                }

                return this.getFullName(guest, false);
            },

            addReminder() {
                if (this.isReadOnly || !this.taskEvent.userId) {
                    return;
                }

                let date = this.taskEvent.start_at;

                if (!this.isTask) {
                    date = locale_dt(date).subHours().toString();
                }

                this.taskEvent.reminders.push(
                    new Reminder({
                        date,
                        user_id: this.taskEvent.userId,
                    }),
                );
            },

            deleteReminder(index) {
                this.taskEvent.reminders.splice(index, 1);
            },

            validate() {
                if (!this.isValidType) {
                    this.validationErrors.push(this.$t('modal.error_no_type'));
                }

                if (!this.isOther && !this.lead.id) {
                    this.validationErrors.push(this.$t('modal.error_lead'));
                }

                if (
                    !this.taskEvent.start_at ||
                    !this.taskEvent.end_at ||
                    (this.showMainEventDate && !this.mainEventDate)
                ) {
                    this.validationErrors.push(this.$t('modal.error_invalid_date'));
                }

                if (!this.taskEvent.start_at) {
                    if (this.isEvent) {
                        this.validationErrors.push(this.$t('modal.start_at'));
                    } else {
                        this.validationErrors.push(this.$t('modal.error_date'));
                    }
                }

                if (!this.taskEvent.userId) {
                    this.validationErrors.push(this.$t('modal.error_no_selected_user'));
                }

                if (this.isEvent && !this.taskEvent.end_at) {
                    this.validationErrors.push(this.$t('modal.error_end_at'));
                }

                if (this.isEvent && !!this.taskEvent.end_at && !!this.taskEvent.start_at) {
                    if (
                        as_locale(this.taskEvent.end_at, 'end_at').isBefore(
                            as_locale(this.taskEvent.start_at, 'start_at'),
                        )
                    ) {
                        this.validationErrors.push(this.$t('modal.error_end_is_before'));
                    }
                }

                if (this.taskEvent.status && locale_dt(this.mainEventDate).isFuture()) {
                    this.validationErrors.push(this.$t('modal.error_main_event_date_is_after', [this.doneTitle]));
                }

                if (!empty(this.taskEvent.reminders)) {
                    const emptyRemindersCount = this.taskEvent.reminders.filter(reminder => {
                        return empty(reminder.date);
                    });

                    if (!empty(emptyRemindersCount)) {
                        this.validationErrors.push(this.$t('modal.error.reminderDate'));
                    }
                }

                return !this.validationErrors.length;
            },

            getActiveUsersBySelectedRoles(roles) {
                const activeUsers = this.baseUsers.filter(user => {
                    return user.active || this.selectedGuests.some(guest => guest.id == user.id);
                });
                return activeUsers.filter(user => roles.includes(user.role_id));
            },

            getActiveUsersFromGuestGroups(users) {
                const activeUsers = this.baseUsers.filter(user => {
                    return user.active || this.selectedGuests.some(guest => guest.id == user.id);
                });
                return activeUsers.filter(user => users.includes(user.id));
            },

            getActiveUsersBySelectedPosts(posts) {
                const activeUsers = this.baseUsers.filter(user => {
                    return user.active || this.selectedGuests.some(guest => guest.id == user.id);
                });
                return activeUsers.filter(user => posts.includes(user.post_id));
            },

            getCompleteGuestGroup(users) {
                const guestGroups = this.contextAccount.guest_groups;

                let checkedGuestGroups = [];
                guestGroups.forEach(group => {
                    const usersId = group.users;

                    const selectedUsersPerGuestGroup = users.filter(user => usersId.includes(user.id));

                    if (usersId.length == selectedUsersPerGuestGroup.length) {
                        checkedGuestGroups = checkedGuestGroups.concat(group);
                    }
                });

                return checkedGuestGroups;
            },

            getGuestGroupToRemove(users) {
                const guestGroups = this.contextAccount.guest_groups;

                let removedGuestGroups = [];
                guestGroups.forEach(group => {
                    const usersId = group.users;

                    const selectedUsersPerGuestGroup = users.filter(user => usersId.includes(user.id));

                    if (usersId.length != selectedUsersPerGuestGroup.length) {
                        removedGuestGroups = removedGuestGroups.concat(group);
                    }
                });

                return removedGuestGroups;
            },

            async save() {
                if (!this.canSave && !this.isInvited) {
                    return;
                }

                this.validationErrors = [];

                if (!this.validate()) {
                    return;
                }

                await this.createNextStepComment();

                this.selectedGuests = this.selectedGuests.filter(item => !item.isGroup);

                if (this.isTask) {
                    this.selectedGuests = [];
                }

                const payload = {
                    leadId: this.lead.id,
                    start_at: this.taskEvent.start_at,
                    end_at: this.taskEvent.end_at,
                    mainEventDate: this.mainEventDate,
                    title: this.taskEvent.title,
                    guests: this.selectedGuests,
                    type: this.taskEvent.task_event_type_id,
                    appt_call: this.appointmentCall,
                    priority: this.taskEvent.priority,
                    confirmed: this.taskEvent.confirmed,
                    canceled: this.taskEvent.canceled,
                    status: this.taskEvent.status,
                    gas: this.lead.gas,
                    prepared: this.lead.prepared,
                    user: this.taskEvent.userId,
                    noShow: this.taskEvent.no_show,
                    reminders: this.filteredReminders,
                    is_presented_other: this.isPresentedOther,
                    send_ics: this.taskEvent.send_ics,
                    location: this.taskEvent.location,
                    address: this.taskEvent.address,
                    wanted_vehicle_ids: this.taskEvent.wanted_vehicle_ids,
                };

                if (this.taskEvent.description !== null) {
                    payload.description = this.taskEvent.description;
                }

                if (this.sendConfirmation && this.showConfirmation && !this.disabledSendConfirmation) {
                    payload.confirmation_timeframe = this.taskEvent.confirmation_timeframe;
                }

                if (this.isDelivery && this.lead.deliveredById) {
                    payload.delivered_by_id = this.lead.deliveredById;
                }

                await this.verifyOverlappingEventDate(payload);

                if (this.overlappingEvents.length > 0) {
                    this.pendingPayload = payload;
                    this.modals.showOverlappingEventsModal = true;
                    return;
                }

                if (this.isUpdate) {
                    if (this.taskEvent.automated_siblings.length) {
                        this.modals.showUpdateFutureEvent = true;
                        this.pendingPayload = payload;
                        return;
                    }
                    await this.updateTaskEvent(payload);
                } else {
                    await this.createTaskEvent(payload);
                }
            },

            async createNextStepComment() {
                if (!this.nextStepComment) {
                    return;
                }

                const formData = new FormData();

                const comment = {
                    lead_id: this.lead.id,
                    user_id: this.authUser.id,
                    content: this.nextStepComment,
                    alert: false,
                    alert_sms: false,
                    alert_email: false,
                    alert_users: null,
                };

                formData.append('data', JSON.stringify(comment));

                const response = await this.$axios.post('v1/comments', formData);

                this.commentCreated(get(response.data, 'comment'));
            },

            async createTaskEvent(payload) {
                if (this.taskEvent.id) {
                    return;
                }

                this.$wait.start('creating.taskEvent');
                try {
                    const response = await this.$axios.post('v1/reminder', payload);

                    this.reloadLead(response.data.data.lead_id);
                    this.taskEventCreated(response.data.data);

                    this.$eventBus.$emit('add-task-event', response.data.data);
                    this.$wait.end('creating.taskEvent');

                    this.close();
                } catch (error) {
                    this.$wait.end('creating.taskEvent');

                    if (get(error.response, 'status') == 422) {
                        if (error.response.data.message == 'Invalid start date.') {
                            this.validationErrors.push(this.$t('modal.invalidStartAt'));
                        } else if (error.response.data.message == 'Invalid end date.') {
                            this.validationErrors.push(this.$t('modal.invalidEndAt'));
                        } else {
                            this.validationErrors.push(error.response.data.message);
                        }

                        return;
                    }

                    this.$notify.error(this.$t('taskEvents.alerts.store.error'));

                    throw error;
                }
            },

            async updateTaskEvent(payload) {
                if (!this.taskEvent.id) {
                    return;
                }

                this.$wait.start('updating.taskEvent');

                try {
                    const response = await this.$axios.put(`v1/reminder/${this.taskEvent.id}`, payload);

                    this.taskEventUpdated(response.data.data);
                    this.reloadLead(response.data.data.lead_id);

                    this.$wait.end('updating.taskEvent');

                    const appointmentMainEvent = this.lead.task_events.find(taskEvent => {
                        return taskEvent.task_event_type_id == TaskEventType.APPOINTMENT && taskEvent.main_event;
                    });

                    if (
                        !appointmentMainEvent &&
                        this.taskEvent.status &&
                        this.isPhoneAppointment &&
                        this.canAddAppointmentOnSave
                    ) {
                        this.$eventBus.$emit('open-modal-event-appointment', {
                            leadId: this.lead.id,
                            guests: this.selectedGuests,
                        });
                    }

                    this.close();
                } catch (error) {
                    this.$wait.end('updating.taskEvent');

                    if (get(error.response, 'status') == 422) {
                        if (error.response.data.message == 'Invalid start date.') {
                            this.validationErrors.push(this.$t('modal.invalidStartAt'));
                        } else if (error.response.data.message == 'Invalid end date.') {
                            this.validationErrors.push(this.$t('modal.invalidEndAt'));
                        } else {
                            this.validationErrors.push(error.response.data.message);
                        }

                        return;
                    }

                    this.$notify.error(this.$t('taskEvents.alerts.update.error'));

                    throw error;
                }
            },

            async verifyOverlappingEventDate(taskEvent) {
                if (!this.currentAccountId || !findContextAccount(this.currentAccountId).alert_task_overlap) {
                    return;
                }

                this.$wait.start('fetching.taskEventLeads');

                try {
                    const response = await this.$axios.get('v1/task-events/overlaps', {
                        params: {
                            user_id: taskEvent.user,
                            start_at: taskEvent.start_at,
                            end_at: taskEvent.end_at,
                        },
                    });
                    this.overlappingEvents = response.data.data;
                } catch (error) {
                    this.$notify.error(this.$t('taskEvents.alerts.store.error'));
                    return;
                }

                if (this.taskEvent.id) {
                    this.overlappingEvents = this.overlappingEvents.filter(event => event.id !== this.taskEvent.id);
                }

                this.$wait.end('fetching.taskEventLeads');
            },

            approvedOverlappingEvent() {
                if (this.isUpdate) {
                    if (this.taskEvent.automated_siblings.length) {
                        this.modals.showUpdateFutureEvent = true;
                        return;
                    }

                    this.updateTaskEvent(this.pendingPayload);
                } else {
                    this.createTaskEvent(this.pendingPayload);
                }

                this.denyOverlappingEvent();
            },

            denyOverlappingEvent() {
                this.modals.showOverlappingEventsModal = false;
                this.pendingPayload = {};
                this.overlappingEvents = [];
            },

            deleteAutomatedSiblings(taskEvent, siblingsToDelete) {
                if (taskEvent.automated_siblings) {
                    taskEvent.automated_siblings.forEach(sibling => {
                        if (siblingsToDelete == 'all') {
                            this.taskEventDeleted(sibling);
                            this.$eventBus.$emit('delete-task-event', sibling);
                        }

                        if (siblingsToDelete == 'future') {
                            if (
                                as_locale(sibling.start_at, 'start_at').isAfter(
                                    as_locale(taskEvent.start_at, 'start_at'),
                                )
                            ) {
                                this.taskEventDeleted(sibling);
                                this.$eventBus.$emit('delete-task-event', sibling);
                            }
                        }
                    });
                }
            },

            async deleteTaskEvent(payload = {}) {
                if (!this.taskEvent.id) {
                    return;
                }

                const leadId = this.taskEvent.lead_id;

                this.$wait.start('deleting.taskEvent');
                this.taskEventDeleted(this.taskEvent);
                this.$eventBus.$emit('delete-task-event', this.taskEvent);

                if (['all', 'future'].includes(payload.updateAutomatedSiblings)) {
                    this.deleteAutomatedSiblings(this.taskEvent, payload.updateAutomatedSiblings);
                }

                this.close();

                try {
                    await this.$axios.delete(`v1/reminder/${this.taskEvent.id}`, { params: payload });

                    this.reloadLead(leadId);

                    this.$wait.end('deleting.taskEvent');
                } catch (error) {
                    this.$wait.end('deleting.taskEvent');
                    this.$notify.error(this.$t('taskEvents.alerts.destroy.error'));

                    throw error;
                }
            },

            // @TODO Refactor using dynamic translations
            typeTrans(type) {
                let value = '';

                switch (type) {
                    case TaskEventType.CALL:
                        value = this.$t('modal.call');
                        break;

                    case TaskEventType.EMAIL:
                        value = this.$t('modal.email');
                        break;

                    case TaskEventType.APPOINTMENT:
                        value = this.$t('modal.event');
                        break;

                    case TaskEventType.DELIVERY:
                        value = this.$t('modal.delivery');
                        break;

                    case TaskEventType.OTHER:
                        value = this.$t('modal.other');
                        break;

                    case TaskEventType.CSI:
                        value = this.$t('clientCard.csi');
                        break;

                    case TaskEventType.SMS:
                        value = this.$t('clientCard.sms');
                        break;

                    case TaskEventType.PHONE_APPOINTMENT:
                        value = this.$t('modal.phoneAppointment');
                        break;

                    case TaskEventType.VIRTUAL_APPOINTMENT:
                        value = this.$t('modal.virtualAppointment');
                        break;

                    case TaskEventType.TEST_DRIVE:
                        value = this.$t('modal.testDrive');
                        break;
                }

                return value;
            },

            openSearchLeadModal() {
                if ((!this.canOpenClientCard || this.lead.id) && !this.$browser.isMobileWebView()) {
                    return;
                }

                this.modals.searchLeadOpened = true;
            },

            openLeadOrModal(inNewTab = false) {
                if (this.$browser.isMobileWebView() && this.leadUrl) {
                    this.$browser.mobileApp.postMessage('goToClientCard', this.leadUrl.params.id);
                    return;
                }

                if (!this.leadUrl) {
                    this.openSearchLeadModal();
                    return;
                }

                if (inNewTab) {
                    window.open(this.$router.resolve(this.leadUrl).href, '_blank');
                } else {
                    this.$router.push(this.leadUrl);
                }
            },

            async validatePhones() {
                if (
                    !this.opened ||
                    !this.lead.id ||
                    !this.confirmationEnabled ||
                    this.hasValidMobileNumber ||
                    this.hasAllPhonesValidated
                ) {
                    return;
                }

                const response = await this.$axios.post(`v1/leads/${this.lead.id}/validate-phones`);

                this.lead.phones = response.data.data.lead_phones;
            },

            async openEditTaskEvent({ taskEvent, lead, homepage = false }) {
                this.opened = true;

                this.defaultLead = lead?.id;

                // Used to set the appropriate height of the modal
                this.taskEvent.task_event_type_id = taskEvent.task_event_type_id;

                await this.setTaskEvent(taskEvent.id);

                this.setSelectedAccount(taskEvent, lead);

                this.setDefaultReminders();

                if (homepage) {
                    this.homepage = true;

                    if (TaskEventType.getTaskTypes().includes(taskEvent.task_event_type_id)) {
                        this.specificTypeGroup = 'task';
                    }

                    if (TaskEventType.getEventTypes(true).includes(taskEvent.task_event_type_id)) {
                        this.specificTypeGroup = 'event';
                    }
                }
            },

            setSelectedAccount(taskEvent, lead) {
                if (!lead) {
                    lead = taskEvent.lead;
                }

                if (lead) {
                    this.selectedAccount = {
                        id: lead.account.id,
                        label: lead.account.name,
                    };
                } else {
                    this.selectedAccount = {
                        id: this.taskOwner.account?.id || this.contextAccount?.id,
                        label: this.taskOwner.account?.name || this.contextAccount?.name,
                    };
                }
            },

            async openAddTaskEvent({ leadId, taskEventTypeId, startAt, guests, specificTypeGroup = null, homepage = false }) {
                this.opened = true;

                this.specificTypeGroup = specificTypeGroup;

                this.defaultLead = leadId;

                await this.setLead(leadId);

                if (this.isParentAccount && !!this.lead.id) {
                    this.selectedAccount = {
                        id: this.lead.account.id,
                        label: this.lead.account.name,
                    };
                }

                this.selectedGuests = guests || [];

                if (taskEventTypeId) {
                    this.taskEvent.task_event_type_id = taskEventTypeId;
                }

                await this.$nextTick(); // Next tick necessary to overwrite startAt

                this.setDefaultDeliverer();

                if (startAt) {
                    this.taskEvent.start_at = startAt;
                }

                if (homepage) {
                    this.homepage = true;
                }
            },

            adjustDatesBaseOnStartAt(oldStartAt) {
                const newStartAt = as_locale(this.taskEvent.start_at, 'start_at');

                this.taskEvent.end_at = locale_dt(newStartAt).addHours().toString();

                // Update reminders date
                if (oldStartAt) {
                    const diff = newStartAt.diffInMinutes(as_locale(oldStartAt, 'start_at'));

                    for (const reminder of this.taskEvent.reminders) {
                        reminder.date = locale_dt(reminder.date).addMinutes(diff).toString();
                    }
                }

                if (this.isDelivery) {
                    this.taskEvent.end_at = DeliveryTimeframe.calculateEndDate(
                        newStartAt,
                        this.contextAccount.delivery_timeframe,
                    );
                } else if (this.isAppointment || this.isPhoneAppointment || this.isOther) {
                    this.taskEvent.end_at = newStartAt.addHours().toString();
                }
            },

            adjustDatesBasedOnEndAt() {
                const endAt = as_locale(this.taskEvent.end_at, 'end_at');

                if (
                    as_locale(this.taskEvent.start_at, 'start_at').isAfter(endAt) &&
                    this.selectedTaskEventType.type != 'task'
                ) {
                    this.taskEvent.start_at = endAt.subHours().toString();
                }
            },

            adjustDatesBasedOnTaskEventType() {
                if (this.userSelectedStartDate != null) {
                    this.taskEvent.start_at = this.userSelectedStartDate;
                    this.adjustDatesBaseOnStartAt();
                } else if (!this.isEvent && !this.isUpdate) {
                    this.taskEvent.start_at = now().addDays().toString();
                    this.taskEvent.end_at = now().addDays().addMinutes(30).toString();
                } else if (!this.isUpdate) {
                    const newStartAt = now().addMinutes();

                    if (this.isDelivery) {
                        this.taskEvent.end_at = DeliveryTimeframe.calculateEndDate(
                            newStartAt,
                            this.contextAccount.delivery_timeframe,
                        );
                    } else {
                        this.taskEvent.end_at = now().addHours().addMinutes().toString();
                    }

                    this.taskEvent.start_at = newStartAt.toString();
                }
            },

            hasFutureEvent(taskEvents) {
                return (taskEvents || []).some(event => {
                    return as_locale(event.start_at, 'start_at').isFuture() && !event.status;
                });
            },

            onShowNextStep({ lead, afterCallNextStep }) {
                if (this.opened && this.authUser.next_step && lead) {
                    this.shouldOpenNextStepOnClose = true;
                }

                if (
                    this.opened ||
                    !this.authUser.next_step ||
                    !lead ||
                    !this.shouldShowNextStepBaseOnDate(lead) ||
                    (this.authUser.next_step_mandatory && this.hasFutureEvent(lead.task_events))
                ) {
                    return;
                }

                this.afterCallNextStep = afterCallNextStep || false;
                this.isNextStep = true;
                this.openAddTaskEvent({ leadId: lead.id });
                this.selectedAccount = {
                    id: lead.account.id,
                    label: lead.account.name,
                };
                this.$notify.warning(this.$t('toastr.planYourNextStep'));
            },

            shouldShowNextStepBaseOnDate(lead) {
                if (lead.account.next_step_stopping === 'never' || !lead.account.next_step_stopping) {
                    return true;
                }

                if (as_locale(lead[lead.account.next_step_stopping], lead.account.next_step_stopping).isValid()) {
                    return false;
                }

                return true;
            },

            reset() {
                const clonedDefaults = cloneDeep(defaults);

                Object.keys(clonedDefaults).forEach(key => {
                    this[key] = clonedDefaults[key];
                });

                this.taskEvent.userId = null;
            },

            close() {
                this.modals.showUpdateFutureEvent = false;
                this.pendingPayload = {};
                this.selectedAccount = {};
                this.opened = false;
            },

            onCanceled() {
                if (this.isNextStep) {
                    this.$behavior.track('NextStep', { action: 'close' });
                }
            },

            cancel() {
                this.onCanceled();
                this.close();
            },

            updateVehicles(newVehicleList) {
                if (newVehicleList.length) {
                    this.taskEvent.wanted_vehicle_ids = newVehicleList.map(vehicle => vehicle.id);
                    return;
                }

                this.taskEvent.wanted_vehicle_ids = null;
            },

            onUpdateGuest(newGuestList) {
                const guestRemoved = differenceBy(this.selectedGuests, newGuestList, 'id');
                const guestAdded = differenceBy(newGuestList, this.selectedGuests, 'id');
                const groupRemoved = guestRemoved.find(guest => guest.isGroup);
                const groupAdded = guestAdded.find(guest => guest.isGroup);

                if (groupAdded) {
                    const combinedUsersToChecked = this.getUniqueUsersToModifyChecked(groupAdded);

                    this.selectedGuests = uniqBy(newGuestList.concat(combinedUsersToChecked), 'id');
                    return;
                }

                if (groupRemoved) {
                    const combinedUsersToUnchecked = this.getUniqueUsersToModifyChecked(groupRemoved);

                    this.selectedGuests = newGuestList.filter(
                        guest => !combinedUsersToUnchecked.some(user => user.id == guest.id),
                    );
                    return;
                }

                if (guestAdded.length > 0) {
                    const groups = this.getCompleteGuestGroup(newGuestList).map(group => {
                        return {
                            id: `G${group.id}`,
                            isGroup: true,
                            name: `[${group.name}]`,
                            roles: group.roles,
                            users: group.users,
                            posts: group.posts,
                        };
                    });
                    this.selectedGuests = uniqBy(newGuestList.concat(groups), 'id');
                    return;
                }

                if (guestRemoved.length > 0) {
                    this.selectedGuests = newGuestList;
                    const guestGroupsToRemove = this.getGuestGroupToRemove(newGuestList);

                    if (guestGroupsToRemove.length) {
                        this.selectedGuests = newGuestList.filter(
                            guest => !guestGroupsToRemove.some(group => group.key == guest.id),
                        );
                    }
                }
            },

            onOpen() {
                this.setDefaultConfirmationTimeFrame();
                this.$wait.start('opening.taskEventModal');
            },

            async onOpened() {
                // Next Tick necessary to make sure statement relying on this state are completed.
                this.$nextTick(() => {
                    this.$wait.end('opening.taskEventModal');
                });
            },

            onClosed() {
                this.isNextStep = false;

                if (this.afterCallNextStep) {
                    this.$broadcasting.channels.user.whisper('closeAllTabs', {});
                }

                // Next Tick necessary to prevent "afterCallNextStep" overwrite
                this.$nextTick(() => {
                    if (this.shouldOpenNextStepOnClose) {
                        this.shouldOpenNextStepOnClose = false;
                        this.onShowNextStep({ lead: this.lead });
                    }
                    this.reset();
                });
            },

            updateSelectedAccount(value) {
                this.selectedAccount = value;
            },

            onTaskEventUpdated(event) {
                if (event.id == this.taskEvent.id && event.status) {
                    this.taskEvent.status = true;
                }
            },

            getUniqueUsersToModifyChecked(group) {
                const usersToUpdateByRoles = this.getActiveUsersBySelectedRoles(group.roles);
                const usersToUpdateByPosts = this.getActiveUsersBySelectedPosts(group.posts);
                const usersToUpdateByUsers = this.getActiveUsersFromGuestGroups(group.users);

                return uniqBy(usersToUpdateByRoles.concat(usersToUpdateByPosts).concat(usersToUpdateByUsers), 'id');
            },

            trackStatusUpdate() {
                if (this.homepage) {
                    this.$behavior.track('Homepage', { action: 'updateTaskEventStatus', location: 'taskEventModal' });
                }
            },
        },

        created() {
            this.$eventBus.$on('close-task-event', this.close);
            this.$eventBus.$on('open-edit-task-event', this.openEditTaskEvent);
            this.$eventBus.$on('open-add-task-event', this.openAddTaskEvent);
            this.$eventBus.$on('show-next-step', this.onShowNextStep);
            this.$eventBus.$on('task-event-updated', this.onTaskEventUpdated);
        },

        beforeDestroy() {
            this.$eventBus.$off('close-task-event', this.close);
            this.$eventBus.$off('open-edit-task-event', this.openEditTaskEvent);
            this.$eventBus.$off('open-add-task-event', this.openAddTaskEvent);
            this.$eventBus.$off('show-next-step', this.onShowNextStep);
            this.$eventBus.$off('task-event-updated', this.onTaskEventUpdated);
        },
    };
</script>
