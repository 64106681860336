import Session from '@/entities/Session.js';
import { cloneDeep, debounce, isEmpty, isPlainObject } from 'lodash-es';
import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import { gql } from 'apollo-boost';
import { showError, showWarning } from '@/utils/toastr.js';
import { empty, merge } from '@/utils/index.js';
import { useClientCardStore } from '@/store/modules/clientCard/store.js';
import { useDashboardStore } from '@/store/modules/dashboard/store.js';
import { useContextStore } from '@/store/modules/context/store.js';
import { useGlobalStore } from '@/store/store.js';
import axios from '@/plugins/axios.js';
import i18n from '@/plugins/vue-i18n.js';
import { apolloClient } from '@/plugins/vue-apollo.js';
import { useCustomerStore } from '@/store/modules/customer/store.js';
import { useLeadStore } from '@/store/modules/lead/store.js';
import caching from '@/plugins/caching.js';
import {
    CORE_ACCOUNT_FIELDS,
    CORE_DIVISION_FIELDS,
    CORE_EMAIL_CONFIG_FIELDS,
    CORE_POST_FIELDS,
    CORE_USER_FIELDS,
    CORE_USER_PHONE_FIELDS,
} from '@/graphql/fragments.js';
import AuthUser from '@/entities/AuthUser.js';
import UserRepository from '@/graphql/repositories/UserRepository.js';
import User from '@/entities/User.js';
import { useAssociatedLeadsStore } from './modules/associatedLeads/store.js';
import Moment from '../value-objects/Moment.js';
import Lead from '../entities/Lead.js';
import CommunicationKind from '../entities/CommunicationKind.js';

import LeadType from '../entities/LeadType.js';

export default {
    resetState() {
        this.$reset();
    },

    setPageTitle(pageTitle) {
        this.pageTitle = pageTitle;
    },

    setMergedLeadId(mergedLeadId) {
        this.mergedLeadId = mergedLeadId;
    },

    syncAssociatedLeadsCustomer(lead) {
        if (!lead.customer?.leads?.length) {
            return;
        }

        const ids = lead.customer.leads.map(lead => lead.id);

        useGlobalStore().leads
            .filter(l => ids.includes(l.id))
            .forEach(relatedLead => {
                relatedLead.customer_id = cloneDeep(lead.customer_id);
                relatedLead.customer = cloneDeep(lead.customer);
            });
    },

    addLead(newLead) {
        const leadIndex = this.leads.findIndex(l => l.id == newLead.id);

        if (leadIndex !== -1) {
            this.leads.splice(leadIndex, 1, newLead);
        } else {
            this.leads.push(newLead);
        }
    },

    updateLead({ id, newData }) {
        const updateLead = lead => {
            if (newData.hasOwnProperty('wanted_vehicles') || newData.hasOwnProperty('exchange_vehicles')) {
                newData.vehicles = [
                    ...(newData.wanted_vehicles || lead.wanted_vehicles),
                    ...(newData.exchange_vehicles || lead.exchange_vehicles),
                ];
            }

            merge(lead, newData);
        };

        this.leads.filter(lead => lead.id == id).forEach(updateLead);
        useDashboardStore().tableLeads.filter(lead => lead.id == id).forEach(updateLead);
        useAssociatedLeadsStore().associatedLeads.filter(lead => lead.id == id).forEach(updateLead);
    },

    updateLeadCustomer(customer) {
        const updateCustomer = lead => {
            merge(lead.customer, customer);
        };

        this.leads.filter(lead => lead.customer_id == customer.id).forEach(updateCustomer);
        useDashboardStore().tableLeads.filter(lead => lead.customer_id == customer.id).forEach(updateCustomer);
    },

    updateLeadCustomable({ id, newData }) {
        const updateCustomable = lead => {
            lead.fillCustomable(newData.id, newData);
        };

        this.leads.filter(lead => lead.id == id).forEach(updateCustomable);
        useDashboardStore().tableLeads.filter(lead => lead.id == id).forEach(updateCustomable);
    },

    updateLeadProduct(leadProduct) {
        updateOrAddObjectInLead('products', leadProduct);
    },

    addLeadPhone(leadPhone) {
        updateOrAddObjectInLead('phones', leadPhone);
    },

    updateLeadPhone(leadPhone) {
        updateOrAddObjectInLead('phones', leadPhone);
    },

    deleteLeadPhone(leadPhone) {
        deleteObjectInLead('phones', leadPhone);
    },

    addLeadEmail(leadEmail) {
        updateOrAddObjectInLead('emails', leadEmail);
    },

    updateLeadEmail(leadEmail) {
        updateOrAddObjectInLead('emails', leadEmail);
    },

    deleteLeadEmail(leadEmail) {
        deleteObjectInLead('emails', leadEmail);
    },

    addLeadVehicle(leadVehicle) {
        updateOrAddObjectInLead('vehicles', leadVehicle);
    },

    updateLeadVehicle(leadVehicle) {
        updateOrAddObjectInLead('vehicles', leadVehicle);

        useAssociatedLeadsStore().updateLeadVehicle(leadVehicle);
    },

    deleteLeadVehicle(leadVehicle) {
        deleteObjectInLead('vehicles', leadVehicle);
    },

    addCommunication(communication) {
        updateOrAddObjectInLead('communications', communication);
        useClientCardStore().addCommunication(communication);
        useCustomerStore().addCommunication(communication);
    },

    updateCommunication(communication) {
        updateOrAddObjectInLead('communications', communication);
        useClientCardStore().updateCommunication(communication);
        useCustomerStore().updateCommunication(communication);
    },

    deleteCommunication(communication) {
        deleteObjectInLead('communications', communication);
        useClientCardStore().deleteCommunication(communication);
        useCustomerStore().deleteCommunication(communication);
    },

    addTaskEvent(taskEvent) {
        updateOrAddObjectInLead('task_events', taskEvent);
        useClientCardStore().addTaskEvent(taskEvent);
        useCustomerStore().addTaskEvent(taskEvent);
    },

    updateTaskEvent(taskEvent) {
        updateOrAddObjectInLead('task_events', taskEvent);
        useClientCardStore().updateTaskEvent(taskEvent);
        useCustomerStore().updateTaskEvent(taskEvent);
    },

    deleteTaskEvent(taskEvent) {
        deleteObjectInLead('task_events', taskEvent);
        useClientCardStore().deleteTaskEvent(taskEvent);
        useCustomerStore().deleteTaskEvent(taskEvent);
    },

    deleteLead(id) {
        const filterLead = lead => {
            return lead.id != id;
        };

        this.leads = this.leads.filter(filterLead);
        this.leads.forEach(lead => {
            if (lead.customer?.leads?.length) {
                lead.customer.leads = lead.customer.leads.filter(l => l.id != id);
            }
        });

        useDashboardStore().tableLeads = useDashboardStore().tableLeads.filter(filterLead);
        useDashboardStore().tableLeads.forEach(lead => {
            lead.associated_leads = lead.associated_leads.filter(leadId => {
                return leadId != id;
            });
        });

        useAssociatedLeadsStore().removeLead(id);
    },

    updateAutomationJob({ id, leadId, payload }) {
        const updatedAutomationJob = {
            id,
            lead_id: leadId,
            ...payload,
        };

        updateOrAddObjectInLead('automation_jobs', updatedAutomationJob);

        useClientCardStore().updateCommunicationAutomationJob(updatedAutomationJob);
        useCustomerStore().updateCommunicationAutomationJob(updatedAutomationJob);
    },

    setMergedLead(mergedLead) {
        this.mergedLead = mergedLead;
    },

    setTaskEventToBeCompleted(taskEvent) {
        this.taskEventToBeCompleted = taskEvent;
    },

    appendNewError(options) {
        const defaults = {
            message: '',
            code: '',
            display: false,
            request: null,
            error: null,
            data: null,
        };

        const { message, code, display, error, ...informations } = { ...defaults, ...options };
        const responseStatus = error?.response?.status;

        if (![401, 403].includes(responseStatus)) {
            if (!isEmpty(code)) {
                Sentry.withScope(scope => {
                    scope.setExtras({
                        timestamp: Moment.now().toUtcDateTimeString(),
                        code,
                        error,
                        ...informations,
                    });
                    Sentry.captureMessage(`Error on requests - ${code}`, 'warning');
                });
            } else {
                this.errors.push({
                    timestamp: Moment.now().toUtcDateTimeString(),
                    code,
                    error,
                    ...informations,
                });
            }
        }

        if (display) {
            showError(message, code);
        }
    },

    resetErrors() {
        this.errors = [];
    },

    updateVehicleRecordedDate({ leadId, newLeadData }) {
        if (!('delivered_date' in newLeadData)) {
            return;
        }

        const lead = this.leads.find(l => l.id == leadId);

        if (!lead) {
            return;
        }

        if (!lead.account.auto_recorded_date) {
            return;
        }

        const vehicle = lead.wanted_vehicles.find(v => v.sold) || lead.wanted_vehicles[0];

        if (vehicle && !vehicle.recorded_date) {
            vehicle.recorded_date = newLeadData.delivered_date;
        }
    },

    setParentAuthUser(parentAuthUser) {
        this.parentAuthUser = new AuthUser(parentAuthUser);
    },

    updateParentAuthUser(newData, syncChilds = false) {
        Object.assign(this.parentAuthUser, newData);

        if (syncChilds) {
            this.parentAuthUser.children.forEach(child => Object.assign(child, newData));
        }
    },

    setConfigs(configs) {
        this.configs = { ...this.configs, ...configs };
    },

    setFeaturePreviews(featurePreviews) {
        this.featurePreviews = featurePreviews;
    },

    setGroups(groups) {
        this.groups = groups;
    },

    setAccounts(accounts) {
        this.accounts = accounts;
    },

    setTemplateAttributes(templateAttributes) {
        this.templateAttributes = templateAttributes;
    },

    setPdfTemplates(pdfTemplates) {
        this.accountPdfTemplates = pdfTemplates;
    },

    setAccountVehicleModels(accountVehicleModels) {
        this.accountVehicleModels = accountVehicleModels || [];
    },

    setSpecificPhoneToCall(specificPhoneToCall) {
        this.specificPhoneToCall = specificPhoneToCall;
    },

    updateSession(newSession) {
        const session = cloneDeep(this.session);

        for (const [key, value] of Object.entries(newSession)) {
            if (key == 'user') {
                continue;
            }

            if (!isPlainObject(value) || value instanceof Moment) {
                session[key] = value;
            } else {
                session[key] = { ...session[key], ...value };
            }
        }

        this.session = new Session(newSession);
        this.updateCacheUserSession();
    },

    updateLocale(newLocale) {
        this.configs.locale = newLocale;
    },

    updateWebOrderBadgeCount(newCount) {
        this.webOrderBadgeCount = newCount;
    },
    setMergedLeadIdAction(mergedLeadId) {
        if (empty(mergedLeadId)) {
            return;
        }

        // Make sure ID has a valid format
        if (mergedLeadId != parseInt(mergedLeadId, 10)) {
            Vue.router.replace('/');
            return;
        }

        const existingLead = this.leads.find(lead => {
            return lead.id == mergedLeadId;
        });

        if (!empty(existingLead)) {
            this.setMergedLead(existingLead);
            this.setMergedLeadId(mergedLeadId);
            return;
        }

        Vue.api.leads.show(mergedLeadId).then(lead => {
            this.addLead(new Lead(lead));
            this.setMergedLead(lead);
            this.setMergedLeadId(mergedLeadId);
        });
    },
    async reloadLead(leadId) {
        if (!leadId) {
            return;
        }

        const leadExists =
            this.leads.some(l => l.id == leadId) ||
            useDashboardStore().tableLeads.some(l => l.id == leadId) ||
            Vue.stats.leadExists(leadId);

        if (leadExists) {
            const lead = await Vue.api.leads.show(leadId);

            this.leadUpdated(lead);
        }
    },
    leadUpdated(lead) {
        // Verify if in table
        const tableLead = this.leads.find(l => l.id == lead.id);

        if (tableLead) {
            Vue.eventBus.$emit('reload-calendar', { leadId: lead.id });
        }

        this.updateLead({
            id: lead.id,
            newData: lead,
        });

        Vue.stats.updateLead(lead.id, lead);
    },
    leadPhoneCreated(leadPhone) {
        this.addLeadPhone(leadPhone);
        Vue.stats.addSubEntity('lead_phones', leadPhone);
    },

    leadPhoneUpdated(leadPhone) {
        this.updateLeadPhone(leadPhone);
        Vue.stats.updateSubEntity('lead_phones', leadPhone);
    },

    leadPhoneDeleted(leadPhone) {
        this.deleteLeadPhone(leadPhone);
        Vue.stats.deleteSubEntity('lead_phones', leadPhone);
    },

    leadEmailCreated(leadEmail) {
        this.addLeadEmail(leadEmail);
        Vue.stats.addSubEntity('lead_emails', leadEmail);
    },

    leadEmailUpdated(leadEmail) {
        this.updateLeadEmail(leadEmail);
        Vue.stats.updateSubEntity('lead_emails', leadEmail);
    },

    leadEmailDeleted(leadEmail) {
        this.deleteLeadEmail(leadEmail);
        Vue.stats.deleteSubEntity('lead_emails', leadEmail);
    },

    leadVehicleCreated(leadVehicle) {
        this.addLeadVehicle(leadVehicle);
        Vue.stats.addSubEntity(`${leadVehicle.type}_vehicles`, leadVehicle);
    },

    leadVehicleUpdated(leadVehicle) {
        this.updateLeadVehicle(leadVehicle);
        Vue.stats.updateSubEntity(`${leadVehicle.type}_vehicles`, leadVehicle);
    },

    leadVehicleDeleted(leadVehicle) {
        this.deleteLeadVehicle(leadVehicle);
        Vue.stats.deleteSubEntity(`${leadVehicle.type}_vehicles`, leadVehicle);
    },
    communicationCreated(communication) {
        const isMassMailing = communication.kind == CommunicationKind.MASS_MAILING;

        if (!isMassMailing) {
            this.addCommunication(communication);
        }

        Vue.stats.addSubEntity('communications', communication);
    },
    communicationUpdated(communication) {
        this.updateCommunication(communication);
        Vue.stats.updateSubEntity('communications', communication);
    },

    communicationDeleted(communication) {
        this.deleteCommunication(communication);
        Vue.stats.deleteSubEntity('communications', communication);
    },

    taskEventCreated(taskEvent) {
        this.addTaskEvent(taskEvent);
        Vue.stats.addSubEntity('task_events', taskEvent);
        Vue.eventBus.$emit('task-event-created', taskEvent);
    },
    taskEventUpdated(taskEvent) {
        this.updateTaskEvent(taskEvent);
        Vue.stats.updateSubEntity('task_events', taskEvent);
        Vue.eventBus.$emit('task-event-updated', taskEvent);
    },

    taskEventDeleted(taskEvent) {
        this.deleteTaskEvent(taskEvent);
        Vue.stats.deleteSubEntity('task_events', taskEvent);
        Vue.eventBus.$emit('task-event-deleted', taskEvent);
    },

    commentCreated(comment) {
        if (comment.lead_id == useContextStore().leadId) {
            Vue.eventBus.$emit('refresh-comments');
        }
    },

    commentUpdated(comment) {
        if (comment.lead_id == useContextStore().leadId) {
            Vue.eventBus.$emit('refresh-comments');
        }
    },

    commentDeleted(comment) {
        if (comment.lead_id == useContextStore().leadId) {
            Vue.eventBus.$emit('refresh-comments');
        }
    },
    userSeenEmailRead(communicationId) {
        debounce(() => {
            return new Promise((resolve, reject) => {
                if (!communicationId) {
                    return;
                }

                const payload = {
                    user_seen_email_read_at: Moment.now().toString(),
                    from: 'userSeenEmailRead',
                };

                // Update the user seen email at field
                axios
                    .patch(`v1/communications/${communicationId}`, payload)
                    .then(response => {
                        this.communicationUpdated(response.data.data);
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response) {
                            switch (error.response.status) {
                                case 400:
                                    showWarning();
                                    return;

                                case 403:
                                    return;

                                case 404:
                                    showWarning(i18n.t('error.leadNotFound'));
                                    return;
                            }

                            this.appendNewError({
                                code: '0106',
                                display: false,
                                error,
                                payload: { communicationId, ...payload },
                            });
                        }

                        reject(error);
                    });
            });
        }, 1000);
    },

    clearSingleSeenEmailReadAlert(communication) {
        if (communication.user_id == this.authUser.id) {
            this.userSeenEmailRead(communication.id);
        }
    },
    clearAllSeenEmailReadAlert(communications) {
        communications.forEach(communication => {
            if (
                communication.communication_method_id == 2 &&
                communication.communication_type_id == 1 &&
                Moment.asLocale(communication.email_read_at, 'email_read_at').isValid() &&
                !Moment.asLocale(communication.user_seen_email_read_at, 'user_seen_email_read_at').isValid() &&
                communication.user_id == this.authUser.id
            ) {
                this.userSeenEmailRead(communication.id);
            }
        });
    },
    /**
     * Triggers the request to update a lead.
     */
    async requestUpdateLead(payload, id) {
        // Make sure id is set
        if (empty(id)) {
            return null;
        }

        try {
            const response = await axios.put(`v1/leads/${id}`, payload);
            const lead = response.data.data;

            Vue.eventBus.$emit('cancel-follow-up');

            // Manually reload calendar since lead is not updated...
            if (payload.main_event_data && payload.main_event_data.canceled) {
                Vue.eventBus.$emit('reload-calendar', {
                    leadId: id,
                    force: true,
                });
            }

            const dataToSync = {
                user: lead.user,
                bdc_user: lead.bdc_user,
                commercial: lead.commercial,
                customer: lead.customer,
                service_advisor_user: lead.service_advisor_user,
                service_agent_user: lead.service_agent_user,
                second_user: lead.second_user,
                second_bdc_user: lead.second_bdc_user,
            };

            // Manually update task event in lead...
            if (lead.task_events) {
                dataToSync.task_events = lead.task_events;
            }

            // Manually update wanted_vehicles in lead...
            if (lead.wanted_vehicles) {
                dataToSync.wanted_vehicles = lead.wanted_vehicles;
            }

            this.updateLead({
                id,
                newData: dataToSync,
            });

            return response;
        } catch (error) {
            if (!error.response) {
                return null;
            }

            switch (error.response.status) {
                case 403:
                    return null;

                case 404:
                    showWarning(i18n.t('error.leadNotFound'));
                    return null;
            }

            this.appendNewError({
                code: '0048',
                display: true,
                error,
                payload,
            });
        }

        return null;
    },
    /**
     * Updates a lead. If no leadId is passed, it uses the contextLeadId. (Also updates the stat lead).
     */
    // eslint-disable-next-line consistent-return
    updateLeadAction(leadId, newData, options = {}) {
        let successCallBack = null;

        Vue.eventBus.$emit('fetch-stats', { background: true });

        const lead = this.leads.find(tmpLead => {
            return tmpLead.id == leadId;
        });

        // On change of lead_type_id
        if (lead && typeof newData.lead_type_id !== 'undefined') {
            const presentedDate = Moment.asLocale(lead.presented_date, 'presented_date');
            const callDate = Moment.asLocale(lead.call_date, 'call_date');

            if (newData.lead_type_id == LeadType.WALK_IN && !presentedDate.isValid()) {
                newData.presented = true;
                newData.presented_date = Moment.now().toString();
            }

            if (newData.lead_type_id == LeadType.PHONE && !callDate.isValid()) {
                newData.call_date = Moment.now().toString();
            }

            // Renewal
            if (newData.lead_type_id == LeadType.RENEWAL) {
                if (['invalid', 'duplicate'].includes(lead.status)) {
                    newData.status = null;
                }

                // @TODO Validate if still required...
                successCallBack = response => {
                    for (const updatedLead of response.data.data) {
                        const stateLead = this.leads.find(l => l.id == updatedLead.id);

                        if (!empty(stateLead)) {
                            stateLead.exchange_vehicles = updatedLead.exchange_vehicles;
                        }
                    }
                };
            }
        }

        this.updateLead({
            id: leadId,
            newData,
        });

        this.updateVehicleRecordedDate({
            leadId,
            newLeadData: newData,
        });

        Vue.stats.updateLead(leadId, newData);

        if (options.customField) {
            options.customField.value = Array.isArray(options.customField.value)
                ? options.customField.value
                : [options.customField.value];

            this.updateLeadCustomable({
                id: leadId,
                newData: options.customField,
            });

            newData = {
                customField: options.customField,
            };
        }

        if (options.mainEvent) {
            newData.main_event_data = options.mainEvent;
        }

        if (options.deleteUpcomingTask) {
            newData.deleteUpcomingTask = options.deleteUpcomingTask;
        }

        return this.requestUpdateLead(newData, leadId).then(response => {
            if (successCallBack) {
                successCallBack(response);
            }

            if (options.successCallback) {
                options.successCallback(response.data.data);
            }
        });
    },
    /**
     *   Updates multiples leads. If no leadId is passed, no lead will be updated
     */
    async bulkUpdateLeads(newData, leadIds, isBulkAll, dashboardCriteria) {
        if (empty(leadIds) || empty(newData)) {
            return null;
        }

        for (const leadId of leadIds) {
            if (empty(leadId)) {
                continue;
            }

            if (newData.customField) {
                this.updateLeadCustomable({
                    id: leadId,
                    newData: newData.customField,
                });
            } else {
                this.updateLead({
                    id: leadId,
                    newData,
                });

                Vue.stats.updateLead(leadId, newData);
            }
        }

        const query = (() => {
            if (isBulkAll) {
                return { search: dashboardCriteria };
            }
            return { ids: leadIds };
        })();

        try {
            return await Vue.api.leads.update(query, {
                ...newData,
                blackListedLeadIds: useDashboardStore().blackListedLeadIds,
            });
        } catch (error) {
            if (error.response?.status === 403) {
                return null;
            }

            this.appendNewError({
                code: '0049',
                display: false,
                error,
                payload: { newData },
            });
        }

        return null;
    },
    /**
     * Updates a product. If no leadId is passed, it uses the contextLeadId.
     */
    async updateLeadProductAction(newData) {
        this.updateLeadProduct(newData);

        const leadProduct = {
            id: newData.id,
            sold: newData.pivot.sold,
            price: newData.pivot.price,
            minutes: newData.pivot.minutes,
            notes: newData.pivot.notes,
        };

        return this.requestUpdateLead({ leadProduct }, newData.lead_id);
    },
    /**
     * Adds a vehicle to the lead passed. If no leadId is passed, it uses the contextLeadId.
     */
    addLeadVehicleAction(data, leadId) {
        return new Promise((resolve, reject) => {
            if (empty(data)) {
                return;
            }

            // Get lead id from context.lead if not passed
            if (empty(leadId)) {
                if (empty(useContextStore().leadId)) {
                    return;
                }

                leadId = useContextStore().leadId;
            }

            if (empty(leadId)) {
                return;
            }

            // Set lead id in params
            data.lead_id = leadId;

            axios
                .post('v1/lead-vehicles', data)
                .then(response => {
                    this.leadVehicleCreated(response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    if (error.response && error.response.status === 403) {
                        return;
                    }

                    this.appendNewError({
                        code: '0050',
                        display: true,
                        error,
                        payload: { data },
                    });

                    reject(error);
                });
        });
    },
    /**
     * Deletes a vehicle to from lead passed. If no leadId is passed, it uses the contextLeadId.
     */
    deleteLeadVehicleAction(vehicleId, leadId) {
        return new Promise((resolve, reject) => {
            if (empty(vehicleId)) {
                return;
            }

            // Get lead id from context.lead if not passed
            if (empty(leadId)) {
                if (empty(useContextStore().leadId)) {
                    return;
                }

                leadId = useContextStore().leadId;
            }

            if (empty(leadId)) {
                return;
            }

            axios
                .delete(`v1/lead-vehicles/${vehicleId}`)
                .then(response => {
                    this.leadVehicleDeleted({ id: vehicleId, lead_id: leadId });
                    resolve(response);
                })
                .catch(error => {
                    if (error.response && error.response.status === 403) {
                        return;
                    }

                    this.appendNewError({
                        code: '0051',
                        display: false,
                        error,
                        payload: { vehicleId },
                    });

                    reject(error);
                });
        });
    },

    /**
     * Updates a vehicle. If no leadId is passed, it uses the contextLeadId.
     */
    async updateLeadVehicleAction(payload, vehicleId, leadId, options) {
        if (empty(vehicleId)) {
            return null;
        }

        // Get lead id from context.lead if not passed
        if (empty(leadId)) {
            if (empty(useContextStore().leadId)) {
                return null;
            }

            leadId = useContextStore().leadId;
        }

        this.leadVehicleUpdated({ ...payload, id: vehicleId, lead_id: leadId });

        if (options && options.customField) {
            options.customField.value = Array.isArray(options.customField.value)
                ? options.customField.value
                : [options.customField.value];

            payload = {
                customField: options.customField,
            };

            this.updateLeadCustomable({
                id: leadId,
                newData: {
                    ...payload.customField,
                    vehicleId,
                },
            });
        }

        try {
            const response = await axios.patch(`v1/lead-vehicles/${vehicleId}`, payload);

            // @TODO Is this required? It's duplicated...
            this.leadVehicleUpdated({ ...response.data.data, id: vehicleId, lead_id: leadId });

            return response;
        } catch (error) {
            if (error.response && error.response.status === 403) {
                return null;
            }

            this.appendNewError({
                code: '0052',
                display: true,
                error,
                payload,
                vehicleId,
            });

            throw error;
        }
    },

    async bulkUpdateWantedVehicles(payload, leadIds, isBulkAll, dashboardCriteria) {
        useDashboardStore().tableLeads
            .filter(lead => {
                return leadIds.includes(lead.id) && !!lead.wanted_vehicles.length;
            })
            .forEach(lead => {
                const vehicleId = lead.wanted_vehicles[0].id;
                this.leadVehicleUpdated({ ...payload, id: vehicleId, lead_id: lead.id });
            });

        const query = (() => {
            if (isBulkAll) {
                return { search: dashboardCriteria };
            }
            return { ids: leadIds };
        })();

        try {
            return await Vue.api.leads.update(query, {
                wanted_vehicle: payload,
                blackListedLeadIds: useDashboardStore().blackListedLeadIds,
            });
        } catch (error) {
            if (error.response?.status === 403) {
                return null;
            }

            this.appendNewError({
                code: '0049',
                display: false,
                error,
                payload: { newData },
            });
        }

        return null;
    },

    async bulkUpdateExchangeVehicles(payload, leadIds, isBulkAll, dashboardCriteria) {
        useDashboardStore().tableLeads
            .filter(lead => {
                return leadIds.includes(lead.id) && !!lead.exchange_vehicles.length;
            })
            .forEach(lead => {
                const vehicleId = lead.exchange_vehicles[0].id;
                this.leadVehicleUpdated({ ...payload, id: vehicleId, lead_id: lead.id });
            });

        const query = (() => {
            if (isBulkAll) {
                return { search: dashboardCriteria };
            }
            return { ids: leadIds };
        })();

        try {
            return await Vue.api.leads.update(query, {
                exchange_vehicle: payload,
                blackListedLeadIds: useDashboardStore().blackListedLeadIds,
            });
        } catch (error) {
            if (error.response?.status === 403) {
                return null;
            }

            this.appendNewError({
                code: '0049',
                display: false,
                error,
                payload: { newData },
            });
        }

        return null;
    },

    /**
     * Updates a vehicle with accidented part. If no leadId is passed, it uses the contextLeadId.
     */
    async updateLeadVehicleAccidentedParts(payload, vehicleId, leadId) {
        if (empty(vehicleId)) {
            return null;
        }

        // Get lead id from context.lead if not passed
        if (empty(leadId)) {
            if (empty(useContextStore().leadId)) {
                return null;
            }

            leadId = useContextStore().leadId;
        }

        this.leadVehicleUpdated({ ...payload, id: vehicleId, lead_id: leadId });

        try {
            const response = await axios.patch(`v1/lead-vehicles/accidented/${vehicleId}`, payload);

            return response.data.data;
        } catch (error) {
            if (error.response && error.response.status === 403) {
                return null;
            }

            this.appendNewError({
                code: '0060',
                display: false,
                error,
                payload,
                vehicleId,
            });
        }

        return null;
    },

    /**
     * Decodes the vehicle's VIN.
     */
    async decodeVehicleVin({ vin, vehicleId }) {
        const response = await axios.post(`v1/lead-vehicles/${vehicleId}/decode-vin`, {
            vin,
        });

        this.updateLeadVehicle(response.data.data.updatedVehicle);

        return response.data.data;
    },

    /**
     * Decodes the vehicle's VIN.
     */
    async decodeVehicleVinAsl({ vin, vehicleId }) {
        const response = await axios.post(`v1/lead-vehicles/${vehicleId}/asl-decode-vin`, {
            vin,
        });

        this.updateLeadVehicle(response.data.updatedVehicle);

        return response.data.data;
    },

    /**
     * Updates the vehicle's VIN.
     */
    async updateVinVehicleValue({ uvc, vehicleId }) {
        const response = await axios.patch(`v1/lead-vehicles/${vehicleId}/vin-value`, {
            uvc,
        });

        this.updateLeadVehicle(response.data.data.updatedVehicle);

        return response.data.data;
    },

    /**
     * Adds a phone to the lead passed. If no leadId is passed, it uses the contextLeadId.
     */
    addLeadPhoneAction(data, leadId) {
        return new Promise((resolve, reject) => {
            if (empty(data)) {
                return;
            }

            // Get lead id from context.lead if not passed
            if (empty(leadId)) {
                if (empty(useContextStore().leadId)) {
                    return;
                }

                leadId = useContextStore().leadId;
            }

            if (empty(leadId)) {
                return;
            }

            // Set lead id in params
            data.lead_id = leadId;

            axios
                .post('v1/lead-phones', data)
                .then(response => {
                    this.leadPhoneCreated(response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    if (error.response && error.response.status === 403) {
                        return;
                    }

                    this.appendNewError({
                        code: '0053',
                        display: false,
                        error,
                        payload: { data },
                    });

                    reject(error);
                });
        });
    },

    /**
     * Deletes a phone to from lead passed. If no leadId is passed, it uses the contextLeadId.
     */
    deleteLeadPhoneAction(phoneId, leadId) {
        return new Promise((resolve, reject) => {
            if (empty(phoneId)) {
                return;
            }

            // Get lead id from context.lead if not passed
            if (empty(leadId)) {
                if (empty(useContextStore().leadId)) {
                    return;
                }

                leadId = useContextStore().leadId;
            }

            if (empty(leadId)) {
                return;
            }

            axios
                .delete(`v1/lead-phones/${phoneId}`)
                .then(response => {
                    this.leadPhoneDeleted({ id: phoneId, lead_id: leadId });
                    resolve(response);
                })
                .catch(error => {
                    if (error.response) {
                        switch (error.response.status) {
                            case 400:
                                reject(error);
                                return;

                            case 403:
                                return;
                        }
                    }

                    this.appendNewError({
                        code: '0054',
                        display: false,
                        error,
                        payload: { phoneId },
                    });

                    reject(error);
                });
        });
    },

    /**
     * Updates a phone. If no leadId is passed, it uses the contextLeadId.
     */
    updateLeadPhoneAction(newData, phoneId, leadId) {
        return new Promise((resolve, reject) => {
            if (empty(phoneId)) {
                return;
            }

            // Get lead id from context.lead if not passed
            if (empty(leadId)) {
                if (empty(useContextStore().leadId)) {
                    return;
                }

                leadId = useContextStore().leadId;
            }

            axios
                .patch(`v1/lead-phones/${phoneId}`, newData)
                .then(response => {
                    this.leadPhoneUpdated(response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    if (error.response) {
                        switch (error.response.status) {
                            case 400:
                                reject(error);
                                return;

                            case 403:
                                return;
                        }
                    }

                    this.appendNewError({
                        code: '0055',
                        display: false,
                        error,
                        payload: { newData },
                        phoneId,
                    });

                    reject(error);
                });
        });
    },

    /**
     * Adds an email to the lead passed. If no leadId is passed, it uses the contextLeadId.
     * Adds an email to the lead passed. If no leadId is passed, it uses the contextLeadId.
     */
    addLeadEmailAction(data, leadId) {
        return new Promise((resolve, reject) => {
            if (empty(data)) {
                return;
            }

            // Get lead id from context.lead if not passed
            if (empty(leadId)) {
                if (empty(useContextStore().leadId)) {
                    return;
                }

                leadId = useContextStore().leadId;
            }

            if (empty(leadId)) {
                return;
            }

            // Set lead id in params
            data.lead_id = leadId;

            axios
                .post('v1/lead-emails', data)
                .then(response => {
                    this.leadEmailCreated(response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    if (error.response && error.response.status === 403) {
                        return;
                    }

                    this.appendNewError({
                        code: '0056',
                        display: false,
                        error,
                        payload: { data },
                    });

                    reject(error);
                });
        });
    },

    /**
     * Deletes an email to from lead passed. If no leadId is passed, it uses the contextLeadId.
     */
    deleteLeadEmailAction(emailId, leadId) {
        return new Promise((resolve, reject) => {
            if (empty(emailId)) {
                return;
            }

            // Get lead id from context.lead if not passed
            if (empty(leadId)) {
                if (empty(useContextStore().leadId)) {
                    return;
                }

                leadId = useContextStore().leadId;
            }

            if (empty(leadId)) {
                return;
            }

            axios
                .delete(`v1/lead-emails/${emailId}`)
                .then(response => {
                    this.leadEmailDeleted({ id: emailId, lead_id: leadId });
                    resolve(response);
                })
                .catch(error => {
                    if (error.response && error.response.status === 403) {
                        return;
                    }

                    this.appendNewError({
                        code: '0057',
                        display: false,
                        error,
                        payload: { emailId },
                    });

                    reject(error);
                });
        });
    },

    /**
     * Updates an email. If no leadId is passed, it uses the contextLeadId.
     */
    updateLeadEmailAction(newData, emailId, leadId) {
        return new Promise((resolve, reject) => {
            if (empty(emailId)) {
                return;
            }

            // Get lead id from context.lead if not passed
            if (empty(leadId)) {
                if (empty(useContextStore().leadId)) {
                    return;
                }

                leadId = useContextStore().leadId;
            }

            axios
                .patch(`v1/lead-emails/${emailId}`, newData)
                .then(response => {
                    this.leadEmailUpdated(response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    if (error.response && error.response.status === 403) {
                        return;
                    }

                    this.appendNewError({
                        code: '0058',
                        display: false,
                        error,
                        payload: { newData },
                        emailId,
                    });

                    reject(error);
                });
        });
    },

    setUsers(accountId) {
        axios
            .get('v1/users', {
                params: {
                    accountId,
                    with: {
                        account: [
                            'id',
                            'bdc_advisor',
                            'sale_date_month',
                            'leadxpress',
                            'name',
                            'logo',
                            'logo_en',
                            'result_date_validation',
                            'sale_validation',
                            'webboost',
                            'commercial',
                            'client_number',
                            'power_sport',
                            'month_start_day',
                            'next_step_stopping',
                            'sale_table',
                            'sale_accessories',
                            'trade_report',
                            'unsubscribe',
                            'csi',
                            'sale_by_phone',
                            'updated_at',
                        ],
                        objectives: ['id', 'sale_amount', 'average_value', 'defined_date', 'user_id', 'division_id'],
                        divisions: ['id', 'name'],
                    },
                    filter: [
                        'id',
                        'first_name',
                        'last_name',
                        'role_id',
                        'post_id',
                        'account_id',
                        'profile_picture',
                        'updated_at',
                    ],
                },
            })
            .then(response => {
                if (response.data.success) {
                    // Remove commercial users
                    const filteredUsers = response.data.data.filter(value => {
                        return value.role_id != Role.COMMERCIAL;
                    });

                    useDashboardStore().users = filteredUsers;
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    return;
                }

                this.appendNewError({
                    code: '0059',
                    display: false,
                    error,
                    payload: { getParams },
                });
            });
    },

    async mergeLeads(from, to, action) {
        try {
            return await Vue.api.leads.merge(
                { id: from.id },
                {
                    lang: i18n.locale,
                    oldLeadId: to.id,
                    rule: action,
                },
            );
        } catch (error) {
            if (error.response && error.response.status === 403) {
                return null;
            }

            this.appendNewError({
                code: '0062',
                display: false,
                error,
                payload: {
                    lang: i18n.locale,
                    oldLeadId: to.id,
                    rule: action,
                },
            });

            throw error;
        }
    },

    async fetchAuthUser() {
        const authUserResponse = apolloClient.query({

            query: gql`
                ${CORE_USER_FIELDS}
                ${CORE_ACCOUNT_FIELDS}
                ${CORE_USER_PHONE_FIELDS}
                ${CORE_EMAIL_CONFIG_FIELDS}
                ${CORE_POST_FIELDS}
                query authUser {
                    authUser {
                    ...CoreUserFields
                    has_niotext
                    account {
                        ...CoreAccountFields
                        users {
                            ...CoreUserFields
                        }
                    }

                    divisions {
                        id
                    }

                    email_config {
                        ...CoreEmailConfigFields
                    }

                    groups {
                        id
                        accounts {
                            id
                        }
                        created_at
                        name
                        type
                        updated_at
                    }
                    parent {
                        ...CoreUserFields
                    }
                    phones {
                        ...CoreUserPhoneFields
                    }
                    post {
                        ...CorePostFields
                    }
                }
            }`,
        });

        const authUserChildrenResponse = apolloClient.query({
            query: gql`
                ${CORE_USER_FIELDS}
                ${CORE_ACCOUNT_FIELDS}
                ${CORE_DIVISION_FIELDS}
                ${CORE_USER_PHONE_FIELDS}
                ${CORE_EMAIL_CONFIG_FIELDS}
                ${CORE_POST_FIELDS}
                query authUserChildren {
                    authUser {
                        id
                        children {
                            ...CoreUserFields
                            account {
                                ...CoreAccountFields
                            }
                            divisions {
                                ...CoreDivisionFields
                            }
                            email_config {
                                ...CoreEmailConfigFields
                            }
                            post {
                                ...CorePostFields
                            }
                            phones {
                                ...CoreUserPhoneFields
                            }
                        }
                    }
                }
            `,
        });

        const authUserData = (await authUserResponse).data.authUser;
        authUserData.children = (await authUserChildrenResponse).data.authUser.children;

        return authUserData;
    },
    async fetchUserSessionWithCaching() {
        try {
            const session = await Vue.caching.getCache(caching.USER_SESSION_CACHE_KEY);
            if (session) {
                this.session = new Session(session);
                setTimeout(async () => {
                    await this.fetchUserSession();
                }, 2000);
            } else {
                await this.fetchUserSession();
            }
        } catch (e) {
            await this.fetchUserSession();
        }
    },
    async fetchUserSession() {
        const session = apolloClient.query({
            query: gql`query authUser_session {
                authUser {
                    session {
                        id
                        created_at
                        dashboard_column
                        dashboard_column_order
                        dashboard_dates
                        dashboard_expand
                        dashboard_filter
                        dashboard_option
                        hot_swap_date
                        order
                        per_page
                        user_id
                        team_id
                        updated_at
                    }
                }
            }`,
        });

        const sessionData = (await session).data.authUser.session;
        delete sessionData?.__typename;
        this.session = new Session(sessionData);
        this.updateCacheUserSession();
    },

    updateCacheUserSession() {
        Vue.caching.setCache(caching.USER_SESSION_CACHE_KEY, this.session);
    },

    async fetchVehicleMakes() {
        const response = await axios.get('v1/vehicles/makes');

        return response.data.data;
    },

    async fetchAslMakes() {
        const response = await axios.get('v1/asl/makes');

        return response.data.data;
    },

    async fetchVehicleModels(makeId) {
        const response = await axios.get('v1/vehicles/models', {
            params: {
                make_id: makeId,
            },
        });

        return response.data.data;
    },

    async fetchAslModels({ makeId, year }) {
        const response = await axios.get(`v1/asl/models/${makeId}/${year}`);

        return response.data.data;
    },

    async fetchVehicleYears(modelId) {
        const response = await axios.get('v1/vehicles/years', {
            params: {
                model_id: modelId,
            },
        });

        return response.data.data;
    },

    async fetchVehicleTrims(modelId) {
        const response = await axios.get('v1/vehicles/trims', {
            params: {
                model_id: modelId,
            },
        });

        return response.data.data;
    },

    async fetchAslSpecifications(aslModelId) {
        const response = await axios.get('v1/asl/specifications', {
            params: {
                asl_model_id: aslModelId,
            },
        });

        return response.data.data;
    },

    async fetchVehicleImage(vehicleId) {
        const response = await axios.get(`v1/lead-vehicles/${vehicleId}/image`);

        return response.data.data;
    },

    async fetchVehicleMakeWarranty(make, year) {
        const response = await axios.get('v1/vehicles/make-warranty', {
            params: {
                make,
                year,
            },
        });

        return response.data.data;
    },

    async fetchConfigs(accountId = null) {
        const response = await axios.get(`v1/configs?accountId=${accountId}`); // is refetched when context account was loaded

        this.setConfigs(response.data.data);
    },

    async fetchFeaturePreviews() {
        const response = await axios.get('v1/feature-previews');

        this.setFeaturePreviews(response.data.data);
    },

    async fetchTemplateAttributes(accountId = null) {
        const response = await axios.get(`v1/accounts/${accountId || useContextStore().accountId}/attributes`);

        this.setTemplateAttributes(response.data);
    },

    async fetchPdfTemplates(accountId = null) {
        const response = await axios.get(`v1/accounts/${accountId || useContextStore().accountId}/pdf-templates`);

        this.setPdfTemplates(response.data.data);
    },

    async fetchAccounts() {
        const fetchAccountQuery = apolloClient.query({
            query: gql`query accounts_mainNav {
                accounts {
                    account_manager
                    active
                    id
                    name
                }
              }`,
        });

        this.setAccounts((await fetchAccountQuery).data.accounts);
    },

    async fetchAccountVehicleModels(accountId = null) {
        const response = await axios.get(`v1/accounts/${accountId || useContextStore().accountId}/vehicle-models`);

        this.setAccountVehicleModels(response.data?.map(model => model[`display_name_${i18n.locale}`] || model.name));
    },

    updateSessionAction(type, value, overwrite = true) {
        if (useDashboardStore().viewId) {
            return;
        }

        const session = cloneDeep(this.session);
        const dashboardType = useDashboardStore().dashboardType;
        const dashboardDependantOptions = [
            'dashboard_column',
            'dashboard_column_order',
            'dashboard_dates',
            'dashboard_expand',
            'dashboard_filter',
            'order',
        ];

        if (dashboardDependantOptions.includes(type)) {
            if (empty(session[type])) {
                session[type] = {};
            }

            if (!session[type][dashboardType]) {
                session[type][dashboardType] = {};
            }

            if (overwrite) {
                session[type][dashboardType] = value;
            } else {
                session[type][dashboardType] = { ...session[type][dashboardType], ...value };
            }
        } else if (overwrite) {
            session[type] = value;
        } else {
            session[type] = { ...session[type], ...value };
        }

        this.updateSession(session);

        if (session.id) {
            axios.put(`v1/sessions/${session.id}`, session);
        }
    },

    async updateAutomationJobAction(params) {
        const { id, payload } = params;

        this.updateAutomationJob(params);

        try {
            await Vue.api.automationJobs.update(id, payload);
        } catch (error) {
            Vue.notify.error(i18n.t('automations.alerts.update.error'));
        }
    },
    async fetchLead(leadId) {
        let lead = this.leads.find(lead => lead.id == leadId);

        if (!lead) {
            lead = new Lead(await Vue.api.leads.show(leadId));
            this.addLead(lead);
        }

        return lead;
    },
    leadIsInCache(leadId) {
        return this.leads.some(lead => lead.id == leadId);
    },
    leadIsInDashboard(leadId) {
        return useDashboardStore().tableLeads.some(lead => lead.id == leadId);
    },

    async getAssignmentUsers() {
        const contextLead = useContextStore().contextLead;
        const contextAccount = useContextStore().account;
        const assignmentUsers = await contextAccount.getActiveUsers(['id']);

        const missingUserIds = new Set();

        if (contextLead.userId) {
            const assignedAdvisor = assignmentUsers.find(u => u.id == contextLead.userId);

            if (!assignedAdvisor) {
                missingUserIds.add(contextLead.userId);
            }
        }

        if (contextAccount.secondary_advisor && Vue.feature.isEnabled('secondary_advisor') && contextLead.secondaryUserId) {
            const assignedSecondaryAdvisor = assignmentUsers.find(u => u.id == contextLead.secondaryUserId);

            if (!assignedSecondaryAdvisor) {
                missingUserIds.add(contextLead.secondaryUserId);
            }
        }

        if (contextLead.commercialId) {
            const assignedCommercial = assignmentUsers.find(u => u.id == contextLead.commercialId);

            if (!assignedCommercial) {
                missingUserIds.add(contextLead.commercialId);
            }
        }

        if (contextLead.bdcUserId) {
            const assignedBdc = assignmentUsers.find(u => u.id == contextLead.bdcUserId);

            if (!assignedBdc) {
                missingUserIds.add(contextLead.bdcUserId);
            }
        }

        if (contextLead.serviceAdvisorId) {
            const assignedServiceAdvisorIncluded = assignmentUsers.some(u => u.id == contextLead.serviceAdvisorId);

            if (!assignedServiceAdvisorIncluded) {
                missingUserIds.add(contextLead.serviceAdvisorId);
            }
        }

        if (contextLead.serviceAgentId) {
            const assignedServiceAgentIncluded = assignmentUsers.some(u => u.id == contextLead.serviceAgentId);

            if (!assignedServiceAgentIncluded) {
                missingUserIds.add(contextLead.serviceAgentId);
            }
        }

        const missingUsers = await Promise.all(
            [...missingUserIds].map(userId => UserRepository.find(userId, ['first_name', 'last_name'])),
        );

        assignmentUsers.unshift(...missingUsers.map(user => new User(user)));

        return assignmentUsers;
    },
};

const updateOrAddObjectInLead = (key, updatedObject) => {
    if (!updatedObject.id || !updatedObject.lead_id) {
        return;
    }

    const updateObject = lead => {
        let existingObject;

        try {
            existingObject = lead[key].find(object => object.id == updatedObject.id);
        } catch (error) {
            Sentry.withScope(scope => {
                scope.setExtras({
                    timestamp: Moment.now().toUtcDateTimeString(),
                    lead,
                    key,
                    updatedObject,
                });
                Sentry.captureMessage('Error on requests - Trying to update undefined lead key in try catch', 'warning');
            });

            return;
        }

        if (existingObject) {
            merge(existingObject, updatedObject);
        } else {
            lead[key].push(updatedObject);
        }
    };

    useGlobalStore().leads.filter(lead => lead.id == updatedObject.lead_id).forEach(updateObject);
    useDashboardStore().tableLeads.filter(lead => lead.id == updatedObject.lead_id).forEach(updateObject);

    if (['phones', 'emails'].includes(key) && updatedObject.lead_id == useContextStore().leadId) {
        useLeadStore().checkCommunicating(updatedObject.lead_id);
    }
};

const deleteObjectInLead = (key, deletedObject) => {
    if (!deletedObject.id || !deletedObject.lead_id) {
        return;
    }

    const deleteObject = lead => {
        const index = lead[key].findIndex(object => object.id == deletedObject.id);

        if (index !== -1) {
            lead[key].splice(index, 1);
        }
    };

    useGlobalStore().leads.filter(lead => lead.id == deletedObject.lead_id).forEach(deleteObject);
    useDashboardStore().tableLeads.filter(lead => lead.id == deletedObject.lead_id).forEach(deleteObject);

    if (['phones', 'emails'].includes(key) && deletedObject.lead_id == useContextStore().leadId) {
        useLeadStore().checkCommunicating(deletedObject.lead_id);
    }
};
