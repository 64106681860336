export default {
    dateTooltip: "Date de l'événement: {eventDate}<br/>Date de l'attribution: {attributionDate}",
    salesJourneyEvents: 'Événements du parcours de vente',
    viewSalesJourneyEvents: 'Voir les événements du parcours de vente',

    replacePrimaryEvent: {
        confirmation: 'Cette attribution va remplacer certaines informations dans le lead de destination.<br/>@:general.areYouSure',

        currentInformation: 'Information actuelle',
        afterAttribution: "Après l'attribution",
    },
};
