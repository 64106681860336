<template>
    <nav class="grow flex flex-col overflow-hidden">
        <vue-scroll
            :ops="{ scrollPanel: { scrollingX: false } }"
            ref="scroll"
            @handle-scroll="onScroll"
        >
            <div class="flex flex-col pt-4 pb-10" ref="content">
                <!--Homepage-->

                <div v-if="parentAuthUser.hasAccessTo('homepage')">
                    <expandable-main-nav-item
                        icon="regular/house-chimney-2"
                        :to="{ name: 'homepage'}"
                        :title="$t('navs.sidebar.homepage')"
                        :module-active="contextAccount.homepage"
                        v-show="parentAuthUser.hasAccessTo('homepage')"
                    />
                </div>

                <!-- CLIENT -->

                <!--All Lead-->
                <expandable-main-nav-item
                    icon="regular/multiple-neutral-2"
                    :to="{ name: 'dashboards.allLead', query: dashboardQuery }"
                    :title="$t('navs.sidebar.allLead')"
                    v-show="parentAuthUser.hasAccessTo('dashboards.allLead')"
                />

                <div class="h-12" v-if="parentAuthUser.hasAccessTo('homepage')" />

                <!-- Web Order -->
                <expandable-main-nav-item
                    :icon="$icons.webOrder"
                    :module-active="contextAccount.hasOption('web_order')"
                    :to="{ name: 'dashboards.webOrder', query: dashboardQuery }"
                    :title="$t('navs.sidebar.webOrder')"
                    :badge="webOrderBadgeCount"
                    :badge-tooltip="$tc('navs.sidebar.webOrderBadgeTooltip', webOrderBadgeCount)"
                    v-show="parentAuthUser.hasAccessTo('dashboards.webOrder')"
                />

                <!-- LeadXpress -->
                <expandable-main-nav-item
                    :icon="$icons.leadXpress"
                    :module-active="contextAccount.hasOption('leadxpress')"
                    :to="{ name: 'dashboards.leadXpress', query: dashboardQuery }"
                    :title="$t('navs.sidebar.leadXpress')"
                    v-show="parentAuthUser.hasAccessTo('dashboards.leadXpress')"
                />

                <!-- Walk-in -->
                <expandable-main-nav-item
                    :icon="$icons.visit"
                    :module-active="contextAccount.hasOption('walk_in')"
                    :to="{ name: 'dashboards.walkIn', query: dashboardQuery }"
                    :title="$t('navs.sidebar.walkIn')"
                    v-show="parentAuthUser.hasAccessTo('dashboards.walkIn')"
                />

                <!-- Phone-up -->
                <expandable-main-nav-item
                    icon="regular/phone"
                    :module-active="contextAccount.hasOption('phone_up')"
                    :to="{ name: 'dashboards.phoneUp', query: dashboardQuery }"
                    :title="$t('navs.sidebar.phoneUp')"
                    v-show="parentAuthUser.hasAccessTo('dashboards.phoneUp')"
                />

                <!-- Renewal -->
                <expandable-main-nav-item
                    :icon="$icons.renewal"
                    :module-active="contextAccount.hasOption('renewal')"
                    :to="{ name: 'dashboards.renewal', query: dashboardQuery }"
                    :title="$t('navs.sidebar.renewal')"
                    v-show="parentAuthUser.hasAccessTo('dashboards.renewal')"
                />

                <!-- Loyalty -->
                <expandable-main-nav-item
                    :icon="$icons.loyalty"
                    :module-active="contextAccount.hasOption('loyalty')"
                    :to="{ name: 'dashboards.loyalty', query: dashboardQuery }"
                    :title="$t('navs.sidebar.loyalty')"
                    v-show="parentAuthUser.hasAccessTo('dashboards.loyalty')"
                />

                <!-- Service -->
                <expandable-main-nav-item
                    :icon="$icons.service"
                    :flip-icon="true"
                    :module-active="contextAccount.hasOption('service')"
                    :to="{ name: 'dashboards.service', query: dashboardQuery }"
                    :title="$t('navs.sidebar.service')"
                    v-show="parentAuthUser.hasAccessTo('dashboards.service')"
                />

                <!-- TOOLS -->
                <div class="h-12" />

                <!-- Inventory -->
                <expandable-main-nav-item
                    icon="regular/car-actions-search-1"
                    :module-active="contextAccount.hasActiveInventorySupplier()"
                    :title="$t('navs.sidebar.inventory')"
                    :to="{ name: 'inventory', query: inventoryQuery }"
                    @click="$behavior.track('inventory', { action: 'pageLinkClicked', location: 'expandableNav'})"
                    v-show="parentAuthUser.hasAccessTo('dashboards.inventory')"
                />

                <!-- Sale Table -->
                <expandable-main-nav-item
                    icon="regular/award-trophy-star-1"
                    :module-active="contextAccount.hasOption('sale_table')"
                    :to="{ name: 'dashboards.saleTable', query: dashboardQuery }"
                    :title="$t('navs.sidebar.saleTable')"
                    :force-active="$route.name === 'objectives.saleTable'"
                    v-show="parentAuthUser.hasAccessTo('dashboards.saleTable')"
                />

                <!-- In Turn -->
                <expandable-main-nav-item
                    :icon="$icons.inTurn"
                    :module-active="contextAccount.hasOption('in_turn')"
                    :to="{ name: 'inTurn.management' }"
                    :title="$t('navs.sidebar.inTurn')"
                    v-show="parentAuthUser.hasAccessTo('inTurn.management')"
                />

                <!-- WebBoost -->
                <expandable-main-nav-item
                    :icon="$icons.webBoost"
                    :module-active="contextAccount.hasOption('webboost')"
                    :to="{ name: 'dashboards.webBoost', query: dashboardQuery }"
                    :title="$t('navs.sidebar.webBoost')"
                    v-show="parentAuthUser.hasAccessTo('dashboards.webBoost')"
                />

                <!-- Commercial -->
                <expandable-main-nav-item
                    icon="regular/credit-card-1"
                    :module-active="contextAccount.hasOption('commercial')"
                    :to="{ name: 'dashboards.commercial', query: dashboardQuery }"
                    :title="$t('navs.sidebar.commercial')"
                    v-show="parentAuthUser.hasAccessTo('dashboards.commercial')"
                />

                <!-- NioText -->
                <expandable-main-nav-item
                    icon="regular/messages-bubble-typing-1"
                    :module-active="contextAccount.hasOption('niotext')"
                    title="NioText"
                    :to="nioTextUrl"
                    v-if="parentAuthUser.selfOrChildHasNiotext()"
                />

                <!-- Campaign -->
                <expandable-main-nav-item
                    icon="light/performance-increase-mail"
                    :module-active="contextAccount.hasOption('campaign')"
                    :title="$t('navs.sidebar.campaign')"
                    :to="{ name: 'campaign', query: campaignQuery}"
                    v-if="parentAuthUser.hasAccessTo('campaign')"
                />

                <!-- Deskit -->
                <expandable-main-nav-item
                    icon="regular/accounting-calculator"
                    title="Deskit"
                    :to="deskitUrl"
                    v-if="hasDeskitSupplier"
                />

                <!-- Event -->
                <expandable-main-nav-item
                    :icon="$icons.event"
                    :module-active="contextAccount.hasOption('event')"
                    :to="{ name: 'dashboards.event', query: dashboardQuery }"
                    :title="$t('navs.sidebar.event')"
                    v-show="parentAuthUser.hasAccessTo('dashboards.event')"
                />

                <!-- Activity Report -->
                <expandable-main-nav-item
                    icon="regular/graph-stats"
                    :module-active="contextAccount.hasOption('activity_report')"
                    :to="{ name: 'dashboards.activityReport', query: dashboardQuery }"
                    :title="$t('navs.sidebar.activityReport')"
                    :force-active="$route.name === 'objectives.activityReport'"
                    v-show="parentAuthUser.hasAccessTo('dashboards.activityReport')"
                />

                <!-- Analytics -->
                <expandable-main-nav-item
                    icon="regular/analytics-graph-bar"
                    :to="{ name: 'analytics', query: analyticsQuery }"
                    :title="$t('navs.sidebar.analytics')"
                    v-show="showAnalyticModule"
                />

                <template v-if="!mdLayout">
                    <!-- MANAGEMENT -->
                    <div class="h-12" />

                    <!-- Response template -->
                    <expandable-main-nav-item
                        icon="regular/content-pencil-write"
                        :to="{ name: 'templates.index' }"
                        :title="$t('navs.sidebar.templates')"
                        v-show="parentAuthUser.hasAccessTo('templates.index')"
                    />

                    <!-- Automation -->
                    <expandable-main-nav-item
                        icon="regular/video-game-magic-wand"
                        :module-active="contextAccount.hasOption('automation')"
                        :to="{ name: parentAuthUser.hasAccessTo('automations.index') ? 'automations.index' : '' }"
                        :title="$t('navs.sidebar.automations')"
                        v-show="parentAuthUser.hasAccessTo('automations.index')"
                    />

                    <!-- Schedule -->
                    <expandable-main-nav-item
                        icon="regular/calendar-clock"
                        :to="{ name: 'schedule' }"
                        :title="$t('navs.sidebar.schedule')"
                        v-show="parentAuthUser.hasAccessTo('schedule')"
                    />

                    <!-- Flow -->
                    <expandable-main-nav-item
                        icon="regular/filter-1"
                        :to="{ name: 'flow' }"
                        :title="$t('navs.sidebar.flow')"
                        :force-active="$route.name && $route.name.startsWith('flow')"
                        v-show="parentAuthUser.hasAccessTo('flow')"
                    />

                    <!-- Duplicates -->
                    <expandable-main-nav-item
                        :icon="$icons.duplicate"
                        :module-active="contextAccount.hasOption('duplicates')"
                        :to="{ name: 'duplicates' }"
                        :title="$t('navs.sidebar.duplicates')"
                        v-show="parentAuthUser.hasAccessTo('duplicates')"
                    />

                    <!-- Import -->
                    <expandable-main-nav-item
                        icon="regular/cloud-upload"
                        :to="{ name: 'imports.index' }"
                        :title="$t('navs.sidebar.import')"
                        v-show="parentAuthUser.hasAccessTo('imports.index')"
                    />

                    <!-- Trashed lead -->
                    <expandable-main-nav-item
                        icon="regular/bin-paper"
                        :to="{ name: 'leads.trash' }"
                        :title="$t('navs.sidebar.trash')"
                        v-show="parentAuthUser.hasAccessTo('leads.trash')"
                    />
                </template>
            </div>
        </vue-scroll>

        <div
            :class="[
                'flex flex-col py-2',
                'transition-all duration-300',
                !scrolledToBottom ? 'shadow-t-lg' : 'shadow-none',
            ]"
        >
            <expandable-main-nav-item
                icon="regular/question-circle"
                as="button"
                title="Support"
                @click.native="openFreshdesk"
            />
            <expandable-main-nav-item
                :icon="navLocked ? 'regular/arrow-button-right-1' : 'regular/arrow-button-left-1'"
                as="button"
                :title="navLocked ? $t('navs.unlockNavigation') : $t('navs.lockNavigation')"
                @click.native="toggleNavLock"
                v-if="!isTouchDevice"
            />
        </div>
    </nav>
</template>

<script>
    import { mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store.js';
    import { useGlobalStore } from '@/store/store.js';
    import { useLayoutStore } from '@/store/modules/layout/store.js';

    import { deskitSsoUrl } from '@/utils/index.js';
    import { isTouchDevice } from '@/utils/device.js';
    import $behavior from '@/plugins/mixpanel.js';

    import ExpandableMainNavItem from '@/components/nav/ExpandableMainNavItem.vue';
    import FreshdeskUrl from '@/mixins/FreshdeskUrl.js';

    import SupplierId from '@/entities/enums/SupplierId.js';

    export default {
        components: {
            ExpandableMainNavItem,
        },

        mixins: [FreshdeskUrl],

        data: () => ({
            isTouchDevice: isTouchDevice(),
            scrolledToBottom: false,
        }),

        computed: {
            $behavior() {
                return $behavior;
            },
            ...mapState(useLayoutStore, ['navExpanded', 'navLocked']),

            ...mapState(useGlobalStore, [
                'webOrderBadgeCount',
                'parentAuthUser',
            ]),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            showAnalyticModule() {
                const accountHasAnalytics = this.contextAccount.parents.find(parent => parent.analytics_workspace_id) || this.contextAccount.analytics_workspace_id;

                return this.authUser.analytics_module_access &&
                    this.parentAuthUser.hasAccessTo('analytics') &&
                    accountHasAnalytics;
            },

            dashboardQuery() {
                if (!this.$route.name?.includes('dashboards')) {
                    return null;
                }

                return { ...this.$route.query, viewId: null };
            },

            campaignQuery() {
                return { accountId: this.contextAccount.id };
            },

            inventoryQuery() {
                return { accountId: this.contextAccount.id };
            },

            analyticsQuery() {
                return { accountId: this.contextAccount.id };
            },

            deskitUrl() {
                return deskitSsoUrl();
            },

            hasDeskitSupplier() {
                return this.contextAccount.suppliers.some(supplier => supplier.id === SupplierId.DESKIT);
            },

            nioTextUrl() {
                return `${process.env.VUE_APP_NIOTEXT_URL}#/login?token=${this.parentAuthUser.niotext_token}`;
            },
        },

        methods: {
            toggleNavLock() {
                this.$eventBus.$emit('toggle-nav-lock');
            },

            onScroll(event) {
                const scrollPosition = Math.ceil(event.scrollTop + this.$refs.scroll.$el.clientHeight);
                const contentHeight = this.$refs.content.clientHeight;

                this.scrolledToBottom = scrollPosition >= contentHeight;
            },
        },
    };
</script>
