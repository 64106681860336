export default {
    account: 'Compte',
    action: 'Action',
    activateConfirmationApptService: 'L\'activation de cette option entrainera l\'envoi en double de vos confirmations de RDV au service, car elles sont déjà envoyées avec votre DMS. @:general.wishToProceed',
    additionalNotes: 'Informations additionnelles',
    addComment: 'Ajouter une note',
    addCommentOrUpload: 'Ajoutez une note ou déposez un fichier ici',
    addCommentMentions: 'Mentionner',
    addCommentMentionsDisabled: 'Vous ne pouvez pas faire de mention en mode note privée',
    addCommunication: 'Ajouter une communication',
    addLead: 'Ajouter un lead',
    addNewLead: 'Il est impossible d\'ajouter un véhicule actuel pour un @:lexicon.portfolio.singular . Veuillez créer un nouveau lead @:lexicon.portfolio.singular pour celui-ci',
    addOriginalEmail: 'Courriel original',
    addPrecision: 'Veuillez préciser',
    address: 'Adresse',
    addVehicle: 'Ajouter un véhicule',
    addVisitWithoutAppointmentWarning: 'Il est fortement recommandé d\'inscrire un rendez-vous afin de ne pas altérer les statistiques entre le nombre de visites et le nombre de rendez-vous.<br><br>Êtes-vous certain de vouloir continuer?',
    advisor: 'Conseiller',
    alertInvalidStatus: 'Vous ne pouvez pas invalider le lead, car RV / visite / @:lexicon.sale.singular / @:lexicon.delivery.singular est planifié',
    noAccessToSelectOption: 'Vous n\'avez pas accès à la sélection de cette option',
    alertSent: 'Alerte envoyée',
    all: 'Tous',
    alreadyExported: 'Déjà exporté',
    answered: 'Répondu',
    appointment: 'Rendez-vous',
    approved: 'Approuvé',
    assignationDontAllow: 'Vous ne pouvez pas vous assigner sur le lead. Veuillez faire une demande d\'assignation',
    assignationRequest: 'Demande d\'assignation',
    assignationsAndDates: 'Assignations & dates',
    assignedRequestAdvisor: '{user} a fait une demande d\'assignation. Elle est en cours d\'approbation',
    assignedRequestNeedToBeApprovedAdvisor: '{user} a fait une demande d\'assignation. Veuillez l\'approuver/la refuser en cliquant sur le nom du conseiller',
    assignedRequestNeedToBeApprovedBdc: '{user} a fait une demande d\'assignation. Veuillez l\'approuver/la refuser en cliquant sur le nom de l\'agent',
    associate: 'Associer',
    associateLead: 'Associer',
    associatingLead: 'Association du lead en cours',
    association: 'Association',
    attachFiles: 'Joindre des fichiers',
    attachFilesCount: '{count} fichier sélectionné | {count} fichiers sélectionnés',
    attachments: 'Pièces jointes',
    attemped: 'Tentative',
    attempted: 'Tentative',
    audio: 'Audio',
    autoCalculatedField: 'Champ calculé automatiquement',
    automatedEmail: 'Courriel automatisé',
    automatedSms: 'Sms automatisé',
    availableDate: 'Véh. ici le',
    basicInfo: 'Informations de base',
    bdc: 'Agent BDC',
    bdcAgent: 'Agent BDC',
    beBack: 'B-back',
    bestMatch: 'Meilleur match',
    birthDate: 'Date de naissance',
    blockedPhoneModification: 'Ce téléphone est lié à une conversation NioText et ne peut pas être supprimé. Souhaitez-vous modifier ce téléphone?',
    business: 'Entreprise',
    businessTooltip: 'Client entreprise',
    call: 'Appeler',
    callDate: 'Date de l\'appel',
    callDescription: 'Description de l\'appel',
    cannotAddVehicleRenewal: 'Action impossible',
    cantAddAudioNoteWithText: 'Vous ne pouvez pas ajouter un audio sur une note contenant du texte',
    cantAssociateEventLead: 'Vous ne pouvez pas dissocier un lead événement.',
    cantAssociateLeadWithEventRelated: 'Vous ne pouvez pas dissocier un lead relié à un lead événement.',
    cantSelectActualLead: 'Le lead actuel ne peut pas être sélectionné.',
    cantChangeDivisionLeadType: 'Vous devez changer le type du lead "{0}" pour un autre, car ce type est seulement pour la division "{1}".',
    cantChangeDivisionSoldProducts: 'Vous ne pouvez pas changer la division "{0}" lorsque des produits ont été @:lexicon.sold.plural .',
    cantUncheckOnWalkIn: 'La visite ne peut pas être decochée lors d\'un @:lexicon.walkIn.singular',
    carIsAlreadySold: 'Ce lead à déjà une @:lexicon.sale.singular',
    cases: 'Cases',
    certified: 'Certifié',
    certifiedTooltip: 'Véhicule d\'occasion certifié',
    changeValueCBB: 'La valeur a été définie par Canadian Black Book, êtes-vous certain de vouloir la modifier?',
    changeValueCBBTitle: 'Valeur définie par Canadian Black Book',
    chooseAssociationRule: 'Veuillez choisir votre règle d\'association',
    chooseDeliveredVehicle: 'Veuillez choisir le véhicule @:lexicon.delivered.singular',
    chooseDeliveryMan: 'Selectionnez un livreur',
    chooseDirector: 'Selectionnez un directeur',
    chooseFusionRule: 'Veuillez choisir votre règle de fusion',
    chooseRecordedVehicle: 'Veuillez choisir le véhicule enregistré',
    chooseRefinancedVehicle: 'Veuillez choisir le véhicule racheté',
    chooseRenewalVehicle: 'Veuillez choisir le véhicule @:lexicon.renewed.singular',
    chooseServiceVehicle: 'Veuillez choisir le véhicule pour le service',
    chooseSoldVehicle: 'Veuillez choisir le véhicule @:lexicon.sold.singular',
    chooseVehicleToExport: 'Veuillez choisir le véhicule à exporter',
    chooseCdkDriveExportOptions: 'Veuillez choisir les options de l\'export vers CDK Drive',
    chooseSupplierExportOptions: 'Veuillez choisir les options d\'export vers {0}',
    city: 'Ville',
    civility: '@.capitalize:lexicon.civility.singular',
    clickOpenNewTab: 'Ouvrir dans un nouvel onglet',
    client: 'Client',
    clientEmail: 'EMAIL DU CLIENT ',
    clientIsAllUnsubscribed: 'Le client ne désire pas que l’on communique avec lui jusqu\'au {0}',
    clientIsAllUnsubscribedForever: 'Le client ne désire pas que l’on communique avec lui',
    clientIsCallUnsubscribed: 'Le client s\'est désabonné des appels',
    clientIsEmailUnsubscribed: 'Le client s\'est désabonné de l\'envoi de courriel',
    clientIsSmsUnsubscribed: 'Le client s\'est désabonné de l\'envoi de SMS',
    clientNumber: '# de client',
    clientType: 'Type de client',
    close: 'Fermer',
    code: 'Code',
    comment: 'Notes',
    commercial: 'Directeur commercial',
    commercialShort: 'Dir. commercial',
    communicationCreatedByCrmDisabledTooltip: 'Ces champs ne sont pas modifiables sur une communication CRM',
    communicationMethod: 'Méthode',
    communicationPreference: 'Préférence',
    communicationPreferenceTooltip: 'Préférence de communication',
    communications: 'Communications avec le client',
    communicationType: 'Type de communication',
    contactType: {
        home: 'Contact Maison',
        work: 'Contact Travail',
    },
    crmCreatedAt: 'Ajouté au CRM le {date}',
    businessName: 'Nom d\'entreprise',
    casl: 'Adhésion aux communications',
    complete: 'Compléter',
    completingLead: 'Complétion du lead en cours',
    contact: 'Contact',
    contactDms: 'Contact:',
    contractEndDate: '@:clientCard.endContractDate',
    correspondingLeads: 'Liste des leads correspondants',
    country: 'Pays',
    createdAt: 'Ajouté',
    createdBy: 'Créé par',
    createLead: 'Créer un nouveau lead',
    creatingLead: 'Création du lead en cours',
    csi: 'CSI',
    csiCompleted: 'CSI complété',
    customerEmail: 'Emails du client',
    date: 'Date',
    dateTime: 'Date & heure',
    dealerTour: 'Dealer Tour',
    delete: 'Supprimer',
    deleteCommunicationConfirm: 'Cette communication sera supprimer définitivement',
    deletedAt: 'Supprimé le',
    deletedBy: 'Supprimé par',
    deleteLead: 'Supprimer le lead',
    deleteVehicle: 'Supprimer ce véhicule',
    deleteVehicleConfirm: 'Êtes-vous sûr de vouloir supprimer ce véhicule?',
    deleteVehicleSubConfirm: 'Vous ne serez pas en mesure de récupérer ces informations!',
    deliverableDate: '@.capitalize:lexicon.deliverable.singular le',
    delivered: '@.capitalize:lexicon.delivered.singular',
    deliveredBy: 'Livreur',
    deliveryDate: '@.capitalize:lexicon.deliveryDate.singular',
    deliveryPrepDate: 'Véh. ici le',
    deposit: 'Dépôt',
    vehicleNoStockNumberOrVinText: 'Seulement les véhicules avec un NIV ou un # de stock seront exportés. Voulez-vous continuer ?',
    vehicleNoStockNumberOrVinTitle: 'NIV ou # de stock obligatoire',
    discounted: 'Escompté | Escomptés',
    discountedMustBeActive: 'L\'option Escompté doit être active dans le compte pour cocher cette case',
    dissociateCurrentLeadOnly: 'Dissocier le lead courant uniquement',
    division: 'Division',
    dmsError: 'Une erreur du coté de {0} est survenue. Veuillez réessayer plus tard.',
    exportFailed: "Erreur durant l'export",
    integrationSuspended: 'Export vers {0} présentement non disponible.<br>Veuillez conctacter le Support d\'Activix pour plus d\'informations.',
    integrationExportNoAdvisorsSuppliers: "Ce lead n'a aucun conseiller assigné configuré avec {0}.",
    dmsErrorLead: 'Une erreur du coté de {0} est survenue.<br>Le lead {1} n\'a pas été exporté.',
    dmsErrorLeadLocked: 'Une erreur du coté de {0} est survenue.<br>Le lead {1} n\'a pas été exporté.<br>La fiche {0} est présentement en cours d\'édition.',
    dmsErrorLeadStock: 'Une erreur du coté de {0} est survenue.<br>Le lead {1} n\'a pas été exporté.<br>Le # stock est déjà utilisé pour un autre NIV.',
    dnd: 'NPD.',
    doNotDisturb: 'Ne pas déranger',
    dndTitle: 'Date NPD',
    dndTooltip: 'Date jusqu\'à laquelle le client ne veut pas se faire déranger',
    doNotMarket: 'Do not market',
    dollarAndLost: 'Montant & Perte',
    download: 'Télécharger',
    dropAttachment: 'Déposez le fichier ici',
    duplicate: 'Doublon',
    duplicateLead: 'Le lead a déjà été exporté.',
    durationTooltip: 'Temps de réponse<br>(à partir du CRM)',
    edit: 'Modifier',
    editing: 'Édition',
    editCommunication: 'Modifier la communication',
    edited: 'Modifié',
    email: 'Courriel',
    emailType: {
        home: '@:clientCard.email maison',
        work: '@:clientCard.email travail',
    },
    emailReadAt: 'Courriel lu à',
    endContractDate: 'Fin de contrat',
    endContractDateExchange: 'Fin de contrat (A)',
    endContractRequired: 'La date de fin de contrat dans le véhicule actuel est requise pour choisir le type @:lexicon.portfolio.singular',
    endServiceDate: 'Fin de service prévu',
    exchanges: 'Échanges',
    exchangeVehicle: 'Véhicule actuel',
    excluded: 'Exclus',
    exportedVehicleDisable: 'Vous ne pouvez pas supprimer un véhicule exporté vers {0}',
    exporting: 'Export en cours...',
    exportCompleted: 'Évaluation complétée',
    exportTo: 'Exporter vers',
    exportWaiting: 'En attente',
    generatePdfTooltip: 'Le « Véhicule principal » du lead sera utilisé dans la génération de PDF',
    goTo: 'Aller vers',
    exportToAutoVanceRequired: 'Vous devez d\'abord exporter vers Autovance',
    exportToDeskitRequired: 'Vous devez d\'abord exporter vers Deskit',
    fieldFromGroup: 'Champ provenant du groupe {0}',
    files: 'Fichiers',
    firstName: 'Prénom',
    firstUpdateTimeTooltip: 'Temps de première<br>modification',
    followUp: 'Suivi',
    freezedFinancialData: 'Bloquer les données financières',
    financialDataLocked: 'Les données financières sont bloquées',
    financialAndDelivery: 'Financement et livraison',
    funnel: 'Funnel',
    fusion: 'Fusion',
    gas: 'Ravitaillé',
    generalComment: 'Commentaires',
    generalInfo: 'Informations générales',
    group: 'Groupe',
    guestMode: 'Vous êtes en mode «invité»',
    guestModeForDeliveryMen: 'Vous êtes en mode «invité» mais vous êtes le livreur donc vous pouvez modifier les champs de @:lexicon.delivery.plural.',
    guestModeWithAction: 'Vous êtes en mode «invité», <br>pour vous assigner veuillez tenter de communiquer avec le client',
    hideSensitiveInfoMode: 'Masquer les informations sensibles',
    history: 'Historique',
    identity: {
        expired: 'Vérification d\'ID expirée',
        hasBeenVerified: 'L\'identité de ce lead a été vérifiée.',
        loading: 'Récupération de l\'ID',
        notVerified: 'ID non vérifiée',
        previouslyVerified: 'L\'ID de ce lead a déjà été vérifiée.',
        unavailable: 'Vérification d\'ID non disponible',
        verified: 'ID Vérifiée',
    },
    included: 'Inclus',
    indefinitely: 'Indéfiniment',
    info: 'Info',
    inspected: 'Inspecté',
    institution: 'Institution',
    institutionVehicle: 'Institution',
    integrationLeadBlockedTooltip: 'Impossible de changer le type de lead provenant d\'une intégration',
    invalid: 'Invalide',
    invalidDate: 'Date invalide',
    invalidLeadTypeForServiceLead: 'Vous ne pouvez choisir ce type pour un lead service',
    inventoryUnitId: 'Unité d\'inventaire',
    invoiced: 'Facturé',
    isSubscribed: 'Abonné',
    isNotSubscribed: 'Désabonné',
    isUnsubscribedAll: 'Désabonné de tous',
    keepThisLead: 'Créer comme nouveau',
    keyword: 'Mot-clé',
    language: 'Langue',
    lastName: 'Nom',
    lastPresentedDate: 'Dernière visite',
    lastUpdated: 'Dernière modification',
    leadAlreadyAssigned: 'Vous n’êtes pas assigné sur le lead.',
    leadAssociated: 'Lead associé, redirection',
    leadCannotHaveStatus: 'Le lead ne peut pas avoir le statut de perdu, doublon ou invalide pour cocher cette case',
    leadCompleted: 'Lead complété, redirection',
    leadCreated: 'Lead créé, redirection',
    leadForm: 'Formulaire',
    leadManagement: 'Gestion du lead',
    leadMustBeSold: 'Le lead doit être @:lexicon.sold.singular pour pouvoir être @:lexicon.delivered.singular',
    leadMustBeRenewed: 'Le lead doit être @:lexicon.renewed.singular pour pouvoir être @:lexicon.delivered.singular',
    leadMustHaveASoldWantedVehicle: 'Le lead doit avoir un véhicle recherché @:lexicon.sold.singular pour y ajouter une date de @:lexicon.delivery.singular',
    leadRequirementForAction: 'Le lead doit être {0} pour effectuer cette action',
    leadsInSalesJourney: '{0} leads dans ce parcours de vente',
    leadxpressEmail: 'Courriel',
    legend: 'Légende',
    linkedDuplicate: 'Doublons de ce lead',
    longTerm: 'Long terme',
    lost: 'Perdu',
    loyalty: 'Loyauté',
    loyaltyTooltip: 'Client Loyauté',
    manualCall: 'Appel Manuel',
    manualSMS: 'SMS Manuel',
    callLogCommunication: 'Sys. Tél. Api',
    mergeFillTooltip: 'La fonction "Compléter" ajoute les informations dans les champs vides du CRM uniquement.',
    mergeLead: 'Fusionner',
    mergeLeadRenewNote: '* Si un véhicule actuel du lead source n\'est pas présent dans le lead @:lexicon.portfolio.singular de destination, un nouveau lead @:lexicon.portfolio.singular sera créé et associé au lead destination.',
    mergeReplaceTooltip: 'La fonction "Remplacer" remplace les données du CRM par celles disponibles provenant de l’import pour les champs en concurrence, sans affecter les autres champs.',
    messenger: 'Messenger',
    messengerDescription: 'Description de la conversation Messenger',
    met: 'Rencontré',
    minAssignReasonRequest: 'La raison doit être un minimum de 10 charactères',
    modifyDeliveryDate: 'La @:lexicon.delivery.singular doit être décochée pour modifier cette case',
    msrp: '(PDSF)',
    more: 'Plus',
    mustBeDelivered: 'Le lead doit être @:lexicon.delivered.singular pour pouvoir cocher cette case',
    name: 'Nom complet',
    navigationHistory: 'Historique de navigation',
    navigationHistoryAbr: 'Historique de nav.',
    navigationHistoryClickMsg: 'Cliquer pour voir l\'historique de navigation',
    navigationHistoryEmpty: 'Aucune historique de navigation',
    needAppointmentChecked: 'Le rendez-vous doit être coché pour modifier cette case',
    needAppointmentUnchecked: 'Le rendez-vous doit être décoché pour modifier cette case',
    needCommercial: 'Contactez-nous pour activer le module "Directeur commercial"',
    needCsiUnchecked: 'CSI doit être décochée pour modifier cette case',
    needDeliveredChecked: 'La @:lexicon.delivery.singular doit être cochée pour modifier cette case',
    needDeliveredUnchecked: 'La @:lexicon.delivery.singular doit être décochée pour modifier cette case.',
    neededDataForRenewal: '@.capitalize:lexicon.portfolio.prefix automatique requiert une date de fin de contrat ou la modalité "Cash Deal" sur le véhicule @:lexicon.sold.singular avant de pouvoir modifier cette case.',
    needInvoicedChecked: 'La facturation doit être cochée pour modifier cette case',
    needModalityInfo: 'La modalité du véhicule @:lexicon.sold.singular doit être "cash deal" ou avoir une date de fin de contrat',
    needOneCurrentVehicle: 'Un véhicule actuel avec une date de fin de contrat ou une modalité \'Cash Deal\' est requis pour choisir le type @:lexicon.portfolio.singular',
    needPaymentAndFrequencyEmpty: 'Le solde ne peut pas être modifié lorsque les champs paiement et fréquence sont remplis',
    needPresentedChecked: 'La visite doit être cochée pour modifier cette case.',
    needPresentedDealerChecked: 'Une visite au concessionnaire est nécessaire pour modifier cette case.',
    needPresentedUnchecked: 'La visite doit être décochée pour modifier cette case',
    needRefinancedUnchecked: 'Le rachat doit être décoché pour modifier cette case',
    needRenewalChecked: '@.capitalize:lexicon.portfolio.prefix doit être coché pour modifier cette case',
    needRenewalUnchecked: '@.capitalize:lexicon.portfolio.prefix doit être décoché pour modifier cette case.',
    needSaleByPhoneUnchecked: 'La @:lexicon.sale.singular par tél. doit être décochée pour modifier cette case.',
    needSaleChecked: 'La @:lexicon.sale.singular doit être cochée pour modifier cette case',
    needSaleUnchecked: 'La @:lexicon.sale.singular doit être décochée pour modifier cette case.',
    needToBeCommercial: 'Vous devez être directeur commercial pour modifier ce champs',
    needToBeDirector: 'Vous devez être directeur pour modifier ce champs',
    needVehicleInfoAndStockForSale: 'Vous devez remplir les champs marque, modèle et stock du véhicule pour modifier cette case',
    needVehicleInfoForSale: 'Vous devez remplir les champs marque et modèle du véhicule pour modifier cette case',
    needVehicleInfoToSelectSoldVehicle: 'Vous devez remplir les champs marque, modèle et stock pour sélectionner ce véhicule',
    newAssociate: 'Associé',
    newest: 'Plus récent',
    newLead: 'Nouveau lead',
    nextDashboardLead: 'Lead suivant du tableau',
    nextPresentedDate: 'Prochaine relance',
    nextStep: 'Prochaine étape',
    noAttachments: 'Aucune pièces jointes',
    noComment: 'Aucune note',
    noCommunication: 'Aucune communication',
    noDescription: 'Aucune description',
    noEmail: 'Aucun courriel',
    noLeadFound: 'Aucun doublon potentiel trouvé, création du lead en cours...',
    noMakeModel: 'Aucun véhicule',
    noSelection: 'Aucune sélection',
    noSms: 'Aucun SMS',
    notes: 'Notes',
    audioNotesFiles: 'Notes audio & fichiers',
    noteDeleteMessage: 'Êtes-vous certain de vouloir supprimer cette note?',
    notMet: 'Non rencontré',
    notOnLeadRenewal: 'Il est impossible de modifer ce champ lorsque le lead est de type @:lexicon.portfolio.singular .',
    notSold: 'Non @:lexicon.sold.singular',
    noAdvisorForExport: 'Un conseiller doit être assigné afin d\'exporter ce lead',
    noAdvisorTitle: 'Aucun conseiller',
    nonConsumerInitiated: 'Non généré par le consommateur',
    noVehicle: 'Aucun véhicule, appuyez sur le + pour en ajouter un',
    noVehicleFilter: 'Aucun véhicule ne correspond au filtre',
    numberMustBeMobile: 'Le numéro doit être un cellulaire valide.',
    odometerLastVisit: 'KM dern. visite',
    oldest: 'Plus ancien',
    openAutoVance: 'Ouvrir Autovance',
    openDeskit: 'Ouvrir Deskit',
    openWorkOrderDate: 'Ouverture W.O.',
    openWorkOrderDateTooltip: 'Ouverture du bon de travail',
    optOut: 'Opt-out',
    order: 'Commande | Commandes',
    orderEmpty: 'Aucune Commande',
    origin: 'Source',
    originalCreatedAt: 'Date de création originale',
    originalEmail: 'Afficher le courriel original',
    others: 'Autres',
    paperwork: 'Dossier',
    pbsVehicleNoVINText: 'Seuls les véhicules avec un NIV seront exportés. Voulez-vous continuer ?',
    pbsVehicleNoVINTitle: 'NIV obligatoire',
    pending: 'Attente',
    performance: 'Performances',
    personalInfo: 'Informations client',
    phone: 'Téléphone',
    phoneAbr: 'Tél.',
    phoneAppointment: 'Rendez-vous téléphonique',
    phoneType: {
        home: 'Téléphone maison',
        cell: 'Cellulaire',
        work: 'Téléphone travail',
    },
    plannedEmail: 'Courriel Planifié',
    plannedPickUpDate: 'Récupération prévue',
    popular: 'Plus populaire',
    postalCode: {
        CA: 'Code postal',
        US: 'Code postal',
    },
    preferredPhone: 'Numéro de téléphone préféré',
    preferredEmail: 'Adresse courriel préférée',
    prepaid: 'Prépayé',
    prepaidTooltip: 'Client Prépayé',
    prepared: 'Préparé',
    prepared_work_order: 'P.W.O.',
    preparedWorkOrderPlaceholder: 'P.W.O. #12348900',
    preparedWorkOrderTooltip: 'Prepared work order',
    presented: 'Visite',
    presentedDate: 'Date de visite',
    previousDashboardLead: 'Lead précédent du tableau',
    preQualified: {
        expired: 'Pré-Qualification expirée',
        for: 'Pré-Qualifié pour',
        forUpTo: 'Pré-Qualifié pour un maximum de',
        loading: 'Récupération de la pré-qualification',
        notSubmitted: 'Pré-Qualification non soumise',
        previouslyPreQualified: 'Ce lead a été préalablement pré-qualifié.',
        title: 'Pré-Qualifié',
        unableToProvide: 'Nous ne sommes pas en mesure de fournir des options de pré-qualification pour le moment en raison d\'informations manquantes ou non valides soumises par le client.',
        unavailable: 'Pré-Qualification non disponible',
    },
    primaryContact: 'Contact principal',
    primaryContactOptions: {
        main: 'Nom complet',
        business: 'Nom d\'entreprise',
    },
    setPrimaryVehicleTooltip: 'Définir comme véhicule principal pour la génération de PDF et de courriels',
    unsetPrimaryVehicleTooltip: 'Ne pas définir comme véhicule principal pour la génération de PDF et de courriels',
    private: 'Privé',
    privateNote: 'Note privée',
    privateNoteInfo: 'Une note privée ne peut être vue que par vous-même',
    process: 'Processus',
    processService: 'Processus (Service)',
    progress: 'Stade',
    promisedDate: 'Heure promise',
    provider: 'Fournisseur',
    providedBy: 'Fourni par',
    province: {
        CA: 'Province',
        US: 'État',
    },
    push: 'Mobile',
    qualification: 'Qualification',
    rating: 'Évaluation',
    ratingComment: 'Commentaires',
    ratingDeleteMessage: 'Êtes-vous certain de vouloir supprimer cette évaluation?',
    ratingModalTitle: 'Évaluation du client',
    ratingTooltip: 'Évaluation du client',
    reached: 'Rejoint',
    readOnly: 'Lecture Seulement',
    reassignedCommentTooltip: 'Les commentaires de réassignation ne peuvent pas être modifié',
    record: 'Enregistrer',
    recordAgain: 'Recommencer l\'enregistrement',
    recordDelete: 'Cette action supprimera votre enregistrement.',
    referrer: 'Référent',
    refinanced: 'Rachat',
    refinancedTooltip: 'La modalité du véhicule doit être "location" pour modifier cette option.',
    rejoint: 'Rejoint',
    refresh: 'Rafraîchir',
    renewal: '@.capitalize:lexicon.renewed.singular',
    renewalSuccessful: 'Lead @:lexicon.portfolio.singular créé avec succès.',
    renewed: '@.capitalize:lexicon.renewed.singular',
    repairDate: 'Date de réparation',
    repairOrder: 'Repair order #',
    reply: 'Répondre',
    replyingTo: 'Répondre à',
    reportedNotSold: 'Enregistré non @:lexicon.sold.singular | Enregistrés non @:lexicon.sold.plural',
    reportedSold: 'Enregistré @:lexicon.sold.singular | Enregistrés @:lexicon.sold.plural',
    requestSource: 'Source de la demande',
    resetCBBValue: 'Désirez-vous réappliquer la valeur du Canadian Black Book?',
    resetCBBValueTitle: 'Valeur Canadian Black Book',
    responseTimeNotApplicableMergedTooltip: 'Le temps de réponse n’est pas calculé lorsque d\'autres leads ont été fusionnés.',
    restore: 'Restaurer',
    result: '@.capitalize:lexicon.result.singular',
    revivalService: 'Relance',
    roadTest: 'Essai routier | Essais routier',
    roadTestEmpty: 'Aucun essai routier',
    roadTestAYL: 'Essai routier - AYL',
    roadTestTDM: 'Essai routier - TDM',
    sale: '@.capitalize:lexicon.sale.singular',
    saleProcess: 'Processus de vente',
    saleByPhone: '@.capitalize:lexicon.sale.singular par téléphone',
    save: 'Sauvegarder',
    searchingLead: 'Recherche de lead existant',
    searchTerm: 'Terme de recherche',
    secondContact: '2e contact',
    secondaryAdvisor: '@.capitalize:lexicon.secondaryAdvisor.singular',
    selectAction: 'Veuillez choisir l\'action à effectuer',
    selectCorrectVersion: 'Sélectionnez la bonne version du véhicule',
    sertiPushNoPhoneText: 'Le client doit obligatoirement avoir un numéro de téléphone',
    sertiPushNoPhoneTitle: 'Numéro de téléphone obligatoire',
    service: 'Service',
    serviceAdvisor: 'Aviseur technique',
    serviceAgent: 'Agent service',
    serviceIntervalKm: 'Intervalle',
    serviceMonthlyKm: 'KM mensuel',
    setNextPresentedDate: 'Selon l\'intervalle et le KM mensuel, la prochaine date de visite devrait être le <strong>{0}</strong>. Voulez-vous écraser la valeur actuelle du <strong>{1}</strong>?',
    sex: 'Genre',
    showAllAutomations: 'Afficher toutes les automatisations',
    sms: 'SMS',
    smsDescription: 'Description du message texte',
    smsDisabled: 'Les notifications SMS ont été désactivées à la demande de la direction.',
    smsTheClient: 'Texto',
    sold: '@.capitalize:lexicon.sold.singular',
    soldPrimaryVehicleInformation: 'Désigner un véhicule comme vendu le définira automatiquement comme véhicule principal du lead',
    soldPrimaryVehicleTooltip: 'Un véhicule vendu est automatiquement défini comme véhicule principal du lead',
    soldVehicleDisabled: 'Vous ne pouvez pas supprimer un véhicule @:lexicon.sold.singular',
    source: 'Source',
    state: 'Approbation',
    status: 'Statut',
    stock: 'Stock',
    storage: 'Entreposage',
    subscribed: 'Opt-out',
    subscriptionManagement: 'Gestion des abonnements',
    succefullyExported: 'Exporté avec succès',
    exportRequestInProgress: 'Export du lead vers {0} en cours',
    sureChangeLeadType: 'La modification du type de lead risque d\'entraîner des erreurs dans vos statistiques, êtes-vous certain de vouloir modifier ce champ?',
    sureMerge: 'La fusion des leads est irréversible et risque d\'entraîner des erreurs dans vos statistiques, êtes-vous certain de vouloir fusionner ces leads?',
    takeOver: 'T.O.',
    takeOverBy: 'T.O. par',
    task: 'Tâche',
    taskOwner: 'Propriétaire',
    technician: 'Technicien',
    timeElapsed: 'Temps écoulé',
    timeFrame: 'Délai d\'intention d\'achat',
    total: 'Total',
    twenty_four_hour: '24h',
    twentyFourHrTooltip: '24h',
    type: 'Type',
    unauthorizedAction: 'Vous n\'êtes pas autorisé à effectuer cette action',
    unauthorizedActionOnField: 'Vous n\'avez pas accès à modifier ce champs',
    unfreezedFinancialData: 'Débloquer les données financières',
    unknown: 'Inconnu',
    unsubscribedEmail: 'Désabonné de l\'envoi de courriel depuis le {0}',
    unsubscribedPhone: 'Désabonné des appels depuis le {0}',
    unsubscribedSince: 'Désabonné depuis le {0}',
    unsubscribedSms: 'Désabonné de l\'envoi de SMS depuis le {0}',
    updatedAt: 'Modifié',
    updatedBy: 'Modifié par',
    user: 'Utilisateur',
    validateLead: 'Sélectionnez un lead à associer ou fusionner ou cliquez sur "Annuler" avant de continuer',
    validating: 'Validation...',
    valueByCBB: 'La valeur a été définie par CBB selon la condition: {0}',
    vehicle: 'Véhicule',
    vehicleExchange: 'Véhicule actuel',
    vehicleWanted: 'Véhicule recherché',
    vehicleState: 'État du vehicule',
    vehicleEvaluate: 'Le véhicule a été évalué',
    vehicleExchangeEndWarrantyDate: 'Fin de garantie',
    vehicleExchangeExtendedWarranty: 'Garantie prolongée',
    vehicleExchangeLicensePlate: 'Numéro de plaque',
    vehicleExchangePurchaseDate: 'Date d\'achat',
    vehicleExchangeRecall: 'Rappel',
    vehicleExportedToSerti: 'Véhicule exporté vers Serti',
    verifiedSaleUneditable: 'Cette @:lexicon.sale.singular est vérifiée',
    videoconference: 'Vidéoconférence',
    videoconferenceDescription: 'Description de la vidéoconférence',
    viewSensitiveInfoMode: 'Afficher les informations sensibles',
    waitingSale: '@.capitalize:lexicon.sale.singular en att.',
    waitingSaleDisabled: 'Le lead ne doit pas être @:lexicon.delivered.singular',
    waitingSaleTooltip: 'Forcer le traitement de ce lead comme une @:lexicon.sale.singular en attente',
    walkAround: 'Walk around',
    walkIn: '@.capitalize:lexicon.walkIn.singular',
    walkInDescription: 'Description de la visite',
    wantedVehicle: 'Véhicule recherché',
    workOrder: 'W.O. #',
    workOrderClosureDate: 'Fermeture du W.O.',
    workOrderPartialClosureDate: 'Fermeture partielle du W.O.',
    workOrderTooltip: '# Bon de travail',
    writeUp: 'Quotation',
    you: 'Vous',

    assignation: {
        transferAssignedTooltip: 'Assigné : {0}',
        transferWaitingTooltip: 'En Attente : {0}',
    },
    associatedLeads: {
        assignedToMe: 'Assigné à moi',
        createAssociatedLead: 'Créer lead associé',
        close: 'Fermer',
        customerSince: 'Client depuis {0}',
        collapse: 'Réduire',
        expand: 'Agrandir',
        filterLeads: 'Filtrer les leads',
        interDealerLeads: 'Leads Interconcessionnaire',
        noVehicle: 'Aucun véhicule',
    },
    boxes: {
        clickToCollapse: 'Cliquer pour réduire',
        clickToExpand: 'Cliquer pour agrandir',
        collapse: 'Réduire',
        expand: 'Agrandir',
        filtering: 'Filtres',
        filteringAndSorting: 'Filtres & Tri',

        activities: {
            title: 'Tâches et rendez-vous',

            addActivity: 'Ajouter une tâche ou rendez-vous',
            automated: 'Automatisé',
            canceled: 'Annulé',
            enableCalendarView: 'Activer la vue calendrier',
            enableTimelineView: 'Activer la vue chonologique',
            guests: 'Invité|Invités',
            pendingActivities: '{0} tâche ou rendez-vous en attente|{0} tâches ou rendez-vous en attente',
            taskCreator: 'Créateur de tâche',
            upcomingActivities: '{0} tâche ou rendez-vous à venir|{0} tâches ou rendez-vous à venir',
            view: 'Consulter',

            filters: {
                activityType: 'Type de tâche ou rendez-vous',
                leadType: 'Type de lead',
                timelineGroup: 'Groupe',
            },

            typeAction: {
                appointment: 'RDV',
                call: 'Appel',
                csi: 'CSI',
                delivery: '@.capitalize:lexicon.delivery.singular d\'un véhicule',
                email: 'Courriel',
                other: 'Autre',
                phone_appointment: 'RDV téléphonique',
                sms: 'SMS',
                virtual_appointment: 'RDV virtuel',
                test_drive: 'Essai routier',
            },
        },
        commercialProducts: {
            title: 'Profits F&I',

            clientMet: 'Client rencontré',
            clientNotMet: 'Client non-rencontré',
            commercialSpecifications: 'Caractéristiques F&I',
            freezeFinancialData: 'Bloquer info financières',
            included: 'Inclus',
            notIncluded: 'Non-inclus',
            product: 'produit',
            profit: 'profit',
            sold: 'Vendu',
            soldProductsCount: 'Produits vendus',
            specifications: 'Caractéristiques',
        },
        communications: {
            title: 'Communications',

            automated: 'Automatisée',
            campaign: 'Campagne',
            clickedTime: 'Cliqué {0} fois',
            edit: 'Modifier',
            emailReadOn: 'Courriel lu le {0}',
            exchangeCount: 'Nombre d\'échange',
            manual: 'Manuel',
            planned: 'Planifiée',
            read: 'Lu',
            time: 'fois | fois',
            unread: 'Non lu',
            view: 'Consulter',

            conferences: {
                browserNotSupportingVideoPlaying: 'Votre navigateur ne prend pas en charge la lecture de vidéo.',
                duration: 'Durée {0}',
                join: 'Rejoindre',
                notAuthorizedToJoin: 'Vous n\'êtes pas autorisé à joindre cette vidéoconférence',
                recorded: 'Enregistrée',
                recordedVideoTooltip: 'La vidéoconférence a été enregistrée',
                recordingAccessDenied: "Vous n'avez pas accès à l'enregistrement",
                recordingVideoTooltip: 'La vidéoconférence sera enregistrée',
                videoProcessing: 'Traitement du vidéo...',
            },

            filters: {
                automation: 'Automatisation',
                direction: 'Direction',
                emailAttachment: 'Courriel avec pièce jointe',
                method: 'Méthode',
                type: {
                    title: 'Type',

                    regular: 'Régulière',
                },
            },

            ringcentralRecordings: {
                audioUnavailable: 'Audio indisponible ou en traitement',
            },
        },
        financialAndDelivery: {
            title: 'Financement et @:lexicon.delivery.singular',

            reportedOn: 'Enregistré le',

            progress: {
                approvalSent: 'Approbation envoyée',
                clientApproved: 'Client approuvé',
                deliveryDateSet: 'Date de @:lexicon.delivery.singular établie',
                deliveryDateSetWithDate: 'Date de @:lexicon.delivery.singular établie le {0}',
                vehicleDelivered: 'Véhicule @:lexicon.delivered.singular',
                vehicleDeliveredWithDate: 'Véhicule @:lexicon.delivered.singular le {0}',
                vehicleInspected: 'Véhicule inspecté',
                vehicleSold: 'Véhicule @:lexicon.sold.singular',
            },
        },
        general: {
            averageSpendingService: '$ par visite (service)',
            contactPreference: 'Préférence de contact',
            details: 'Détails de l\'opportunité',
            generalInfo: 'Information générale',
            identity: 'Identité',
            invalidPhoneNumber: 'Ce numéro de téléphone est invalide',
            lastVisitService: 'Dernière visite (service)',
            moreOptions: 'Plus d\'options',
            noBusiness: 'Pas d\'entreprise',
            noPreferences: 'Aucune préférence',
            validatingPhoneNumber: 'Validation du numéro...',
            view: 'Voir',
            viewBreakdown: 'Voir la répartition',

            tabs: {
                contact: 'Contact',
                general: 'Géneral',
            },
        },
        notes: {
            title: 'Notes',

            deleteConfirmationSingleChild: 'Êtes-vous sûr de vouloir supprimer cette note et celle associée?',
            deleteConfirmationMultipleChildren: 'Êtes-vous sûr de vouloir supprimer cette note et les {0} associées?',
            editingThisNote: 'Modification de cette note',
            filters: {
                related: 'Seulement les notes du véhicule',
                leadType: 'Type de lead',
                noteType: {
                    title: 'Type de note',

                    all: 'Tous',
                    attachment: 'Fichiers',
                    audio: 'Audio',
                    case: 'Case',
                    cases: 'Cases',
                    string: 'Notes',
                    instruction: 'Instructions',
                    reassign: 'Demande d\'assignation',
                },
            },
            notAvailableInEditMode: 'Non disponible en mode édition',
        },
        overview: {
            viewContact: 'Voir contact',
            priority: {
                high: 'Priorité élevée',
                normal: 'Priorité normale',
            },
            noDescription: 'Aucune description',
            noPlannedTask: 'Aucune tâche planifiée',
            noExcerpt: 'Aucun extrait',
            noCommunication: 'Aucune communication',
            noMileage: 'Kilométrage inconnu',
        },
        revisionsLog: {
            title: 'Historique des modifications',
        },
        revival: {
            title: 'Relance',
        },
        salesProgress: {
            title: 'Navigateur d’offres',
            negativeEquity: 'Négative',
            positiveEquity: 'Positive',
            modal: {
                actions: {
                    markVehicleAs: 'Marquer le véhicule comme',
                },
                addVehicle: 'Ajouter un véhicule',
                tabs: {
                    appraisal: 'Évaluation',
                    dealInformation: 'Information sur la vente',
                    vehicleDetails: 'Détails du véhicule',
                },
                title: {
                    tradeInVehicle: 'Véhicule actuel',
                    wantedVehicle: 'Soumission',
                },
                section: {
                    additionalInformation: 'Informations supplémentaires',
                    appraisal: 'Évaluation',
                    customFields: 'Champs personnalisés',
                    existingPaymentsAndContracts: 'Paiements et contrats existants',
                    financialInformation: 'Informations financières',
                    financialAndWarranty: 'Financement et garantie',
                    financial: 'Financement',
                    payment: 'Paiement',
                    allowanceAndResidual: 'Allocation KM et résiduel',
                    contractDetails: 'Détails du contrat',
                    overview: 'Aperçu',
                    priceAndContract: 'Prix et contrat',
                    pricingDetails: 'Détails du prix',
                    warranty: 'Garantie',
                    extendedWarranty: 'Garantie prolongée',
                    serviceFollowUp: 'Suivi du service',
                    vehicleCondition: 'État du véhicule',
                    vehicleInformation: 'Informations sur le véhicule',
                    onlineCustomerQuote: 'Soumission du client depuis AutoHebdo.ca',
                    onlineTraderQuote: 'Soumission depuis AutoHebdo.ca',
                    internalDealerQuote: 'Soumission interne du concessionnaire',
                    internalDealerQuoteTooltip: 'Pour voir la soumission du client en ligne, ouvrez cette offre dans TraderPanel.',
                    dealOnlyVisibleToDealer: 'Cette offre n\'est visible que par le concessionnaire et n\'a pas d\'impact sur le devis AutoHebdo en ligne.',
                    internalDealerTradeIn: 'Échange interne du concessionnaire',
                    doesNotImpactOnlineTradeIn: 'Les entrées suivantes n\'affectent pas l\'échange en ligne sur AutoHebdo.ca.',
                    tradeInFinancial: {
                        title: 'Échange du client depuis AutoHebdo.ca',
                        estimate: 'Estimation',
                        providedByAutotrader: 'Fourni par AutoHebdo.ca',
                        eti: 'Express Trade In',
                        owing: 'Dû / Privilège',
                        offer: 'Offre',
                    },
                },
            },
            tabs: {
                deal: 'Soumission',
                tradeIn: 'Échange',
                presentation: 'Présentation',
                billOfSale: 'Vente',
                financialAndInsuranceAndCredit: 'Financement',
                delivery: 'Livraison',
                custom: 'Personnalisé',
            },
            presentation: {
                sections: {
                    dealershipPresentation: 'Présentation du concessionnaire',
                    vehiclePresentation: 'Présentation du véhicule',
                    customFields: 'Champs personnalisés',
                },
                stepToComplete: 'Confirmez la visite du client pour compléter cette étape.',
                quoteCreated: 'Soumission créée',
                twentyFourHr: 'Suivi 24 heures',
            },
            billOfSale: {
                sections: {
                    billOfSale: 'Facture de vente',
                    customerDeposit: 'Dépôt du client',
                    customFields: 'Champs personnalisés',
                },
                billOfSaleCreated: 'Facture de vente créée',
                billOfSaleSentToCustomer: 'Envoyée au client',
                billOfSaleSignedByCustomer: 'Signée par le client',
                copyLink: 'Copier le lien vers le formulaire de dépôt',
                customerDepositReceived: 'Dépôt reçu',
                customerReceiptSent: 'Reçu envoyé',
                stepToComplete: 'Marquez un véhicule comme @.capitalize:lexicon.sold.singular pour compléter cette étape.',
            },
            deal: {
                sections: {
                    noDealTitle: 'Aucune offre pour l\'instant',
                    addVehicleText: 'Ajoutez un véhicule pour commencer à conclure une affaire',
                    addVehicleTextButton: 'Ajouter un véhicule',
                    filterVehiclesTextButton: 'Filtrer & Trier',
                },
                stepToComplete: 'Marquez un véhicule comme @.capitalize:lexicon.sold.singular pour compléter cette étape.',
                listing: {
                    title: 'Véhicules recherchés',
                    vehicleInformation: {
                        sold: '@.capitalize:lexicon.sold.singular',
                        soldToADifferentLead: '@.capitalize:lexicon.sold.singular à un autre lead',
                        soldOn: '@.capitalize:lexicon.sold.singular le',
                        payment: 'Paiement',
                        primaryVehicleTooltip: 'Véhicule principal pour la génération de PDF et de courriels',
                        rate: 'Taux',
                        stock: 'N° stock',
                        term: 'Terme',
                        modality: {
                            cash: 'Comptant',
                            leasing: 'Location',
                            financing: 'Financement',
                        },
                        frequency: {
                            bi_monthly: 'Bimensuel',
                            monthly: 'Mensuel',
                            one_payment: 'Une fois',
                            two_weeks: '2 semaines',
                            weekly: 'Hebdomadaire',
                        },
                    },
                    actions: {
                        title: 'Actions',
                        viewDeal: 'Voir l\'offre',
                        vehicleDetails: 'Détails du véhicule',
                        deleteVehicle: 'Supprimer le véhicule',
                        setPrimaryVehicle: 'Définir comme véhicule principal',
                        unsetPrimaryVehicle: 'Ne pas définir comme véhicule principal',
                        setPrimaryVehicleTooltip: 'Définir comme véhicule principal pour la génération de PDF et de courriels',
                        unsetPrimaryVehicleTooltip: 'Ne pas définir comme véhicule principal pour la génération de PDF et de courriels',
                        soldPrimaryVehicleTooltip: 'Un véhicule vendu est automatiquement défini comme véhicule principal du lead',
                        markAsSold: 'Marquer comme @.capitalize:lexicon.sold.singular',
                        markAsUnsold: 'Supprimer le statut @.capitalize:lexicon.sold.singular',
                    },
                    footer: {
                        manageInTrader: 'Gérer dans la page Hebdo',
                        customerIsCustomizing: 'Le client est en train de personnaliser son véhicule dans AutoHebdo.ca',
                        customerView: 'Page client',
                        copyCustomerLink: 'Copier lien client',
                    },
                },
                addVehicleChoices: {
                    newInventory: 'Inventaire neuf',
                    usedInventory: 'Inventaire usagé',
                    addManually: 'Ajouter un véhicule manuellement',
                },
            },
            financialAndInsuranceAndCredit: {
                sections: {
                    creditApplication: 'Demande de crédit',
                    customFields: 'Champs personnalisés',
                },
                copyLink: 'Copier le lien vers le formulaire de demande de crédit',
                lenderApproval: 'Approbation du prêteur',
                institution: 'Institution financière',
                clientApproval: 'Approbation du client',
                refinanced: 'Refinancé',
                stepToComplete: 'Confirmez l\'état de l\'approbation du client pour compléter cette étape.',
            },
            delivery: {
                sections: {
                    vehiclePreparation: 'Préparation du véhicule',
                    customerCommunication: 'Communication avec le client',
                    customFields: 'Champs personnalisés',
                },
                gas: 'Ravitaillé',
                inspected: 'Inspecté',
                prepared: 'Préparé',
                preparedWorkOrder: 'Bon de travail',
                stepToComplete: 'Confirmez la livraison du véhicule pour compléter cette étape.',
            },
            tradeIn: {
                stepToComplete: 'Confirmez le status de l\'évaluation du véhicle pour compléter cette étape.',
                listing: {
                    title: 'Véhicules actuels',
                    addVehicle: 'Ajouter un véhicule',
                    vehicleInformation: {
                        equity: 'Équité',
                        appraisal: 'Valeur de l\'évaluation',
                        resale: 'Valeur de revente',
                    },
                    actions: {
                        viewAppraisal: 'Voir l\'évaluation',
                        vehicleDetails: 'Détails du véhicule',
                        deleteVehicle: 'Supprimer le véhicule',
                    },
                },
            },
        },
        salesProcess: {
            title: 'Processus de vente',
            beBack: 'Be-Back',
            deliveryPrepDate: 'Véhicule ici le',
            takeOver: 'Takeover',
            waitingSale: '@.capitalize:lexicon.sale.singular en attente',

            progress: {
                appointmentScheduled: 'Rendez-vous planifié',
                appointmentScheduledWithDate: 'Rendez-vous planifié le {0}',
                clientVisited: 'Client rencontré',
                clientVisitedWithDate: 'Client rencontré le {0}',
                leadAcquiredWithDate: 'Lead obtenu le {0}',
                testDriveDone: 'Essai routier complété',
                testDriveDoneWithDate: 'Essai routier complété le {0}',
                vehicleSold: 'Véhicule @:lexicon.sold.singular',
                vehicleSoldWithDate: 'Véhicule @:lexicon.sold.singular le {0}',
            },
        },
        serviceProcess: {
            title: 'Processus au service',

            description: 'Description',
            price: 'Prix',
            product: 'Produit',
            sold: 'Vendu',
            time: 'Temps',
        },
        serviceProducts: {
            title: 'Services vendus',

            totalTime: 'Temps total',
            workOrder: '# Bon de travail',
        },
        vehicles: {
            currentVehiclesTitle: 'Véhicule actuel | Véhicules actuels',
            wantedVehiclesTitle: 'Véhicule recherché | Véhicules recherchés',
            noCurrentVehicleTitle: 'Aucun véhicule actuel',
            noWantedVehicleTitle: 'Aucun véhicule recherché',

            budgetRange: 'Budget recherché',
            damageValue: 'Valeur des dommages',
            details: 'Détails',
            endExtendedWarrantyDate: 'Fin de garantie prolongée',
            endExtendedWarrantyMileage: 'Fin de garantie prolongée KM',
            filtersResetOnVehicleCreation: 'Les filtres actuels seront réinitialisés après l\'ajout du véhicule. Voulez-vous continuer avec l\'ajout?',
            lengthRange: 'Longueur recherché',
            moreInfo: 'Informations additionnelles',
            negativeEquity: 'Équité négative',
            positiveEquity: 'Équité positive',
            pricingOverview: 'Aperçu des prix',
            serviceFollowUp: 'Suivi au service',
            stockToBeDefined: 'Stock à définir',
            totalRepairs: 'Réparations totales',
            yearsRange: 'Années recherchées',

            contractWidget: {
                cashdeal: 'Cash deal de {amount}',
                missingContractInfo: 'Informations de contract manquantes',
                missingEndContractDate: 'Date de fin de contrat manquante',
                missingFrequency: 'Fréquence manquante',
                missingPayment: 'Paiement manquant',
                missingPaymentInfo: 'Informations de paiement manquantes',
                missingPrice: 'Prix manquant',
                missingRate: 'Taux manquant',
                missingTerm: 'Terme manquant',
                noCashdown: 'Pas de comptant initial',
            },

            filters: {
                polestarVehicleState: 'État',
                sold: '@:clientCard.sold',
                stockNumber: '# Stock',
                stockState: 'État de stock',
                vin: 'NIV',
            },

            noModalityInfo: 'Modalités inconnues',
            noVinInfo: 'NIV inconnu',

            tabs: {
                appraisal: 'Évaluation',
                info: 'Info',
                priceAndContract: 'Prix & Contrat',
                quote: 'Soumission',
            },
        },
    },
    countries: {
        CA: 'Canada',
        US: 'États-Unis',
    },
    customFieldSections: {
        group: {
            lead: 'Lead',
            vehicleExchange: 'Véhicule actuel',
            vehicleWanted: 'Véhicule recherché',

            short: {
                lead: 'Lead',
                vehicleExchange: 'V. actuel',
                vehicleWanted: 'V. recherché',
            },
        },

        billOfSale: '@:clientCard.boxes.salesProgress.tabs.billOfSale',
        delivery: '@:clientCard.boxes.salesProgress.tabs.delivery',
        financialAndInsuranceAndCredit: '@:clientCard.boxes.salesProgress.tabs.financialAndInsuranceAndCredit',
        generalInfo: '@:clientCard.generalInfo',
        performance: '@:clientCard.saleProcess',
        vehicleWantedInfo: '@:clientCard.boxes.vehicles.tabs.info',
        vehicleWantedPriceAndContract: '@:clientCard.boxes.vehicles.tabs.priceAndContract',
        vehicleExchangeInfo: '@:clientCard.boxes.vehicles.tabs.info',
        vehicleExchangePriceAndContract: '@:clientCard.boxes.vehicles.tabs.priceAndContract',
        vehicleExchangeAppraisal: '@:clientCard.boxes.vehicles.tabs.appraisal',
        process: '@:clientCard.financialAndDelivery',
        processService: '@:clientCard.processService',
        funnel: '@:clientCard.funnel',
        dollarAndLost: '@:clientCard.dollarAndLost',
        saleProcess: '@:clientCard.saleProcess',
        financialAndDelivery: '@:clientCard.financialAndDelivery',
    },
    event: {
        callCount: 'Nombre d\'appel',
        event: 'Événement',
        segment: 'Segment',
    },
    emptyState: {
        activityTitle: 'Aucune activité',
        activityDescription: 'Il n\'y a aucune tâche reliée à ce lead.',
        activityNoMatchDescription: 'Il n\'y a aucune tâche correspondante aux filtres.',
        communicationTitle: 'Aucune communication',
        communicationDescription: 'Il n\'y a aucune communciation reliée à ce lead.',
        communicationNoMatchDescription: 'Il n\'y a aucune communication correspondante aux filtres.',
        noteTitle: 'Aucune note',
        noteDescription: 'Il n\'y a aucune note reliée à ce lead.',
        noteNoMatchDescription: 'Il n\'y a aucune note correspondante aux filtres.',
        revisionsLogTitle: 'Aucun historique de modifications',
        revisionsLogDescription: 'Il n\'y a pas d\'historique de modifications pour ce lead.',
        tradeInTitle: 'Pas encore d\'échange',
        tradeInDescription: 'Ajoutez d\'abord un véhicule pour commencer',
        tradeInAction: 'Ajouter un véhicule',
        vehicleExchangeTitle: 'Aucun véhicule actuel',
        vehicleExchangeDescription: 'Il n\'y a aucun véhicule actuel relié à ce lead.',
        vehicleExchangeNoMatchDescription: 'Il n\'y a aucun véhicule actuel correspondant aux filtres.',
        vehicleWantedTitle: 'Aucun véhicule recherché',
        vehicleWantedDescription: 'Il n\'y a aucun véhicule recherché relié à ce lead.',
        vehicleWantedNoMatchDescription: 'Il n\'y a aucun véhicule recherché correspondant aux filtres.',
    },
    products: {
        air_filter: 'Filtre air',
        alignment: 'Align.',
        anti_theft: 'Anti-vol',
        body: 'Carrosserie',
        brakes: 'Freins',
        burn_protection: 'Protection brûlure',
        chiselling: 'Burinage',
        diagnostic: 'Diagnost.',
        event: 'Événement',
        extended_warranty: 'Garantie prolongée',
        financing: 'Financement',
        financing_cash_back: 'Ristourne financement',
        flame_quard_protection: 'Protection flame Guard',
        in_turn: 'Tour de rôle',
        injection: 'Injection',
        ins_filling: 'Assurance rempl.',
        ins_health: 'Assurance maladie',
        ins_invalidity: 'Assurance invalidité',
        ins_life: 'Assurance vie',
        ins_rental: 'Assurance location',
        leather_tissu_interior_treatment: 'Traitement intérieur cuir et tissu',
        maintenance_a: 'Entretien A',
        maintenance_b: 'Entretien B',
        maintenance_c: 'Entretien C',
        maintenance_d: 'Entretien D',
        maintenance_recommended: 'Entretien Recomm.',
        mouse_repellent: 'Répulsif-souris',
        niotext: 'NioText',
        oil_filter: 'Huile & filtre',
        other: 'Autre',
        others: 'Autres',
        paint_treatment: 'Traitement de peinture',
        parts: 'Pièces',
        pef: 'PEF',
        pellicule: 'Pellicule 3M',
        pep: 'PEP',
        pollen_filter: 'Filtre pollen',
        pre_paid_maintenance: 'EPP',
        renewal: '@.capitalize:lexicon.portfolio.singular',
        roof_treatment: 'Traitement de toit',
        rustproofing: 'Antirouille',
        sale_table: 'Tableau des @:lexicon.sale.plural',
        seat_protection: 'Protection sièges',
        service: 'Service',
        starter: 'Démarreur',
        tires: 'Pneus',
        transmission: 'Transmiss.',
        walk_in: '@.capitalize:lexicon.walkIn.singular',
        wash: 'Lavage',
        wheel_protection: 'Protection jantes-pneus',
        window_tint: 'Vitres teintées',
        windshield_treatment: 'Traitement de pare-brise',
    },
    leadVehicleAccidented: {
        frontBumper: 'Pare-choc avant',
        frontDoorDriver: 'Porte conducteur avant',
        frontDoorPassenger: 'Porte passager avant',
        frontPanelDriver: 'Aile conducteur avant',
        frontPanelPassenger: 'Aile passager avant',
        hood: 'Capot',
        others: 'Autres',
        rearBumper: 'Pare-choc arrière',
        rearDoorDriver: 'Porte conducteur arrière',
        rearDoorPassenger: 'Porte passager arrière',
        rearPanelDriver: 'Aile conducteur arrière',
        rearPanelPassenger: 'Aile passage arrière',
        total: 'Total',
        windshield: 'Pare-brise',
    },
    provinces: {
        AB: 'Alberta',
        BC: 'Colombie-Britannique',
        MB: 'Manitoba',
        NB: 'Nouveau Brunswick',
        NL: 'Terre-Neuve',
        NS: 'Nouvelle-Écosse',
        NT: 'Territoires du Nord-Ouest',
        NU: 'Nunavut',
        ON: 'Ontario',
        PE: 'Île-du-Prince-Édouard',
        QC: 'Québec',
        SK: 'Saskatchewan',
        YT: 'Yukon',

        AK: 'Alaska',
        AL: 'Alabama',
        AR: 'Arkansas',
        AS: 'American Samoa',
        AZ: 'Arizona',
        CA: 'Californie',
        CO: 'Colorado',
        CT: 'Connecticut',
        DC: 'District de Colombie',
        DE: 'Delaware',
        FM: 'États fédérés de Micronesie',
        FL: 'Floride',
        GA: 'Géorgie',
        GU: 'Guam',
        HI: 'Hawaii',
        IA: 'Iowa',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiane',
        MA: 'Massachusetts',
        MD: 'Maryland',
        ME: 'Maine',
        MH: 'Iles Marshall',
        MI: 'Michigan',
        MN: 'Minnesota',
        MO: 'Missouri',
        MP: 'Îles Mariannes du Nord',
        MS: 'Mississippi',
        MT: 'Montana',
        NC: 'Caroline du Nord',
        ND: 'Dakota du Nord',
        NE: 'Nebraska',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'Nouveau-Mexique',
        NV: 'Nevada',
        NY: 'New York',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvanie',
        PR: 'Puerto Rico',
        PW: 'Palau',
        RI: 'Rhode Island',
        SC: 'Caroline du Sud',
        SD: 'Dakota du Sud',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VA: 'Virginie',
        VI: 'Îles vierges',
        VT: 'Vermont',
        WA: 'Washington',
        WI: 'Wisconsin',
        WV: 'Virginie-Occidentale',
        WY: 'Wyoming',
    },
    services: {
        averageSpending: '$ par visite',
        averageSpendingTooltip: 'Dépenses moyennes par visite',
        cleaning: 'Lavé',
        inspection: 'Essai / Inspection',
        intention: 'Intention',
        minutes: 'Minutes',
        note: 'Note',
        price: 'Prix',
        fiProfit: 'Profit F&I',
        reachedClient: 'Client rejoint',
        soldServices: 'Services vendus',
        time: 'Temps',
        transport: 'Transport',
        walkAround: 'Walk-around',

        transports: {
            courtesy: 'Courtoisie',
            drop: 'Drop',
            limousine: 'Limousine',
            other: 'Autre',
            renting: 'Location',
            wait: 'Wait',
        },
        walkArounds: {
            a1: 'A1',
            brokenGlass: 'Bris de vitre',
            bump: 'Bosse',
            cleaning: 'Lavage',
            other: 'Autre',
            scratch: 'Scratch',
        },
    },
    statusTooltip: {
        duplicate: 'Vous ne pouvez pas modifier {0} pour un lead doublon',
        invalid: 'Vous ne pouvez pas modifier {0} pour un lead invalide',
        lost: 'Vous ne pouvez pas modifier {0} pour un lead perdu',

        inputs: {
            appointment: 'la date de RV',
            approbation: 'l\'approbation',
            approved: 'le champ approuvé',
            available: 'la date de disponibilité',
            beBack: 'la date be back',
            csi: 'la date CSI',
            dealerTour: 'le champ dealer tour',
            deliverable: 'la date où le véhicule sera @:lexicon.deliverable.singular',
            delivered: 'la date de @:lexicon.delivery.singular',
            delivery: 'la date de @:lexicon.delivery.singular',
            deposit: 'le champ dépôt',
            gas: 'le champ essence',
            inspected: 'le champ inspecté',
            institution: 'l\'institution',
            longTerm: 'le champ long terme',
            phoneAppointment: 'la date du RV Téléphonique',
            prepared: 'le champ préparé',
            presented: 'la date de visite',
            qualification: 'la qualification',
            recorded: 'la date d\'enregistrement',
            refinanced: 'la date de rachat',
            roadTest: 'la date d\'essai routier',
            sale: 'la date de @:lexicon.sale.singular',
            saleByPhone: 'la date de @:lexicon.sale.singular par tel',
            takeOver: 'la date de take over',
            twentyFourHour: 'le champ 24h',
            verified: 'la vérification de la @:lexicon.sale.singular',
            walkAround: 'walk-around',
            waitingSale: 'la @:lexicon.sale.singular en attente',
            writeUp: 'le champ quotation',
        },
    },
    vehicles: {
        accessories: 'Accessoires',
        accidented_damage_cost: 'Accident $',
        accidentedTooltip: 'Indique si le véhicule actuel est accidenté',
        actual_value: 'Valeur réelle',
        actualValueTooltip: 'Valeur réelle de revente',
        addNewVehicle: 'Nouveau véhicule',
        addNewVehicleInventory: 'Véhicule d\'inventaire',
        allowed_mileage: 'KM alloué',
        allowedMileageYearly: 'km / année',
        atv: 'VTT',
        auto: 'Automatique',
        automotive: 'Automobile',
        average: 'Average',
        balance: 'Solde',
        balanceTooltip: 'Versements restants x Paiement',
        baseWarranty: 'Garantie de base',
        between_21_30_feet: '21 à 30 pieds',
        between_31_40_feet: '31 à 40 pieds',
        bike: 'Vélo',
        bi_monthly: 'Bimensuel',
        biMonthly: 'Bimensuel',
        biWeekly: '2 semaines',
        blower: 'Souffleuse',
        boat: 'Bateau',
        bodyStyle: 'Carrosserie',
        bodyType: 'Carrosserie',
        border_size: 'Taille bordure',
        budget_max: 'Budget max.',
        budget_min: 'Budget min.',
        buyBack: 'Rachat',
        calculator_value: 'Estimé',
        caravan: 'Roulotte',
        cash: 'Cash deal',
        category: 'Catégorie',
        categoryRV: 'Catégorie VR',
        class_a_diesel: 'Classe A Diesel',
        clean: 'Clean',
        color: 'Couleur',
        colorExterior: 'Couleur extérieure',
        colorInterior: 'Couleur intérieure',
        colorExtInt: 'Ext • Int',
        condition: 'Condition',
        contractEnd: 'Fin de contrat',
        decodeVin: 'Décoder NIV',
        description: 'Description',
        diesel: 'Diesel',
        division: 'Division',
        documentation: 'Documentation',
        drivingWheels: 'Roues motrices',
        electric: 'Électrique',
        endWarrantyDate: 'Fin garantie',
        engine: 'Moteur',
        equity: 'Équité',
        equityTooltip: 'Valeur - Solde - Résiduel',
        exteriorColor: 'Couleur extérieure',
        extendedWarranty: 'Garantie prolongé',
        extra_clean: 'Extra clean',
        fifth_wheel: 'Roulotte à sellette',
        financing: 'Financement',
        Financing: 'Financement',
        frequency: 'Fréquence',
        fuel: 'Carburant',
        gasoline: 'Essence',
        generator: 'Génératrice',
        horsepower: 'Chevaux',
        hybrid: 'Hybride',
        hybride: 'Hybride',
        initialCash: 'Comptant initial',
        inStock: 'En stock',
        intention: 'Intention',
        interiorColor: 'Couleur intérieure',
        in_service_date: 'Mise en service',
        kmAllowance: 'Allocation KM',
        leaseTransfer: 'Transfert de location',
        leasing: 'Location',
        Leasing: 'Location',
        length: 'Longueur',
        link: 'Lien',
        location: 'Location',
        loan: 'Prêt',
        Loan: 'Prêt',
        make: 'Marque',
        manual: 'Manuelle',
        mechanical: 'Produit méc.',
        mileage: 'Odomètre',
        mileageExcess: 'Odo. excédentaire estimé',
        mileageExcessTooltip: 'Pourcentage de l\'odomètre excédentaire estimé à la fin de la garantie',
        modality: 'Modalité',
        modalityPaymentType: 'Modalité',
        model: 'Modèle',
        monthly: 'Mensuel',
        Monthly: 'Mensuel',
        months: 'mois',
        monthsRemaining: 'Mois restant|Mois restants',
        paymentsRemaining: 'Paiement restant|Paiements restants',
        motoculteur: 'Tiller',
        motorcycle: 'Motocyclette',
        motorized: 'Motorisé',
        motorized_a: 'Motorisé A',
        motorized_b: 'Motorisé B/B+',
        motorized_c: 'Motorisé C',
        mower: 'Tondeuse',
        msrp: 'PDSF',
        noEstimate: 'Aucune valeur estimée',
        noInfo: 'Le véhicule recherché n\'a pas de marque, modèle ou d\'année',
        noStockNumber: 'Aucun numéro de stock',
        noTrim: 'Version inconnue',
        noWantedVehicle: 'Ce lead n\'a pas de véhicule recherché',
        offer_number: '# Offre',
        one_payment: 'Un paiement',
        onePayment: 'Paiement unique',
        options: 'Options',
        order_number: '# Commande',
        outside: 'Hors bord',
        over_40_feet: '41 pieds et plus',
        payment: 'Paiement',
        paymentAmount: 'Montant du paiement',
        paymentDetails: 'Détails de paiement',
        paymentType: 'Type de paiement',
        paymentFrequency: 'Fréquence de paiement',
        paymentWithTax: 'Paiement avec taxes',
        preparation: 'Préparation',
        price: 'Prix',
        profit: 'Profit',
        rate: 'Taux',
        refinance: 'Refinancer',
        refuseToRenew: 'Refuse de @:lexicon.renewed.infinitive',
        renewal: '@.capitalize:lexicon.renewed.infinitive',
        requested: '$ Souhaité',
        requestedValue: 'Valeur souhaitée',
        resaleValue: 'Valeur de revente',
        residual: 'Résiduel',
        residualValue: 'Valeur résiduelle',
        retailPrice: 'Prix de détail',
        return: 'Retour',
        rough: 'Rough',
        saved_date: 'Enrg. le',
        securityDeposit: 'Dépôt sécurité',
        sequential: 'Séquentielle',
        sleeping: 'Couchage',
        snowmobile: 'Motoneige',
        soldInDifferentLead: 'Ce stock est indiqué comme vendu dans un autre lead',
        stock: 'Stock',
        stock_number: '# Stock',
        stockState: 'État de stock',
        subtotal: 'Sous-total',
        suffix: 'Suffix',
        suv: 'VUS',
        tent_trailer: 'Tente roulotte',
        term: 'Terme',
        tire_type: 'Pneus',
        tires: 'Pneus $',
        tireTooltip: 'Pneus vendus au moment de la @:lexicon.sale.singular',
        tireTypeTooltip: 'Pneus inclus avec le véhicule actuel',
        torque: 'Couple',
        total: 'Total',
        totalPrice: 'Prix total',
        totalAllowedMileage: 'KM total alloué',
        tradeInDetails: 'Détails de l\'échange soumis par le client',
        trade_notes: 'Notes',
        trade_type: 'Achat',
        tradeIn: 'Échange',
        tradeTypeDisabledTooltip: 'Une valeur doit être inscrite avant de sélectionner cette option.',
        tradeTypeTooltip: 'À la sélection d\'une option, le véhicule sera considéré comme acheté à des fins statistiques.',
        trailer: 'Remorque',
        trailer_park: 'Roulotte de parc',
        transmission: 'Transmission',
        travel_trailer: 'Roulotte de voyage',
        trim: 'Version',
        truck: 'Camion',
        Two_weeks: 'Deux semaines',
        under_20_feet: '20 pieds et moins',
        unverify: 'Retirer la vérification',
        utility: 'Utilitaire (side by side)',
        value: 'Valeur',
        valueTooltip: 'Valeur d\'achat',
        van: 'Camionette',
        vAutoValue: '$ vAuto',
        vehicle_state: 'État du vehicule',
        vehicleStatus: 'État du vehicule',
        verify: 'Marquer comme vérifiée',
        verifiedTooltip: '@.capitalize:lexicon.sale.singular vérifiée',
        version: '@.capitalize:lexicon.version.singular',
        vin: 'NIV',
        vinDecodingFailed: 'Impossible de décoder le NIV. Assurez-vous qu\'il soit valide et formé de 17 caractères.',
        warranty: 'Garantie',
        warranty_type: 'Type de garantie',
        water_pump: 'Pompe a eau',
        watercraft: 'Motomarine',
        weekly: 'Hebdomadaire',
        Weekly: 'Hebdomadaire',
        weight: 'Poids',
        year: 'Année',
        year_max: 'Année max.',
        year_min: 'Année min.',
        estimated: 'Estimé',
        estimatedBy: 'Estimé par',
        received: 'Évalutation faite par {0} le {1}',
        openStock: 'Choisissez le véhicule en stock',
        openStockCondition: 'Une marque séléctionnée est nécessaire pour choisir un véhicule en stock',
        stockTitle: 'Inventaire de Véhicule',
        noStockFoundForFilter: 'Aucun véhicule trouvé pour un {0} {1} {2}.',
        noStockFoundForFilterWithStock: 'Aucun véhicule trouvé pour un {0} {1} {2} avec le stock {3}.',
        noStockFoundForFilterOnlyStock: 'Aucun véhicule trouvé pour le stock {0}.',
        emptyInventory: 'Aucun vehicle disponible en inventaire.',
        searchingVehicles: 'Recherche de véhicules...',
        trimMissing: 'Aucune information sur la version',
        stockMissing: 'Aucune information sur le stock',
        transmissionMissing: 'Aucune information sur la transmission',
        drivetrainMissing: 'Aucune information sur le groupe motopropulseur',
        drivetrainTransmissionMissing: 'Aucune information sur la transmission',
        mileageMissing: 'Aucune information sur le l\'odomètre',
        unavailable: 'Indisponible',
        order: 'Commande',
        locate: 'Localiser',
        pending: 'En attente',
        building: 'En production',
        transport: 'En transit',
        arrived: 'Arrivée',
        stockTBD: 'Stock à déterminer',
        stockStatusTBD: 'Statut à déterminer',
        new: 'Neuf',
        used: 'Occasion',
        certified: 'Certifié',
        demo: 'Demo',
        invoice: 'Facturé',
        production: 'En production',
        transit: 'En transit',
        hold: 'En attente',
        sold: 'Vendu',
        noInventoryUnit: 'Ce véhicule n\'est pas relié à l\'inventaire',
        noStockOrMakeModelYear: 'Ce véhicule n\'a pas de numéro de stock ou de marque, modèle et année',
        noInventoryUnitFromSupplier: 'Ce véhicule n\'est pas relié à l\'inventaire de {0}',
        noMakeModelYear: 'Ce véhicule n\'a pas de marque, de modèle ou d\'année',
        noVin: 'Ce véhicule n\'a pas de NIV',
        none: 'Aucun',

        tireType: {
            all: 'Tous',
            summer: 'Été',
            winter: 'Hiver',
        },
        tradeTypes: {
            excluded: 'Exclu',
            lost: 'Perdu',
            none: 'Aucun',
            other: 'Autre',
            recycled: 'Recyclé',
            retail: 'Détail',
            return: 'Retour',
            wholesale: 'Wholesale',
        },
        vinMissingDataModal: {
            title: 'Information à valider',
        },
    },
    unsubscribeModal: {
        call: 'Cela réactivera les appels pour ce lead.',
        email: 'Cela réactivera l\'envoi de courriel pour ce lead.',
        sms: 'Cela réactivera l\'envoi de SMS pour ce lead.',
    },
    export: {
        noVehicleOption: 'Ne pas exporter ce type de véhicule',
        noVehicleOptionEligible: 'Aucun véhicule éligible',
        lender: 'Prêteur',
        noneConfigured: 'Aucun configuré',
        purchase: 'Achat',
        lease: 'Location',
        deal: 'Deal',
        quote: 'Quotation',
        financeType: 'Type de financement',
        dealType: 'Type de transaction',
        personal: 'Personnel',
        business: 'Entreprise',
        dealerTrade: 'Transaction de concessionnaire',
        wholesale: 'Vente en gros',
        fleet: 'Flotte',
        internalTransfer: 'Transfert interne',
        refinancing: 'Refinancement',
        PreOrder: 'Précommande',
        booked: 'Réservé',
        confirmed: 'Confirmé',
        loan: 'Prêt',
        cash: 'Comptant',
        balloon: 'Balloune',
        dealStatus: 'Statut',
        frequencyType: {
            oneTime: 'Une fois',
            monthly: 'Mensuel',
            weekly: 'Hebdomadaire',
            biWkeely: 'Bi-hebdomadaire',
            yearly: 'Annuel',
        },
        wantedVehicleToExport: 'Véhicule recherché à exporter',
        exchangeVehicleToExport: 'Véhicule d\'échange à exporter',
        noInventoryUnit: 'Véhicule n\'est pas relié à l\'inventaire {0}',
    },
    blocked: {
        hasAlreadyASoldVehicle: 'Ce lead a déjà un véhicule @:lexicon.sold.singular',
        leadAlreadyHasAppointment: 'Ce lead a déjà un rendez-vous',
        leadAlreadyPresented: 'Ce lead a déjà une visite',
        leadHasDelivery: 'Ce lead a une @:lexicon.delivery.singular planifiée',
        leadIsBeBack: 'Ce lead est un be-back',
        leadIsDelivered: 'Ce lead est @:lexicon.delivered.singular',
        leadIsRefinanced: 'Ce lead est un rachat',
        leadIsRenewed: 'Le lead est @:lexicon.renewed.singular',
        leadIsServiceWithConfirmation: 'Ce lead est de la division Service et a un rendez-vous confirmé',
        leadIsSold: 'Ce lead est @:lexicon.sold.singular',
        leadIsService: 'Ce lead est de la division Service',
        leadIsWalkIn: 'Ce lead est un @.capitalize:lexicon.walkIn.singular',
        leadMustBeDelivered: 'Le lead doit être @:lexicon.delivered.singular',
        leadMustBeInvoiced: 'Le lead doit être facturé',
        leadMustBeSold: 'Le lead doit être @:lexicon.sold.singular',
        leadMustBeRenewed: 'Le lead doit être @:lexicon.renewed.singular',
        leadNeedVisit: 'Une visite est requise',
        leadNeedDivision: 'Une division est requise',

        needRenewedWantedVehicle: 'Le lead doit avoir un véhicle recherché @:lexicon.renewed.singular',
        needSoldWantedVehicle: 'Le lead doit avoir un véhicle recherché @:lexicon.sold.singular',
        needToBeDirector: 'Vous devez être directeur pour modifier ce champ',
        needVehicleInfoAndStock: 'Vous devez remplir les champs marque, modèle et stock du véhicule pour modifier ce champ',
        needVehicleInfo: 'Vous devez remplir les champs marque et modèle du véhicule pour modifier ce champ',

        readOnlyField: 'Ce champ n\'est pas modifiable',
        specificCreatedMethod: 'Ce champ n\'est pas modifiable lorsque le lead provient de "{0}"',
        tradeReport: 'Vous devez avoir le module "Rapport d\'achat"',
        unauthorizedFieldUpdate: 'Vous n\'avez pas accès à modifier ce champ',
        unauthorizedLeadUpdate: 'Vous n\'avez pas accès à modifier ce lead',
        vehicleAppraisalCompleted: 'Ce véhicule a été évalué',
        vehicleExported: 'Ce véhicule a été exporté',
        isAppraisedByTrs: 'Ce véhicule a été évalué par AutoHebdo',
        vehicleMustBeLeased: 'Le véhicle doit être en location',
        vehicleStock: 'Ce véhicule est lié à une unité d\'inventaire',

        leadStatus: {
            duplicate: 'Ce champ n\'est pas modifiable sur un lead doublon',
            invalid: 'Ce champ n\'est pas modifiable sur un lead invalide',
            lost: 'Ce champ n\'est pas modifiable sur un lead perdu',
        },

        userAssignation: {
            cannotModifyAlreadyAssignedUser: 'Vous ne pouvez pas modifier un utilisateur déjà assigné',
            reassignationInProgress: 'Une réassignation d\'utilisateur est en cours',
            selfAssignementForbidden: 'Vous ne pouvez pas vous assigner manuellement',
        },
    },
    quote: {
        calculatePricing: 'Calculer un prix',
        calculatedPrice: 'Prix calculé',
        priceResponse: 'La réponse au prix calculé s\'affichera ici...',
        success: 'Soumission sauvegardée avec succès',
        getBillOfSale: 'Obtenir le contrat de vente',
        billOfSale: 'Contrat de vente',
        billOfSaleProductId: 'Id du produit pour le contrat de vente',
        billOfSaleResponse: 'La réponse du contrat de vente s\'affichera ici...',
    },
};
