import Lead from '@/entities/Lead.js';
import Account from '@/entities/Account.js';
import Group from '@/entities/Group.js';
import User from '@/entities/User.js';

export default {
    account: new Account(),
    accountId: null,
    divisionId: null,
    group: new Group(),
    groupId: null,
    contextLeadId: null,
    contextLead: new Lead(),
    teamId: null,
    user: new User(),
    userId: null,
};
