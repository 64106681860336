import LeadVehicle from '@/entities/LeadVehicle.ts';
import DrsQuoteInformation from '@/entities/DrsQuoteInformation.ts';
import LeadVehicleType from './LeadVehicleType.js';

export default class WantedVehicle extends LeadVehicle {
    type: string = LeadVehicleType.WANTED;

    private _drs_additional_information: DrsQuoteInformation = new DrsQuoteInformation();

    constructor(initialValues: Partial<WantedVehicle> = {}) {
        super();

        this.init(initialValues);
    }

    initDrsAdditionalInformation(drsAdditionalInformation: Partial<DrsQuoteInformation> = {}) {
        this._drs_additional_information = new DrsQuoteInformation(drsAdditionalInformation || {});
    }

    set drs_additional_information(drsAdditionalInformation: Partial<DrsQuoteInformation>) {
        this.initDrsAdditionalInformation(drsAdditionalInformation);
    }

    get drs_additional_information(): DrsQuoteInformation {
        return this._drs_additional_information;
    }
}
