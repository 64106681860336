export default {
    pastExecutionDate: 'The date of execution is in the past',
    disableJob: 'Disable automation',
    enableJob: 'Enable automation',

    selectedfields: 'Selected fields',
    if: 'If',
    do: 'Do',
    after: 'After',
    at: 'At',
    action: 'Actions & Delay',
    visible: 'Visible',
    criteria: 'Criteria',
    and: 'AND',
    or: 'OR',
    isEmpty: 'is empty',
    automationMustBeActiveInAccount: 'Automation must be activated in the account',
    activeDisabledByNoActiveAction: 'At least one action must be activated',
    activeDisabledByInvalidCriterion:
        'This task cannot be activated because it contains an invalid criterion following a modification of the account. Please fix this criterion first.',
    affectedLeadTooltip: 'Number of lead affected by the automation',
    invalidCriterionTooltip: 'The following options were removed as they were no longer available due to a change in the account.<br><br><b>{options}</b><br><br>Please revalidate the criteria, reactivate the automation and save it again to restart it.',
    cancelRetroactiveValidation: 'Creating tasks in progress',
    disabledByRetroactionInProgress: "You can't update an automation with a running retroaction. You must first cancel it using the 'x' at the top right.",
    disabledBySystemAutomation: "You can't update an automation generated by system.",
    duplicateActionTooltip: 'Duplicate action',
    systemAutomationNotVisible: 'Automations generated by the system are only visible to admin users',
    table: {
        headings: {
            account: 'Account',
            title: 'Title',
            model: 'Type',
            event: 'Event',
            createdAt: 'Created at',
            createdBy: 'Created by',
            updatedAt: 'Updated at',
            updatedBy: 'Updated by',
            value: 'Value',
            active: 'Active',
            fields: 'Fields',
            affectedClients: 'Affected Clients',
        },
        row: {
            affectedClients: '{0} Affected client | {0} Affected clients',
            noName: 'No name',
        },
    },
    model: {
        lead: 'Lead',
        exchangeVehicles: 'Current Vehicle',
        wantedVehicles: 'Wanted Vehicle',
        source: 'Source',
        nextVirtualEvent: 'Next virtual event',
        taskEvent: 'Task / Event',
        wantedVehiclesCustomFields: 'Custom Fields - Wanted Vehicle',
        exchangeVehiclesCustomFields: 'Custom Fields - Current Vehicle',
        customFields: 'Custom Fields',
    },
    event: {
        create: 'Is Created',
        update: 'Is Update',
    },
    leadRelatedUser: {
        user: 'Associate',
        bdcUser: 'BDC Agent',
        serviceAdvisorUser: 'Service advisor',
        serviceAgentUser: 'Service agent',
        commercial: 'F&I',
        custom: 'Custom',
        specificUser: 'Specific user',
        you: 'Only you',
    },
    config: {
        heading: 'Configuration',
        title: 'Title',
        when: 'When',
        triggerFieldTitle: 'Trigger Field',
        triggerFieldTooltipMessage: 'Once selected, the automation will get triggered only if the field has been updated, otherwise any lead update will trigger the automation',
    },
    name: {
        placeholder: 'Please enter a title',
    },
    trigger: {
        lead: {
            create: 'Lead is Created',
            update: 'Lead is Updated',
            createOrUpdate: 'Lead is Created or Updated',
        },
        exchangeVehicles: {
            create: 'Exchange vehicle is Created',
            update: 'Exchange vehicle is Updated',
        },
        wantedVehicles: {
            create: 'Wanted vehicle is Created',
            update: 'Wanted vehicle is Update',
        },
        taskEvent: {
            create: 'Task/Event is Created',
            update: 'Task/Event is Updated',
        },
    },
    fields: {
        all: 'All',
        fieldNotFound: 'Field unavailable',
        lead: {
            group_label: 'Lead',
            walk_around: 'Walk around',
            approved: 'Approved',
            progress_state: 'Approval',
            waiting_sale: 'Waiting @:lexicon.sale.singular',
            discounted_date: 'Funded',
            source_id: 'Source',
            form_id: 'Form',
            dealer_tour: 'Dealer Tour',
            sale_by_phone: '@.capitalize:lexicon.sale.singular by phone',
            appointment_date: 'Appointment date',
            status_updated_at: 'Status',
            presented_date: 'Visit date',
            sale_date: '@.capitalize:lexicon.sale.singular date',
            road_test_date: 'Test drive',
            take_over_date: 'Turn over date',
            be_back_date: 'B-Back date',
            available_date: 'Veh. here on',
            paperwork_date: 'Paperwork completed on',
            end_contract_date: 'End of contract',
            end_service_date: 'End of service',
            refinanced_date: 'Buy Out date',
            repair_date: 'Repair date',
            last_presented_date: 'Last visit',
            birth_date: 'Birthdate',
            call_date: 'Called date',
            delivery_date: '@.capitalize:lexicon.deliveryDate.singular',
            delivered_date: '@.capitalize:lexicon.delivered.singular date',
            csi_date: 'CSI date',
            appt_call_date: 'Phone appointment',
            appt_confirmation_date: 'Appointment confirmation',
            work_order_closure_date: 'W.O. Closure date',
        },
        wantedVehicles: {
            all: 'All',
            group_label: 'Lead',
            stock_state: 'Stock State',
            stock: 'Stock',
            niv: 'NIV',
            category: 'Category',
            make: 'Make',
            model: 'Model',
            tire: 'Tires @:lexicon.sold.plural',
            frequency: 'Frequency',
            term: 'Term',
            modality: 'Modality',
            end_contract_date: 'Contract end date',
            institution: 'Institution',
            sold_date: '@.capitalize:lexicon.sold.singular Date',
            sold_by: '@.capitalize:lexicon.sold.singular By',
            year: 'Year',
            version: 'Trim',
            price: 'Price',
        },
        exchangeVehicles: {
            all: 'All',
            group_label: 'Lead',
            stock_state: 'Stock State',
            stock: 'Stock',
            niv: 'NIV',
            category: 'Category',
            make: 'Make',
            model: 'Model',
            tire: 'Tires @:lexicon.sold.plural',
            frequency: 'Frequency',
            term: 'Term',
            modality: 'Modality',
            end_contract_date: 'Contract end date',
            institution: 'Institution',
            sold_date: '@.capitalize:lexicon.sold.singular Date',
            sold_by: '@.capitalize:lexicon.sold.singular By',
            year: 'Year',
            version: 'Trim',
            price: 'Price',
        },
    },
    create: 'Creation',
    edit: 'Edit',
    addAction: '+ Action',
    addCriterion: '+ Criterion',
    addCriterionGroup: '+ Criteria group',
    goToAutomation: 'Other automations',
    goToAutomationDescription: 'Your changes will be lost, are you sure you want to leave this page?',
    operators: {
        is: 'Is',
        isNot: 'Is not',
        contains: 'Contains',
        notContains: 'Does not contain',
        defined: 'Is defined',
        undefined: 'Is not defined',
        lessThan: 'Is smaller than',
        greaterThan: 'Is bigger than',
        lessThanNow: 'Is in the past',
        greaterThanNow: 'Is in the future',
        before: 'Is before the',
        after: 'Is after the',
        isChecked: 'Is checked',
        isNotChecked: 'Is not checked',
    },
    criteriaFields: {
        custom_fields: 'Custom field',
        customFields: {
            group_label: 'Custom field',
        },
        wantedVehiclesCustomFields: {
            group_label: 'Custom field - Wanted vehicle',
        },
        exchangeVehiclesCustomFields: {
            group_label: 'Custom field - Exchange vehicle',
        },
        customer: {
            group_label: 'Customer',

            casl_consent: {
                confirmed_at: 'Double opt-in date',
                limit_date: 'Consent limit date for communication',
            },
        },
        lead: {
            group_label: 'Lead',

            appointment_date: 'Appointment date',
            approved: 'Approved',
            appt_call_date: 'Phone appointment date',
            appt_confirmation_date: 'Appointment confirmation date',
            available_date: 'Vehicle here on',
            bdc_user_id: 'BDC Agent',
            be_back_date: 'B-Back date',
            birth_date: 'Birthdate',
            call_date: 'Call date',
            first_event_campaign_id: 'Event',
            code: 'Code',
            city: 'City',
            civility: 'Civility',
            commercial_id: 'F&I',
            communication_preference: 'Communication preference',
            created_at: 'Creation date',
            created_by: 'Person that created the lead',
            created_method: 'Created method',
            dealer_tour: 'Dealer tour',
            delivered_by_id: 'Delivery man',
            delivered_date: '@.capitalize:lexicon.delivered.singular date',
            delivery_date: '@.capitalize:lexicon.deliveryDate.singular',
            deliverable_date: '@.capitalize:lexicon.deliverable.singular date',
            deposit: 'Deposit',
            discounted_date: 'Funded date',
            division_id: 'Division',
            do_not_market: 'Do not market',
            end_contract_date: 'End of contract date',
            end_service_date: 'End of service date',
            first_update_time: 'First update time (seconds)',
            form_normalized: 'Form',
            fullname: 'Lead name',
            gas: 'Fueled',
            import_file_name_normalized: 'Import file name',
            inspected: 'Inspection',
            invoiced: 'Invoiced',
            last_presented_date_normalized: 'Last visit date',
            lead_bank_id: 'Institution',
            lead_type_id: 'Lead type',
            locale: 'Language',
            loyalty: 'Loyalty',
            next_presented_date: 'Next revival date',
            non_consumer_initiated: 'Non-Consumer Initiated',
            odometer_last_visit: 'Last visit KM',
            open_work_order_date: 'W.O. opening date',
            paperwork_date: 'Paperwork date',
            planned_pick_up_date: 'Planned pick up date',
            prepaid: 'Prepaid',
            prepared: 'Prepared',
            presented_date: 'Visit date',
            progress_state: 'Approbation process',
            promised_date: 'Promised time',
            province: 'Province',
            source_id: 'Source',
            rating: 'Evaluation',
            reached_client: 'Reached client',
            refinanced_date: 'Buy Out date',
            refinanced_type: 'Buy Out type',
            repair_date: 'Repair date',
            repair_order: 'Repair order #',
            response_time: 'Response time (from CRM)',
            result_normalized: 'Result',
            road_test_date: 'Test drive date',
            sale_by_phone: '@.capitalize:lexicon.sale.singular by phone',
            sale_date: '@.capitalize:lexicon.sale.singular date',
            separated_from_previous_sales_journey: 'Separated from a previous sales journey',
            service_advisor_id: 'Service advisor',
            service_agent_id: 'Service agent',
            sex: 'Gender',
            status: 'Status',
            status_updated_at: 'Status updated date',
            status_updated_by: 'Person that updated the status',
            take_over_date: 'Turn over date',
            take_over_director_id: 'Turn over manager',
            unsubscribe_all_date: 'DND.',
            unsubscribe_call_date: 'Calls unsubscribe',
            unsubscribe_email_date: 'Emails unsubscribe',
            unsubscribe_sms_date: 'SMS unsubscribe',
            updated_by: 'Person that updated the lead',
            user_id: 'Associate',
            walk_around: 'Walk around',
            work_order: 'W.O. #',
            work_order_closure_date: 'W.O. Closure date',
            work_order_partial_closure_date: 'W.O. Partial closure date',
            write_up: 'Write-Up',
            result: 'Result',
            merged_id: 'Lead merged',
            newest_merged_id: 'Reception of merged lead',

            '@validLeadPhone': 'Valid phone',
            '@!validLeadPhone': 'Invalid phone',
            '@validLeadEmail': 'Valid email address',
            '@validLeadEmailTooltip': 'At least one valid email address',
            '@!validLeadEmail': 'Invalid email address',
            '@!validLeadEmailTooltip': 'No valid email address',
            '@incomingCallCommunications': 'Incoming call longer than 30 seconds',
            '@!incomingCallCommunications': 'No incoming call longer than 30 seconds',
            '@incomingEmailCommunications': 'Incoming email',
            '@!incomingEmailCommunications': 'No incoming email',
            '@incomingSmsCommunications': 'Incoming SMS',
            '@!incomingSmsCommunications': 'No incoming SMS',
            '@outgoingCallCommunications': 'Outgoing call longer than 30 seconds',
            '@!outgoingCallCommunications': 'No outgoing call longer than 30 seconds',
            '@outgoingEmailCommunications': 'Outgoing email',
            '@!outgoingEmailCommunications': 'No outgoing email',
            '@outgoingSmsCommunications': 'Outgoing SMS',
            '@!outgoingSmsCommunications': 'No outgoing SMS',
        },
        account: {
            group_label: 'Dealer',

            '@isInHoursNew': 'Dealer new open',
            '@!isInHoursNew': 'Dealer new closed',
            '@isInHoursUsed': 'Dealer used open',
            '@!isInHoursUsed': 'Dealer used closed',
            '@isInHoursService': 'Dealer service open',
            '@!isInHoursService': 'Dealer service closed',
        },
        vehicle: {
            accessories: 'Accessories',
            accidented_damage_cost: 'Accident $',
            actual_value: 'Actual value',
            balance: 'Balance',
            bank_id: 'Institution',
            budget_max: 'Max. budget',
            budget_min: 'Min. budget',
            calculator_value: 'Estimated',
            category: 'Category',
            category_rv: 'Category RV',
            condition: 'Condition',
            created_at: 'Creation date',
            documentation: 'Documentation',
            end_contract_date: 'End contract date',
            end_warranty_date: 'End warranty date',
            frequency: 'Frequency',
            fuel: 'Fuel',
            initial_cash: 'Cashdown',
            intention: 'Intention',
            license_plate: 'License plate',
            link: 'Lien',
            make: 'Make',
            mileage: 'Odometer',
            mileage_excess: 'Est. odometer excess',
            modality: 'Modality',
            model: 'Model',
            offer: 'Offer #',
            order_number: 'Order #',
            payment: 'Payment',
            payment_with_tax: 'Payment with tax',
            preparation: 'Preparation',
            price: 'Price',
            profit: 'Profit',
            purchase_date: 'Purchased date',
            rate: 'Rate',
            recall: 'Recall',
            recorded_date: 'Recorded date',
            requested: 'Requested',
            residual: 'Residual',
            security_deposit: 'Security deposit',
            sold_date: 'Date of sale',
            sold: '@.capitalize:lexicon.sold.singular',
            sold_by: '@.capitalize:lexicon.sold.singular by',
            stock: 'Stock',
            stock_state: 'Stock state',
            suffix: 'Suffix',
            term: 'Term',
            tire_type: 'Tires',
            trade_type: 'Buying',
            transmission: 'Transmission',
            trim: 'Trim',
            value: 'Value',
            verified_by_id: 'Manager that verified the @:lexicon.sale.singular',
            vin: 'VIN',
            weight: 'Weight',
            year: 'Year',
            year_max: 'Max. year',
            year_min: 'Min. year',

            '@hasPositiveEquity': 'Has positive equity',
            '@!hasPositiveEquity': 'Has not positive equity',
        },
        wantedVehicles: {
            group_label: 'Primary Wanted Vehicle',
            group_abr: ' - W',
            stock_state: 'Stock state',
            recorded_date: 'Recorded date',
            stock: 'Stock',
            niv: 'NIV',
            category: 'Category',
            make: 'Make',
            model: 'Model',
            tire: 'Tires @:lexicon.sold.plural',
            frequency: 'Frequency',
            term: 'Term',
            modality: 'Modality',
            end_contract_date: 'Contract end date',
            institution: 'Institution',
            sold_date: '@.capitalize:lexicon.sold.singular Date',
            sold_by: '@.capitalize:lexicon.sold.singular By',
            year: 'Year',
            version: 'Trim',
            price: 'Price',
        },
        exchangeVehicles: {
            group_label: 'Exchange vehicle',
            group_abr: ' - C',
            stock_state: 'Stock State',
            stock: 'Stock',
            niv: 'NIV',
            category: 'Category',
            make: 'Make',
            model: 'Model',
            tire: 'Tires @:lexicon.sold.plural',
            frequency: 'Frequency',
            term: 'Term',
            modality: 'Modality',
            end_contract_date: 'Contract end date',
            institution: 'Institution',
            sold_date: '@.capitalize:lexicon.sold.singular Date',
            sold_by: '@.capitalize:lexicon.sold.singular By',
            year: 'Year',
            version: 'Trim',
            price: 'Price',
        },
        source: {
            group_label: 'Source',
            provider_id: 'Provider',
        },
        nextVirtualEvent: {
            group_label: 'Next virtual event',
            customer_virtual_event_link: 'Customer virtual event link',
            user_virtual_event_link: 'User virtual event link',
        },
        other: {
            group_label: 'Other',
            'lead@currentWeekday': 'Days of the week',
            'lead@triggerByAssigned': 'Triggered by an assigned user',
            'lead@!triggerByAssigned': 'Triggered by a unassigned user',
            'lead@nextAppointmentDate': 'Next appointment date',
        },
        taskEvent: {
            group_label: 'Task / Event',
            start_at: 'Start',
            end_at: 'End',
            status: 'Follow done / Showed / @.capitalize:lexicon.delivered.singular',
            task_event_type_id: 'Type',
            user_id: 'Owner',
            main_event: 'Main event',
            confirmed: 'Confirmed',
            canceled: 'Canceled',
            no_show: 'No Show',
            '@triggerByOwner': 'Triggered by owner',
            '@!triggerByOwner': 'Triggered by another user than the owner',
            '@triggerByGuest': 'Triggered by a guest',
            '@!triggerByGuest': 'Triggered by another user than a guest',
            '@triggerByDeliveryUser': 'Triggered by @.capitalize:lexicon.delivery.singular specialist',
            '@!triggerByDeliveryUser': 'Triggered by another user than @.capitalize:lexicon.delivery.singular specialist',
            '@isLastTaskEvent': 'Is the last of the same type',
            '@!isLastTaskEvent': 'Is not the last of the same type',
        },
    },
    actions: {
        sendEmail: {
            affectLeadResult: 'Affect lead result',
            affectLeadResultTooltip: 'Leads contacted through this automation will have a result of "Attempted" as opposed to "Pending"',
            assignTo: 'Associate to assign',
            assignToTooltip: 'This associate will be assigned if the lead does not already have an associate',
            attachments: 'Attachments',
            changeLocaleOrder: 'Change language priority',
            customEmail: 'custom',
            category: 'Category',
            sender: 'Sender',
            fallback: 'Fallback email',
            fallbackSender: 'Fallback sender',
            disabledFallBackEmailTooltip: 'You must select a fallback sender using the dropdown below',
            localePreference: 'Language preference',
            localePreferenceTooltip: 'Language to use for the email',
            specifiedEmail: 'Email custom',
            template: 'Template',
            title: 'Send an email',
            useLeadLocale: 'Use the lead language',
            useLeadLocaleTooltip: 'Unless specified during the integration process, the account language will be used',
        },
        sendSms: {
            affectLeadResult: 'Affect lead result',
            affectLeadResultTooltip: 'Leads contacted through this automation will have a result of "Attempted" as opposed to "Pending"',
            assignTo: 'Associate to assign',
            assignToTooltip: 'Assign this user to the lead if the lead does not have an associate.',
            category: 'Category',
            fallback: 'Fallback user',
            localePreference: 'Language preference',
            localePreferenceTooltip: 'Language to use for the SMS',
            template: 'Template',
            title: 'Send an SMS',
            useLeadLocale: 'Use the lead language',
            useLeadLocaleTooltip: 'Unless specified during the integration process, the account language will be used',
            userToUse: 'From',
        },
        sendWebhook: {
            apiKey: 'API key',
            apiKeySaveNeeded: 'The API key will be generated after the automation is saved.',
            fallbackEmail: 'Fallback email',
            provider: 'Provider',
            title: 'Send update to provider',
            url: 'URL Destination address',
        },
        createTaskEvent: {
            title: 'Add a task',
            taskTitle: 'Title',
            taskTitleTooltip: "If no title is specified, the name of the automation + name of the lead will be used —e.g., 'Auto CSI - John Doe'",
            taskTitlePlaceholder: 'Title: {Task type} - {Lead Name}',
            type: 'Task',
            owner: 'Owner',
            relativeFields: 'Assigned',
            roles: 'Roles',
            ownerOptions: 'Users',
            backupOwner: 'Fallback owner',
            backupOwnerTooltip: "If the specified owner field is empty the fallback will be used but if the 2nd is also empty the task won't be created",
            guests: 'Guests',
            deliverySpecialist: '@.capitalize:lexicon.delivery.singular specialist',
            backupDeliverySpecialist: 'Fallback @.capitalize:lexicon.delivery.singular specialist',
            priority: 'Priority',
            description: 'Description',
            duration: 'Duration',
            time: 'Time',
            reminderPreference: 'Reminders',
            userReminderPreference: "Owner's preference",
            userReminderPreferenceTooltip: "The owner's default reminders will be used",
            allowTaskAssignedAlert: 'Trigger "Task assigned" alert',
            allowTaskAssignedAlertTooltip: 'Tasks generated by the automation will trigger the alert "Task assigned" based on the owner\'s preferences.',
        },
        sendNotification: {
            title: 'Send a notification',
            division: 'Division',
            to: 'Send to',
            users: 'Users',
            frequency: 'Frequency',
            daily: 'Daily',
            weekly: 'Weekly',
            notification: 'Notification',
            template: 'Template',
            previewMessage: 'Preview email',
            previewNotification: 'Preview notification',
            subject: 'Subject',
            email: 'Email',
            emailTooltip: 'Send the notification by email',
            screen: 'Screen',
            screenTooltip: 'Send the notification by screen',
            push: 'Mobile',
            pushTooltip: 'Send the notification by mobile',
            templateTooltip: 'The notification will be sent in the user\'s language',
            templateTranslationsMissingExcerpt: "Every template's translations must define a screen and mobile body",
            guestGroups: 'User groups',
            once: 'Only once',
            delay: 'Delay between notifications',
            noSelfNotification: 'No self-notification',
            noSelfNotificationTooltip: 'By checking this box, you will not be self-notified if you trigger this automation',
        },
        sendSystemNotification: {
            title: 'Send a delayed notification',
            to: 'Send to',
            users: 'Users',
            frequency: 'Frequency',
            daily: 'Daily',
            weekly: 'Weekly',
            notification: 'Notification',
            sms: 'SMS',
            email: 'Email',
            screen: 'Screen',
            push: 'Mobile',
        },
        sendConsentDoubleOptIn: {
            title: 'Send a double opt-in email for the Canada’s anti-spam legislation',

            fallbackSender: 'Fallback sender',
            sender: 'Sender',
            user: 'User',
        },
        autoCloseLead: {
            title: 'Close the lead',
            lostReasonLabel: 'Lead lost reason',
            lostReasonPlaceholder: 'Enter a custom reason',
            defaultLostReason: 'Closed by automation',
        },
    },
    alerts: {
        index: {
            error: 'Failed fetching the automations',
        },
        store: {
            success: 'Automation created successfully',
            error: 'Failed creating the automation',
        },
        show: {
            error: 'Failed fetching the automation',
        },
        update: {
            success: 'Automation updated successfully',
            error: 'Failed updating the automation',
        },
        destroy: {
            success: 'Automation deleted successfully',
            error: 'Failed deleting the automation',
        },
    },
    rangeUnit: {
        minutes: 'Minutes',
        hours: 'Hours',
        days: 'Days',
        weekdays: 'Weekdays',
        weeks: 'Weeks',
        months: 'Months',
        years: 'Years',
    },
    deleteModal: 'Are you sure you want to delete this automation?',
    deactivateModal: 'Disable all planned actions not executed yet?',
    disableAutomation: 'Disable this automation?',
    deactivateAutomationModal: 'All scheduled actions will be deleted.',
    retroactionModal: 'Apply this automation to existing Clients?',
    revalidateAutomationModal: 'Revalidate affected lead?',
    validation: 'Please validate that all the fields are filled correctly',
    active: 'Active',
    execution: {
        useNewModalTooltip: 'Use the new modal for the execution configuration?',

        criteria: {
            title: 'Execution criteria',
            tooltip: 'Define criteria limiting the execution of the action' +
                '<br/><br/><b>†</b> Lead creation date if no element' +
                '<br/><b>††</b> Completed variant or lead creation if no element',

            none: 'No criteria',
            invalidatedCriteriaField: 'Some execution criteria were no longer valid after removing an automation criterion. They have been modified to use the trigger  "When all criteria are met"',

            disabledForRecurringExecutionTooltip: 'Execution criteria cannot be applied on action with recurrence',
            clearExecutionCriteriaWhenAddingRecurrence: 'Adding a recurrence on the action will disable the execution criteria config. Are you sure you want to continue with the changes?',

            conditionalExecution: 'Will be executed {executionContext} <b>if</b> this date satisfy the following execution criteria',
            executionBasedOnExecutionCriteria: 'Will be executed on the earliest date that meets all of the following execution criteria (or immediately if the resulting date is in the past):',

            quantifier: {
                label: 'Quantifier',

                options: {
                    at_least: 'At least',
                    less_than: 'Less than',
                },
            },
        },

        delayType: {
            label: 'Type',

            options: {
                none: 'At the moment of',
                exact_time: 'At this date',
                same_day: 'Same day than',
                exactly: 'Exactly',
            },
        },

        tooltips: {
            direction: 'Indicate if the recurrence is being executed in the future or in the past',
            directionButtonFuture: 'Change the direction of recurrence for past date',
            directionButtonPast: 'Change the direction of recurrence for future date',
            iconInvalidField: 'Your delay was no longer valid after removing a criterion, it was modified to use the option "When all criteria are met"',
            caslActionRequiresLimitDateField: 'This action requires this field as trigger',
        },
        direction: {
            future: 'future',
            past: 'past',
        },
        recurrence: {
            now: 'Executed directly',
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            yearly: 'Yearly',
        },
        typeOfTime: {
            field: 'At the same time',
            custom: 'Custom time',
        },
        label: {
            unset: 'Select the type of execution...',
            now: 'Executed',
            recurring: 'repeated {occurence} time in the {direction} | repeated {occurence} times in the {direction}',
            delay: '{amount} {unit} {direction}',
            noDelay: 'No delay',
            field: 'at {field}',
            fieldShort: '{field}',
            time: 'at {time}',
            exactTime: 'at {time}',
            theField: 'the {field}',
            at: 'at',
        },
        modal: {
            title: 'Execution parameters',
            applyButton: 'Apply',
            noPreview: 'Provide at least one occurence...',
            label: {
                recurrence: 'Recurrence',
                recurrence_direction: 'Direction of recurrence',
                occurence: 'Repetition',
                delay: 'Delay',
                delay_direction: 'Moment of the delay',
                field: 'Based on the moment of...',
                type_of_time: 'Task time',
                time_of_day: 'At this time',
                schedule_aware: 'Schedule',
                exact_time: 'At this exact time',
                unit: 'Unit',
                during: 'during',
            },
        },
        fields: {
            trigger: 'When all criteria are met',

            customer: {
                casl_consent: {
                    confirmed_at: 'Double opt-in date',
                    limit_date: 'Consent limit date for communication',
                },
            },
            lead: {
                appointment_date: 'Appointment date',
                appt_call_date: 'Phone appointment date',
                appt_confirmation_date: 'Appointment confirmation date',
                available_date: 'Vehicle arrival date',
                birth_date: 'Birthdate',
                call_date: 'Called date',
                created_at: 'Creation date',
                deliverable_date: '@.capitalize:lexicon.deliverable.singular date',
                delivered_date: '@.capitalize:lexicon.delivered.singular date',
                delivery_date: '@.capitalize:lexicon.deliveryDate.singular',
                discounted_date: 'Discount date',
                end_contract_date: 'End of contract date',
                end_service_date: 'End of service date',
                paperwork_date: 'Paperwork date',
                presented_date: 'Visit date',
                promised_date: 'Promised time',
                refinanced_date: 'Buy Out date',
                repair_date: 'Repair date',
                road_test_date: 'Test drive date',
                sale_date: '@.capitalize:lexicon.sale.singular date',
                status_updated_at: 'Update of his status date',
                take_over_date: 'Turn over date',
                be_back_date: 'B-Back date',
                last_presented_date_normalized: 'Last visit date',
                work_order_closure_date: 'W.O. Closure date',

                latest_task_date_criteria: 'Latest task (†)',
                latest_completed_task_date_criteria: 'Latest completed task (†)',
                latest_uncompleted_task_date_criteria: 'Latest uncompleted task (††)',
                latest_event_date_criteria: 'Latest event (†)',
                latest_completed_event_date_criteria: 'Latest completed event (†)',
                latest_uncompleted_event_date_criteria: 'Latest uncompleted event (††)',
                latest_task_event_date_criteria: 'Latest task/event (†)',
                latest_completed_task_event_date_criteria: 'Latest completed task/event (†)',
                latest_uncompleted_task_event_date_criteria: 'Latest uncompleted task/event (††)',
                latest_communication_date_criteria: 'Latest communication (†)',
            },
            wantedVehicles: {
                created_at: 'Creation date',
                end_contract_date: 'End of contract date',
                end_warranty_date: 'End of warranty date',
                purchase_date: 'Purchased date',
                recorded_date: 'Recorded date',
            },
            exchangeVehicles: {
                created_at: 'Creation date',
                end_contract_date: 'End of contract date',
                end_warranty_date: 'End of warranty date',
                purchase_date: 'Purchased date',
                recorded_date: 'Recorded date',
                sold_date: 'Date of sale',
            },
            taskEvent: {
                start_at: 'Start date',
                end_at: 'End date',
            },
        },
    },
    errors: {
        fetchCommunicationAutomation: 'An error occurred while retrieving the automation',
    },
};
