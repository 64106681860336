import Vue from 'vue';
import { find, forEach, get, merge, set, uniqueId } from 'lodash-es';
import { gql } from 'apollo-boost';

import defaultLexiconFr from '@/locales/fr/lexicon/default.js';
import defaultLexiconEn from '@/locales/en/lexicon/default.js';
import polestarLexiconFr from '@/locales/fr/lexicon/polestar.js';
import polestarLexiconEn from '@/locales/en/lexicon/polestar.js';
import hyundaiLexiconFr from '@/locales/fr/lexicon/hyundai.js';
import hyundaiLexiconEn from '@/locales/en/lexicon/hyundai.js';

// Plugins
import wait from '@/plugins/vue-wait.js';
import { apolloClient } from '@/plugins/vue-apollo.js';
import i18n from '@/plugins/vue-i18n.js';
import axios from '@/plugins/axios.js';

import Checksum from '@/plugins/checksum.js';
import { useContextStore } from '@/store/modules/context/store.js';
import caching from '@/plugins/caching.js';
import { contextStore } from '@/pinia/storeHelper.js';
import {
    CORE_ACCOUNT_FIELDS,
    CORE_ACCOUNT_PRODUCT_FIELDS,
    CORE_ACCOUNT_SUPPLIER_FIELDS,
    CORE_CAMPAIGN_FIELDS,
    CORE_CUSTOM_FIELD_FIELDS,
    CORE_EMAIL_CONFIG_FIELDS,
    CORE_GUEST_GROUP_FIELDS,
    CORE_PHONE_PROVIDER_FIELDS,
    CORE_SOURCE_FIELDS,
    CORE_TEAM_FIELDS,
    CORE_USER_FIELDS,
    CORE_USER_PHONE_FIELDS,
} from '@/graphql/fragments.js';
import { useGlobalStore } from '../../store.js';

// Utils
import { showLeadDeleted, showLeadNotFound } from '../../../utils/toastr.js';
import { isValidNumber } from '../../../utils/numbers.js';

// Entities
import Account from '../../../entities/Account.js';
import Group from '../../../entities/Group.js';
import Lead from '../../../entities/Lead.js';
import User from '../../../entities/User.js';

const updateLocalStorage = newContext => {
    if (Vue.ls) {
        const context = Vue.ls.get('context', {});

        Vue.ls.set('context', { ...context, ...newContext });
    }
};

const fetchContextAccount = async accountId => {
    if (!accountId) {
        return null;
    }

    return fetchContextAccountGraphQl(accountId);
};

const fetchContextAccountCache = {};

const fetchContextAccountGraphQl = async accountId => {
    const contextAccountCache = await Vue.caching.getCache(`${caching.CONTEXT_ACCOUNT_CACHE_KEY}_${accountId}`) ?? {};
    let delayedUpdate = [];

    const fetchFromCache = (queryName, gqlQuery, cacheUpdateDelay = 3000) => {
        return new Promise((resolve) => {
            const dataFromQuery = (delay = 0) => {
                if (get(fetchContextAccountCache, `${queryName}.timeout`, null) != null) {
                    clearTimeout(fetchContextAccountCache[queryName].timeout);
                }

                const executeFetch = async () => {
                    const timeoutId = uniqueId();
                    set(fetchContextAccountCache, `${queryName}.id`, timeoutId);
                    const query = apolloClient.query({
                        query: gqlQuery,
                        variables: {
                            id: parseInt(accountId, 10),
                        },
                    });
                    const data = (await query).data;

                    if (Checksum(data) !== Checksum(contextAccountCache[queryName]) && get(fetchContextAccountCache, `${queryName}.id`) === timeoutId) {
                        contextAccountCache[queryName] = data;
                        Vue.caching.setCache(`${caching.CONTEXT_ACCOUNT_CACHE_KEY}_${accountId}`, contextAccountCache);
                    }
                    fetchContextAccountCache[queryName] = undefined;
                    return contextAccountCache[queryName];
                };

                return new Promise((resolve2) => {
                    if (delay) {
                        if (Vue.feature.isEnabled('delayed_cache_loading')) {
                            delay *= 3;
                        }

                        delayedUpdate.push((() => {
                            return new Promise((delayedResolve) => {
                                set(fetchContextAccountCache, `${queryName}.timeout`, setTimeout(
                                    async () => {
                                        const data = await executeFetch();
                                        delayedResolve({ name: queryName, data });
                                    },
                                    delay,
                                ));
                            });
                        })());
                    } else {
                        resolve2(executeFetch());
                    }
                });
            };

            if (!contextAccountCache[queryName]) {
                resolve(dataFromQuery());
                return;
            }

            if (get(contextAccountCache, `${queryName}.account.id`, null) != accountId) {
                resolve(dataFromQuery());
                return;
            }

            dataFromQuery(cacheUpdateDelay);
            resolve(contextAccountCache[queryName]);
        });
    };

    const contextAccountQuery = fetchFromCache(
        'account_context',
        gql`
            ${CORE_ACCOUNT_FIELDS}
            ${CORE_SOURCE_FIELDS}
            ${CORE_ACCOUNT_SUPPLIER_FIELDS}
            ${CORE_TEAM_FIELDS}
            ${CORE_CAMPAIGN_FIELDS}
            ${CORE_PHONE_PROVIDER_FIELDS}
            query account_context($id: Int) {
                account(id: $id) {
                    ...CoreAccountFields
                    sources {
                        ...CoreSourceFields
                    }
                    suppliers {
                        ...CoreAccountSupplierFields
                    }
                    phone_providers {
                        ...CorePhoneProviderFields
                    }
                    children {
                        ...CoreAccountFields
                        sources {
                            ...CoreSourceFields
                        }
                        suppliers {
                            ...CoreAccountSupplierFields
                        }
                        phone_providers {
                            ...CorePhoneProviderFields
                        }
                    }

                    parents {
                        ...CoreAccountFields
                        sources {
                            ...CoreSourceFields
                        }
                        suppliers {
                            ...CoreAccountSupplierFields
                        }
                        phone_providers {
                            ...CorePhoneProviderFields
                        }
                    }
                    lead_forms {
                        id
                        name
                        display_name_fr
                        display_name_en
                        account_id
                    }
                    teams {
                        ...CoreTeamFields
                    }
                    flows {
                        id
                        account_id
                        communication_method_id
                        communication_type_id
                        division_id
                        lead_form_id
                        notify_precedent_priorities
                        created_at
                        updated_at
                        round_robin
                        activeUsers {
                            id
                            flow_id
                            flow_order
                        }
                    }
                    campaigns {
                        ...CoreCampaignFields
                    }
                }
            }
        `,
    );

    const productsQuery = fetchFromCache(
        'accountProducts_context',
        gql`
            ${CORE_ACCOUNT_PRODUCT_FIELDS}
                query accountProducts_context($id: Int) {
                    account(id: $id) {
                        id
                        products {
                            ...CoreAccountProductFields
                        }
                        children {
                            id
                            products {
                                ...CoreAccountProductFields
                            }
                        }
                        parents {
                            id
                            products {
                                ...CoreAccountProductFields
                            }
                        }
                    }
                }
        `,
    );

    const usersQuery = fetchFromCache(
        'accountUsers_context',
        gql`
            ${CORE_USER_FIELDS}
            ${CORE_USER_PHONE_FIELDS}
            ${CORE_EMAIL_CONFIG_FIELDS}
            query accountUsers_context($id: Int) {
                account(id: $id) {
                    id
                    users {
                        ...CoreUserFields
                        children {
                            ...CoreUserFields
                        }
                        parent {
                            ...CoreUserFields
                        }
                        divisions {
                            id
                        }
                        email_config {
                            ...CoreEmailConfigFields
                        }
                        phones {
                            ...CoreUserPhoneFields
                        }
                        post {
                            id
                            role_id
                            locale_specific
                            sex
                            name
                        }
                    }
                    children {
                        id
                        users {
                            ...CoreUserFields
                        }
                    }
                    parents {
                        id
                        users {
                            ...CoreUserFields
                        }
                    }
                }
            }
        `,
    );

    const customFieldsQuery = fetchFromCache(
        'accountCustomField_context',
        gql`
            ${CORE_CUSTOM_FIELD_FIELDS}
            query accountCustomField_context($id: Int) {
                account(id: $id) {
                    id
                    custom_fields {
                        ...CoreCustomFieldFields
                    }
                    parents {
                        id
                        custom_fields {
                            ...CoreCustomFieldFields
                        }
                    }
                }
            }
        `,
    );

    const guestGroupQuery = fetchFromCache(
        'accountGuestGroup_context',
        gql`
            ${CORE_GUEST_GROUP_FIELDS}
            query accountGuestGroup_context($id: Int) {
                account(id: $id) {
                    id
                    guest_groups {
                        ...CoreGuestGroupFields
                    }
                    children {
                        id
                        guest_groups {
                            ...CoreGuestGroupFields
                        }
                    }
                }
            }
        `,
    );

    let accountData = (await contextAccountQuery).account;

    const foreigns = {
        products: (await productsQuery),
        users: (await usersQuery),
        custom_fields: (await customFieldsQuery),
        guest_groups: (await guestGroupQuery),
    };

    const rebuildAccount = (accountData, foreignData) => {
        forEach(foreignData, (foreignDatum, foreignName) => {
            if (Array.isArray(foreignDatum)) {
                if (['children', 'parent'].includes(foreignName)) {
                    forEach(foreignDatum, (foreignAccount) => {
                        let foreignContext = find(get(accountData, foreignName, []), ({ id }) => {
                            return id === foreignAccount.id;
                        });

                        if (!foreignContext) {
                            foreignContext = { id: foreignAccount.id };
                            get(accountData, foreignName, []).push(foreignContext);
                        }

                        merge(foreignContext, foreignAccount);
                    });
                } else {
                    accountData[foreignName] = merge([], accountData[foreignName], foreignDatum);
                }
            }
        });
        return accountData;
    };

    let foreignData = {};
    forEach(foreigns, (foreign) => {
        foreignData = merge(foreignData, foreign.account);
    });

    accountData = rebuildAccount(accountData, foreignData);

    (async () => {
        let updatedAccount = {};
        let updatedForeigns = {};
        const updates = await Promise.all(delayedUpdate);
        delayedUpdate = [];
        forEach(updates, (update) => {
            if (update.name === 'account_context') {
                updatedAccount = update.data.account;
            } else {
                updatedForeigns = merge(updatedForeigns, update.data.account);
            }
        });

        updatedAccount = rebuildAccount(updatedAccount, updatedForeigns);

        if (useContextStore().accountId == updatedAccount.id) {
            contextStore().setContextAccount(updatedAccount);
        }
    })();

    return accountData;
};

const fetchContextUser = async userId => {
    if (!userId) {
        return null;
    }

    const contextUserQuery = apolloClient.query({
        query: gql`query contextUser($userId: Int!) {
            user(id: $userId) {
                account_id
                first_name
                id
                last_name
                parent_user_id
            }
        }`,
        variables: {
            userId: parseInt(userId, 10),
        },
    });

    return (await contextUserQuery).data.user;
};

const fetchContextGroup = async groupId => {
    if (!groupId) {
        return null;
    }

    return Vue.api.groups.show(groupId, {
        include: ['accounts.sources:id,name'],
    });
};

const fetchContextLead = async (leadId) => {
    if (!leadId) {
        return null;
    }

    try {
        return await Vue.api.leads.show(leadId);
    } catch (error) {
        if (!error.response) {
            throw error;
        }

        // 5xx error
        if (error.response.status.toString().startsWith(5)) {
            dispatch('appendNewError', {
                code: '0047',
                display: false,
                error,
                payload: { id },
            });
        }

        if (error.response.status == 404) {
            const mergedId = get(error.response, 'data.errors.mergedId');
            const deletedBy = get(error.response, 'data.errors.deletedBy');
            const currentRoute = Vue.router.currentRoute;

            if (deletedBy) {
                showLeadDeleted(deletedBy);
                Vue.router.replace('/');

                return null;
            }

            if (!mergedId) {
                showLeadNotFound();
                Vue.router.replace('/');

                return null;
            }

            if (currentRoute.name == 'leads.update' && currentRoute.params.id == id) {
                Vue.router.replace({
                    name: 'leads.update',
                    params: {
                        id: error.response.data.errors.mergedId,
                    },
                });
            }
        }

        throw error;
    }
};

const fetchWebOrderBadgeCount = async accountId => {
    if (!accountId) {
        return null;
    }

    const response = await axios.get(`v1/accounts/${accountId}/web-order-badge-count`);

    return response.data.badgeCount;
};

export default {
    async reloadContextAccount() {
        const accountId = this.account.id;

        if (!accountId) {
            return;
        }

        const account = await fetchContextAccount(accountId);

        this.setContextAccount(account);

        useGlobalStore().fetchTemplateAttributes(accountId);
        useGlobalStore().fetchPdfTemplates(accountId);
        useGlobalStore().fetchAccountVehicleModels(accountId);
        useGlobalStore().fetchConfigs(accountId);
    },
    async setContextUserAction(userId) {
        const tmpAuthUser = useGlobalStore().authUser;

        if (!userId) {
            if (tmpAuthUser.hasSimpleAccess()) {
                await this.setContextUserAction(tmpAuthUser.id);
            } else {
                this.setContextUser(null);
                this.setContextUserId(null);
            }

            return;
        }

        if (userId == this.userId) {
            return;
        }

        wait.start('fetching.contextUser');

        this.setContextUserId(userId);

        try {
            const user = await fetchContextUser(userId);

            this.setContextUser(user);

            await this.setContextAccountAction(user.account_id);
            await this.setContextGroupAction();

            wait.end('fetching.contextUser');
        } catch (error) {
            wait.end('fetching.contextUser');

            if (tmpAuthUser.hasSimpleAccess()) {
                await this.setContextUserAction(tmpAuthUser.id);
            }
        }
    },
    async setContextGroupAction(groupId) {
        if (!groupId) {
            this.setContextGroup(null);
            this.setContextGroupId(null);
            return;
        }

        if (groupId == this.groupId) {
            return;
        }

        wait.start('fetching.contextGroup');

        this.setContextGroupId(groupId);

        try {
            const group = await fetchContextGroup(groupId);

            this.setContextGroup(group);

            if (!this.accountId) {
                await this.setContextAccountAction(useGlobalStore().authUser.account_id);
            }
            await this.setContextUserAction();

            wait.end('fetching.contextGroup');
        } catch (error) {
            wait.end('fetching.contextGroup');

            this.setContextGroup(null);
            this.setContextGroupId(null);
        }
    },
    async setContextAccountAction(accountId) {
        const tmpAuthUser = useGlobalStore().parentAuthUser;
        if (!isValidNumber(accountId)) {
            const authUserDefaultAccountId = tmpAuthUser.default_account_id || tmpAuthUser.account_id;
            const forceAccountId = tmpAuthUser.isAdmin() ? Account.DEMO : authUserDefaultAccountId;
            await this.setContextAccountAction(forceAccountId);
            return;
        }

        if (accountId == this.accountId) {
            return;
        }

        if (!tmpAuthUser.hasAccessToSeeAccount(accountId)) {
            await this.setContextAccountAction(tmpAuthUser.account_id);

            return;
        }

        wait.start('fetching.contextAccount');

        this.setContextAccountId(accountId);

        try {
            const account = await fetchContextAccount(accountId);

            this.setContextAccount(account);
            this.setContextTeamId(null);
            this.setContextLexicon();

            if (tmpAuthUser.hasAccessTo('dashboards.webOrder')) {
                fetchWebOrderBadgeCount(accountId).then(badgeCount => {
                    useGlobalStore().updateWebOrderBadgeCount(badgeCount);
                });
            }

            useGlobalStore().fetchTemplateAttributes(accountId);
            useGlobalStore().fetchPdfTemplates(accountId);
            useGlobalStore().fetchConfigs(accountId);
            useGlobalStore().fetchAccountVehicleModels(accountId);

            wait.end('fetching.contextAccount');
        } catch (error) {
            wait.end('fetching.contextAccount');

            await this.setContextAccountAction(tmpAuthUser.account_id);
        }
    },
    setContextLexicon() {
        const replaceLexicon = (locale, messages) => {
            const localeMessages = i18n.getLocaleMessage(locale);
            localeMessages.lexicon = messages;

            i18n.setLocaleMessage(locale, localeMessages);
        };

        if (this.account.isPolestar()) {
            replaceLexicon('fr', polestarLexiconFr);
            replaceLexicon('en', polestarLexiconEn);
        } else if (this.account.hasHyundaiSupplier()) {
            replaceLexicon('fr', hyundaiLexiconFr);
            replaceLexicon('en', hyundaiLexiconEn);
        } else {
            replaceLexicon('fr', defaultLexiconFr);
            replaceLexicon('en', defaultLexiconEn);
        }
    },
    async setContextLeadIdAction(leadId) {
        if (!leadId) {
            this.setContextLead(null);
            return;
        }

        if (leadId == this.contextLeadId) {
            return;
        }

        wait.start('fetching.lead');

        try {
            const lead = new Lead(await fetchContextLead(leadId));

            if (Vue.router.currentRoute.name !== 'calendar') {
                await this.setContextAccountAction(lead.account_id);
            }

            this.setContextLead(lead);
        } catch (error) {
            this.setContextLead(null);

            if (error.response) {
                const mergedId = error.response.data.errors ? error.response.data.errors.mergedId : null;

                if (error.response.status == 404 && mergedId) {
                    Vue.router.replace({
                        name: 'leads.update',
                        params: {
                            id: mergedId,
                        },
                    });
                } else if ([403, 404].includes(error.response.status)) {
                    Vue.router.replace('/');
                } else {
                    useGlobalStore().appendNewError({
                        code: '0105',
                        display: false,
                        error,
                        payload: { leadId },
                    });

                    Vue.router.replace('/');
                }
            }

            if (!this.accountId) {
                await this.setContextAccountAction();
            }
        }

        wait.end('fetching.lead');
    },

    setContextDivisionId(divisionId) {
        this.divisionId = parseInt(divisionId, 10) || null;
        updateLocalStorage({ divisionId: this.divisionId });
    },

    setContextLeadId(leadId) {
        this.contextLeadId = parseInt(leadId, 10) || null;
        updateLocalStorage({ leadId: this.contextLeadId });
    },

    setContextLead(lead) {
        this.contextLead = lead || new Lead();
        this.setContextLeadId(lead?.id);
    },

    setContextTeamId(teamId) {
        const teamExists = this.account.teams.some(team => team.id == teamId);

        if (!teamExists) {
            teamId = null;
        }

        this.teamId = parseInt(teamId, 10) || null;
        updateLocalStorage({ teamId: this.teamId });
    },

    setContextAccountId(accountId) {
        this.accountId = parseInt(accountId, 10) || null;
        updateLocalStorage({ accountId: this.accountId });
    },

    setContextGroupId(groupId) {
        this.groupId = parseInt(groupId, 10) || null;
        updateLocalStorage({ groupId: this.groupId });
    },

    setContextUserId(userId) {
        this.userId = parseInt(userId, 10) || null;
        updateLocalStorage({ userId: this.userId });
    },

    setContextAccount(account) {
        this.account = new Account(account);
    },

    setContextGroup(group) {
        this.group = new Group(group);
    },

    setContextUser(user) {
        this.user = new User(user);
    },
};
