export default {
    pastExecutionDate: "La date d'exécution est dans le passé",
    disableJob: "Désactiver l'automatisation",
    enableJob: "Activer l'automatisation",

    division: 'Division',
    selectedfields: 'Champs sélectionné',
    if: 'Si',
    do: 'Faire',
    after: 'Après',
    at: 'à',
    action: 'Actions & Délais',
    visible: 'Visible',
    criteria: 'Critères',
    and: 'ET',
    or: 'OU',
    isEmpty: 'est vide',
    automationMustBeActiveInAccount: 'Automatisation doit être activé dans le compte',
    activeDisabledByNoActiveAction: 'Au moins une action doit être activée',
    activeDisabledByInvalidCriterion: "Cette tâche ne peut être activée car elle contient un critère invalide suite à une modification du compte. Veuillez d'abord revalider les critères.",
    affectedLeadTooltip: "Nombre de leads affectés par l'automatisation",
    invalidCriterionTooltip: "Les options suivantes ont été retirées car elle n'étaient plus disponible suite à une modification du compte.<br><br><b>{options}</b><br><br>Veuillez revalider les critères, réactiver l'automation et l'enregistrer de nouveau afin de la remettre en marche.",
    cancelRetroactiveValidation: 'Création de tache en cours',
    disabledByRetroactionInProgress: "Vous ne pouvez pas modifier une automatisation possédant une rétroaction en cours. Vous devez d'abord annuler la rétroaction en utilisant le 'x' en haut a droite.",
    disabledBySystemAutomation: 'Vous ne pouvez pas modifier une automatisation générée par le système.',
    duplicateActionTooltip: 'Dupliquer cette action',
    systemAutomationNotVisible: 'Les automatisations générées par le système sont uniquement visibles pour les administrateurs',
    table: {
        headings: {
            account: 'Compte',
            title: 'Titre',
            model: 'Type',
            event: 'Événement',
            createdAt: 'Date de création',
            createdBy: 'Créée par',
            updatedAt: 'Date de mise à jour',
            updatedBy: 'Mise à jour par',
            value: 'Valeur',
            active: 'Actif',
            fields: 'Champs',
            affectedClients: 'Clients affectés',
        },
        row: {
            affectedClients: '{0} Client affecté | {0} Clients affectés',
            noName: 'Sans nom',
        },
    },
    model: {
        lead: 'Lead',
        exchangeVehicles: 'Véhicule actuel',
        wantedVehicles: 'Véhicule recherché',
        source: 'Source',
        nextVirtualEvent: 'Prochain évènement virtuel',
        taskEvent: 'Tâche / rendez-vous',
        wantedVehiclesCustomFields: 'Champs personnalisés - Véhicule recherché',
        exchangeVehiclesCustomFields: 'Champs personnalisés - Véhicule actuel',
        customFields: 'Champs personnalisés',
    },
    event: {
        create: 'Est créé',
        update: 'Est mis à jour',
    },
    leadRelatedUser: {
        user: 'Conseiller',
        bdcUser: 'Agent BDC',
        serviceAdvisorUser: 'Aviseur',
        serviceAgentUser: 'Agent de service',
        commercial: 'Directeur commercial',
        custom: 'Sur mesure',
        specificUser: 'Utilisateur spécifique',
        you: 'Vous seulement',
    },
    config: {
        heading: 'Configuration',
        title: 'Titre',
        when: 'Quand / Quoi',
        triggerFieldTitle: 'Champs déclencheur',
        triggerFieldTooltipMessage: 'Lorsque sélectionnée, l\'automatisation sera déclenchée seulement si le champ a été modifié. Sinon, toute modification au lead déclenchera l\'automatisation',
    },
    name: {
        placeholder: 'Veuillez entrer un titre',
    },
    trigger: {
        lead: {
            create: 'Lead est créé',
            update: 'Lead est mis à jour',
            createOrUpdate: 'Lead est créé ou mis à jour',
        },
        exchangeVehicles: {
            create: 'Véhicule actuel est créé',
            update: 'Véhicule actuel est mis à jour',
        },
        wantedVehicles: {
            create: 'Véhicule recherché est créé',
            update: 'Véhicule recherché est mis à jour',
        },
        taskEvent: {
            create: 'Tâche / rendez-vous est créé',
            update: 'Tâche / rendez-vous est mis à jour',
        },
    },
    fields: {
        all: 'Tous',
        fieldNotFound: 'Champ indisponible',
        lead: {
            sale_by_phone: '@.capitalize:lexicon.sold.singular par tél',
            appointment_date: 'Date de rendez-vous',
            status_updated_at: 'Status',
            presented_date: 'Date de visite',
            sale_date: 'Date de @:lexicon.sale.singular',
            road_test_date: 'Essai routier',
            take_over_date: 'Date de Take over',
            be_back_date: 'Date du B-back',
            dealer_tour: 'Dealer Tour',
            walk_around: 'Walk around',
            approved: 'Approuvé',
            progress_state: 'Approbation',
            available_date: 'Véh. ici le',
            paperwork_date: 'Dossier complété le',
            end_contract_date: 'Date de fin de contrat',
            end_service_date: 'Fin de son service',
            refinanced_date: 'Date de rachat',
            repair_date: 'Date de réparation',
            last_presented_date: 'Dernière visite',
            birth_date: 'Anniversaire',
            call_date: "Date d'appel",
            delivery_date: 'Date de @:lexicon.delivery.singular prévue',
            waiting_sale: '@.capitalize:lexicon.sale.singular en att',
            delivered_date: 'Date de @:lexicon.delivery.singular',
            discounted_date: 'Escompté',
            csi_date: 'Date du CSI',
            provider_id: 'Source',
            form_id: 'Formulaire',
            appt_call_date: 'Date de rendez-vous téléphonique',
            appt_confirmation_date: 'Confirmation de rendez-vous',
            work_order_closure_date: 'Date de fermeture du W.O.',
        },
        exchangeVehicles: {
            all: 'Tous',
            stock_state: 'Stock State',
            stock: 'Stock',
            niv: 'VIN',
            category: 'Catégorie',
            make: 'Marque',
            model: 'Modèle',
            tire: 'Pneus @:lexicon.sold.singular',
            frequency: 'Fréquence',
            term: 'Terme',
            modality: 'Modalité',
            end_contract_date: 'Fin de contrat',
            institution: 'Institution',
            sold_date: 'Date de @:lexicon.sale.singular',
            sold_by: '@.capitalize:lexicon.sold.singular par',
            year: 'Année',
            version: 'Version',
            price: 'Prix',
        },
        wantedVehicles: {
            group_label: 'Véhicule recherché principal',
            all: 'Tous',
            stock_state: 'Stock State',
            stock: 'Stock',
            niv: 'VIN',
            category: 'Catégorie',
            make: 'Marque',
            model: 'Modèle',
            tire: 'Pneus @:lexicon.sold.singular',
            frequency: 'Fréquence',
            term: 'Terme',
            modality: 'Modalité',
            end_contract_date: 'Fin de contrat',
            institution: 'Institution',
            sold_date: 'Date de @:lexicon.sale.singular',
            sold_by: '@.capitalize:lexicon.sold.singular par',
            year: 'Année',
            version: 'Version',
            price: 'Prix',
        },
    },
    create: 'Création',
    edit: 'Modification',
    addAction: '+ Action',
    addCriterion: '+ Critère',
    addCriterionGroup: '+ Sous-critère',
    goToAutomation: 'Attention',
    goToAutomationDescription: 'Vos changements seront perdues, êtes-vous sûr de vouloir quitter cette page?',
    operators: {
        is: 'Est',
        isNot: "N'est pas",
        contains: 'Contient',
        notContains: 'Ne contient pas',
        defined: 'Est défini',
        undefined: "N'est pas défini",
        lessThan: 'Est plus petit que',
        greaterThan: 'Est plus grand que',
        lessThanNow: 'Est dans le passé',
        greaterThanNow: 'Est dans le futur',
        before: 'Est avant le',
        after: 'Est après le',
        isChecked: 'Est coché',
        isNotChecked: "N'est pas coché",
    },
    criteriaFields: {
        custom_fields: 'Champ personnalisé',
        customFields: {
            group_label: 'Champ personnalisé',
        },
        wantedVehiclesCustomFields: {
            group_label: 'Champ personnalisé - Véhicule recherché',
        },
        exchangeVehiclesCustomFields: {
            group_label: 'Champ personnalisé - Véhicule actuel',
        },
        customer: {
            group_label: 'Client',

            casl_consent: {
                confirmed_at: 'Date de la double adhésion',
                limit_date: "Date de l'adhésion tacite pour communiquer",
            },
        },
        lead: {
            group_label: 'Lead',

            appointment_date: 'Date du rendez-vous',
            approved: 'Approuvé',
            appt_call_date: 'Date du rendez-vous téléphonique',
            appt_confirmation_date: 'Date de confirmation du rendez-vous',
            available_date: 'Véhicule ici le',
            bdc_user_id: 'Agent BDC',
            be_back_date: 'Date du B-back',
            birth_date: "Date d'anniversaire",
            call_date: "Date d'appel",
            first_event_campaign_id: 'Événement',
            city: 'Ville',
            civility: 'Civilité',
            code: 'Code',
            commercial_id: 'Dir. commercial',
            communication_preference: 'Préférence de communication',
            created_at: 'Date de création',
            created_by: 'Personne qui a créé le lead',
            created_method: 'Méthode de création',
            dealer_tour: 'Dealer tour',
            delivered_by_id: 'Livreur',
            delivered_date: 'Date de @:lexicon.delivery.singular',
            delivery_date: '@.capitalize:lexicon.delivery.singular prévu',
            deliverable_date: 'Date @:lexicon.deliverable.singular',
            deposit: 'Dépôt',
            discounted_date: "Date d'escompte",
            division_id: 'Division',
            do_not_market: 'Do not market',
            end_contract_date: 'Date de fin de contrat',
            end_service_date: 'Date de fin de service',
            first_update_time: 'Temps de première modification (secondes)',
            form_normalized: 'Formulaire',
            fullname: 'Nom du lead',
            gas: 'Ravitaillé',
            import_file_name_normalized: 'Nom du fichier d\'import',
            inspected: 'Inspection',
            invoiced: 'Facturé',
            last_presented_date_normalized: 'Dernière visite',
            lead_bank_id: 'Institution',
            lead_type_id: 'Type du lead',
            locale: 'Langue',
            loyalty: 'Loyauté',
            non_consumer_initiated: 'Non généré par le consommateur',
            next_presented_date: 'Prochaine relance',
            odometer_last_visit: 'KM dern. visite',
            open_work_order_date: "Date d'ouverture du W.O.",
            paperwork_date: 'Date de dossier',
            planned_pick_up_date: 'Date de récupération prévue',
            prepaid: 'Prépayé',
            prepared: 'Préparé',
            presented_date: 'Date de visite',
            progress_state: "Processus d'approbation",
            promised_date: 'Heure promise',
            province: 'Province',
            separated_from_previous_sales_journey: "Séparé d'un précédent parcours de vente",
            source_id: 'Source',
            rating: 'Évaluation',
            reached_client: 'Client rejoint',
            refinanced_date: 'Date de rachat',
            refinanced_type: 'Type du rachat',
            repair_date: 'Date de réparation',
            repair_order: 'Repair order #',
            response_time: 'Temps de réponse (à partir du CRM)',
            result_normalized: 'Résultat',
            road_test_date: "Date d'essai routier",
            sale_by_phone: '@.capitalize:lexicon.sale.singular par téléphone',
            sale_date: 'Date de @:lexicon.sale.singular',
            service_advisor_id: 'Aviseur',
            service_agent_id: 'Agent service',
            sex: 'Genre',
            status: 'Statut',
            status_updated_at: 'Date de mise à jour du statut',
            status_updated_by: 'Personne qui a mit à jour le statut',
            take_over_date: 'Take over date',
            take_over_director_id: 'Directeur ayant fait le take over',
            unsubscribe_all_date: 'NPD.',
            unsubscribe_call_date: 'Désabonné Appels',
            unsubscribe_email_date: 'Désabonné Courriels',
            unsubscribe_sms_date: 'Désabonné SMS',
            updated_by: 'Personne qui a updaté le lead',
            user_id: 'Conseiller',
            walk_around: 'Walk around',
            work_order: '# W.O.',
            work_order_closure_date: 'Date de fermeture du W.O.',
            work_order_partial_closure_date: 'Date de fermeture partielle du W.O.',
            write_up: 'Quotation',
            result: 'Résultat',
            merged_id: 'Lead fusionné',
            newest_merged_id: 'Réception de lead fusionné',

            '@validLeadPhone': 'Téléphone valide',
            '@!validLeadPhone': 'Téléphone invalide',
            '@validLeadEmail': 'Adresse courriel valide',
            '@validLeadEmailTooltip': 'Au moins une addresse courriel valide',
            '@!validLeadEmail': 'Adresse courriel invalide',
            '@!validLeadEmailTooltip': 'Aucune adresse courriel valide',
            '@incomingCallCommunications': 'Au moins un appel entrant de plus de 30 secondes',
            '@!incomingCallCommunications': 'Aucun appel entrant de plus de 30 secondes',
            '@incomingEmailCommunications': 'Au moins un courriel entrant',
            '@!incomingEmailCommunications': 'Aucun courriel entrant',
            '@incomingSmsCommunications': 'Au moins un SMS entrant',
            '@!incomingSmsCommunications': 'Aucun SMS entrant',
            '@outgoingCallCommunications': 'Au moins un appel sortant de plus de 30 secondes',
            '@!outgoingCallCommunications': 'Aucun appel sortant de plus de 30 secondes',
            '@outgoingEmailCommunications': 'Au moins un courriel sortant',
            '@!outgoingEmailCommunications': 'Aucun courriel sortant',
            '@outgoingSmsCommunications': 'Au moins un SMS sortant',
            '@!outgoingSmsCommunications': 'Aucun SMS sortant',
        },
        account: {
            group_label: 'Concessionnaire',

            '@isInHoursNew': 'Concessionnaire neuf ouverte',
            '@!isInHoursNew': 'Concessionnaire neuf fermée',
            '@isInHoursUsed': 'Concessionnaire occasion ouverte',
            '@!isInHoursUsed': 'Concessionnaire occasion fermée',
            '@isInHoursService': 'Concessionnaire service ouverte',
            '@!isInHoursService': 'Concessionnaire service fermée',
        },
        vehicle: {
            accessories: 'Accessoires',
            accidented_damage_cost: 'Accident $',
            actual_value: 'Valeur réelle',
            balance: 'Solde',
            bank_id: 'Institution',
            budget_max: 'Budget max.',
            budget_min: 'Budget min.',
            calculator_value: 'Estimé',
            category: 'Catégorie',
            category_rv: 'Catégorie VR',
            condition: 'Condition',
            created_at: 'Date de création',
            documentation: 'Documentation',
            end_contract_date: 'Date de fin de contrat',
            end_warranty_date: 'Date de fin de garantie',
            frequency: 'Fréquence',
            fuel: 'Carburant',
            initial_cash: 'Comptant initial',
            intention: 'Intention',
            license_plate: 'Numéro de plaque',
            link: 'Lien',
            make: 'Marque',
            mileage: 'Odomètre',
            mileage_excess: 'Odo. excédentaire estimé',
            modality: 'Modalité',
            model: 'Modèle',
            offer: '# Offre',
            order_number: '# Comm.',
            payment: 'Paiement',
            payment_with_tax: 'Paiement avec taxes',
            preparation: 'Préparation',
            price: 'Prix',
            profit: 'Profit',
            purchase_date: "Date d'achat",
            rate: 'Taux',
            recall: 'Rappel',
            recorded_date: "Date d'enregistrement",
            requested: '$ Souhaité',
            residual: 'Résiduel',
            security_deposit: 'Dépôt sécurité',
            sold_date: 'Date de vente',
            sold: '@.capitalize:lexicon.sold.singular',
            sold_by: '@.capitalize:lexicon.sold.singular par',
            stock: 'Stock',
            stock_state: 'État de stock',
            suffix: 'Suffix',
            term: 'Terme',
            tire_type: 'Pneus',
            trade_type: 'Achat',
            transmission: 'Transmission',
            trim: 'Version',
            value: 'Valeur',
            estimated: 'Estimé',
            verified_by_id: 'Directeur ayant vérifié la @:lexicon.sale.singular',
            vin: 'NIV',
            weight: 'Poids',
            year: 'Année',
            year_max: 'Année max.',
            year_min: 'Année min.',

            '@hasPositiveEquity': 'Est en équité positive',
            '@!hasPositiveEquity': 'N\'est pas en équité positive',
        },
        wantedVehicles: {
            group_label: 'Véhicule recherché',
            group_abr: ' - R',
            stock_state: 'État de stock',
            recorded_date: "Date d'enregistrement",
            stock: 'Stock',
            niv: 'VIN',
            category: 'Catégorie',
            make: 'Marque',
            model: 'Modèle',
            tire: 'Pneus @:lexicon.sold.singular',
            frequency: 'Fréquence',
            term: 'Terme',
            modality: 'Modalité',
            end_contract_date: 'Fin de contrat',
            institution: 'Institution',
            sold_date: 'Date de @:lexicon.sale.singular',
            sold_by: '@.capitalize:lexicon.sold.singular par',
            year: 'Année',
            version: 'Version',
            price: 'Prix',
        },
        exchangeVehicles: {
            group_label: 'Véhicule actuel',
            group_abr: ' - A',
            stock_state: 'État de stock',
            recorded_date: "Date d'enregistrement",
            stock: 'Stock',
            niv: 'VIN',
            category: 'Catégorie',
            make: 'Marque',
            model: 'Modèle',
            tire: 'Pneus @:lexicon.sold.singular',
            frequency: 'Fréquence',
            term: 'Terme',
            modality: 'Modalité',
            end_contract_date: 'Fin de contrat',
            institution: 'Institution',
            sold_date: 'Date de @:lexicon.sale.singular',
            sold_by: '@.capitalize:lexicon.sold.singular par',
            year: 'Année',
            version: 'Version',
            price: 'Prix',
        },
        source: {
            group_label: 'Source',
            provider_id: 'Fournisseur',
        },
        nextVirtualEvent: {
            group_label: 'Prochain évènement virtuel',
            customer_virtual_event_link: 'Lien virtuel du client',
            user_virtual_event_link: 'Lien virtuel du conseiller',
        },
        other: {
            group_label: 'Autres',
            'lead@currentWeekday': 'Jours de la semaine',
            'lead@triggerByAssigned': 'Déclenché par un utilisateur assigné',
            'lead@!triggerByAssigned': 'Déclenché par un utilisateur non assigné',
            'lead@nextAppointmentDate': 'Date du prochain rendez-vous',
        },
        taskEvent: {
            group_label: 'Tâche / rendez-vous',
            start_at: 'Début',
            end_at: 'Fin',
            status: 'Terminé / Visite / @.capitalize:lexicon.delivered.singular',
            task_event_type_id: 'Type',
            user_id: 'Propriétaire',
            main_event: 'Tâche / rendez-vous principal',
            confirmed: 'Confirmé',
            canceled: 'Annulé',
            no_show: 'No Show',
            '@triggerByOwner': 'Déclenché par le propriétaire',
            '@!triggerByOwner': 'Déclenché par un autre utilisateur que le propriétaire',
            '@triggerByGuest': 'Déclenché par un invité',
            '@!triggerByGuest': 'Déclenché par un autre utilisateur que les invités',
            '@triggerByDeliveryUser': 'Déclenché par le livreur',
            '@!triggerByDeliveryUser': 'Déclenché par un autre utilisateur que le livreur',
            '@isLastTaskEvent': 'Est le dernier du même type',
            '@!isLastTaskEvent': 'N\'est pas le dernier du même type',
        },
    },
    actions: {
        sendEmail: {
            affectLeadResult: 'Affecte le résultat des leads',
            affectLeadResultTooltip: 'Les leads contactés par cette automatisation auront un résultat de "Tentative" au lieu de "Attente"',
            assignTo: 'Conseiller à assigner',
            assignToTooltip: "Ce conseiller sera assigné si le lead n'a pas déjà un conseiller",
            attachments: 'Pièces jointes',
            changeLocaleOrder: 'Changer la priorité',
            customEmail: 'sur mesure',
            category: 'Catégorie',
            sender: 'Expéditeur',
            fallback: 'Courriel de secours',
            fallbackSender: 'Expéditeur de secours',
            disabledFallBackEmailTooltip: "Vous devez choisir un expéditeur de secours à l'aide du menu déroulant ci-dessous",
            localePreference: 'Préférence',
            localePreferenceTooltip: 'Langue à utiliser pour le courriel',
            specifiedEmail: 'Email sur mesure',
            template: 'Modèle de lettre',
            title: 'Envoyer un courriel',
            useLeadLocale: 'Utiliser la langue du lead',
            useLeadLocaleTooltip: "À moins d'être spécifié par le fournisseur au moment de l'intégration, la langue par défaut est celle du compte.",
        },
        sendSms: {
            affectLeadResult: 'Affecte le résultat des leads',
            affectLeadResultTooltip: 'Les leads contactés par cette automatisation auront un résultat de "Tentative" au lieu de "Attente"',
            assignTo: 'Conseiller à assigner',
            assignToTooltip: "Assigner l'utilisateur au lead si le lead ne contient pas de conseiller.",
            category: 'Catégorie',
            fallback: 'Personne de secours',
            localePreference: 'Préférence',
            localePreferenceTooltip: 'Langue à utiliser pour le SMS',
            template: 'Modèle',
            title: 'Envoyer un SMS',
            useLeadLocale: 'Utiliser la langue du lead',
            useLeadLocaleTooltip: "À moins d'être spécifié par le fournisseur au moment de l'intégration, la langue par défaut est identique à celle du compte.",
            userToUse: 'De',
        },
        sendWebhook: {
            apiKey: "Clef de l'API",
            apiKeySaveNeeded: "La clef sera générée une fois l'automatisation créée.",
            fallbackEmail: 'Courriel de secours',
            provider: 'Fournisseur',
            title: 'Envoyer MAJ au fournisseur',
            url: 'Adresse de destination URL',
        },
        createTaskEvent: {
            title: 'Ajouter une tâche',
            taskTitle: 'Titre',
            taskTitleTooltip: "Si aucun titre n'est spécifié, le nom de l'automatisation + nom du lead sera utilisé ex: 'Rappel CSI - Jonh Doe'",
            taskTitlePlaceholder: 'Titre: {Type de tâche} - {Nom Lead}',
            type: 'Tâche',
            owner: 'Propriétaire',
            relativeFields: 'Assignés',
            roles: 'Rôles',
            ownerOptions: 'Utlisateur',
            backupOwner: 'Propriétaire de secours',
            backupOwnerTooltip: 'Si le champs spécifié pour le propriétaire est vide le 2e sera appliqué, si les 2 sont vides la tâche ne sera pas ajoutée.',
            guests: 'Invités',
            deliverySpecialist: 'Livreur',
            backupDeliverySpecialist: 'Livreur de secours',
            priority: 'Priorité',
            description: 'Description',
            duration: 'Durée',
            time: 'Heure',
            reminderPreference: 'Rappels',
            userReminderPreference: 'Préference propriétaire',
            userReminderPreferenceTooltip: 'Les préférences de rappel du propriétaire assigné seront utilisées',
            allowTaskAssignedAlert: 'Déclencher l\'alerte "Tâche assignée"',
            allowTaskAssignedAlertTooltip: 'Les tâches générées par l\'automatisation déclencheront l\'alerte "Tâche assignée" selon les préférences du propriétaire.',
        },
        sendNotification: {
            title: 'Envoyer une notification',
            division: 'Division',
            to: 'Envoyer à',
            users: 'Utilisateurs',
            frequency: 'Fréquence',
            daily: 'Journalière',
            weekly: 'Hebdomadaire',
            template: 'Modèle de notification',
            previewMessage: 'Aperçu de votre message courriel',
            previewNotification: 'Aperçu de votre message de notification',
            subject: 'Sujet',
            notification: 'Notification',
            email: 'Courriel',
            emailTooltip: 'Envoyer la notification par courriel',
            screen: 'Écran',
            screenTooltip: 'Envoyer la notification sur écran',
            push: 'Mobile',
            pushTooltip: 'Envoyer la notification sur mobile',
            templateTooltip: 'La notification sera envoyée dans la langue de l\'utilisateur',
            templateTranslationsMissingExcerpt: 'Toutes les langues du modèle doivent contenir un corps pour écran et mobile',
            guestGroups: 'Groupes d\'utilisateurs',
            once: 'Seulement une fois',
            delay: 'Temps entre deux notifications',
            noSelfNotification: 'Pas d\'auto notification',
            noSelfNotificationTooltip: 'En cochant cette case, vous ne serez pas auto-notifié si vous déclenchez cette automatisation.',
        },
        sendSystemNotification: {
            title: 'Envoyer une notification différée',
            to: 'Envoyer à',
            users: 'Utilisateurs',
            frequency: 'Fréquence',
            daily: 'Journalière',
            weekly: 'Hebdomadaire',
            notification: 'Notification',
            sms: 'SMS',
            email: 'Courriel',
            screen: 'Écran',
            push: 'Mobile',
        },
        sendConsentDoubleOptIn: {
            title: 'Envoyer un courriel double adhésion pour la loi canadienne anti-pourriel',

            fallbackSender: 'Expéditeur de secours',
            sender: 'Expéditeur',
            user: 'Utilisateur',
        },
        autoCloseLead: {
            title: 'Fermer le lead',
            lostReasonLabel: 'Raison de la perte du lead',
            lostReasonPlaceholder: 'Entrez une raison personnalisée',
            defaultLostReason: 'Fermé par automatisation',
        },
    },
    alerts: {
        index: {
            error: 'Échec de la récupération des automatisations',
        },
        store: {
            success: 'Automatisation créée avec succès',
            error: "Échec de la création de l'automatisation",
        },
        show: {
            error: "Échec de la récupération de l'automatisation",
        },
        update: {
            success: 'Automatisation mise à jour avec succès',
            error: "Échec de la mise à jour de l'automatisation",
        },
        destroy: {
            success: 'Automatisation supprimée avec succès',
            error: "Échec de la suppression de l'automatisation",
        },
    },
    rangeUnit: {
        minutes: 'Minutes',
        hours: 'Heures',
        days: 'Jours',
        weekdays: 'Jours ouvrable',
        weeks: 'Semaines',
        months: 'Mois',
        years: 'Années',
    },
    deleteModal: 'Voulez-vous vraiment supprimer cette automatisation?',
    retroactionModal: 'Appliquer cette automatisation sur tous les clients existants?',
    revalidateAutomationModal: 'Revalider les leads déjà affectés?',
    deactivateModal: 'Désactiver toutes les opérations planifiées qui ne se sont pas encore exécutées?',
    disableAutomation: 'Désactiver cette automatisation?',
    deactivateAutomationModal: 'Toutes les actions planifiées seront supprimées.',
    validation: 'Veuillez vérifier que tous les champs sont bien remplis',
    active: 'Actif',
    execution: {
        useNewModalTooltip: "Utiliser le nouveau modal pour la configuration de l'exécution?",

        criteria: {
            title: "Critères d'exécution",
            tooltip: "Définir des critères limitant l'exécution de l'action<br/><br/><b>†</b> Date de création du lead si aucun élément",

            none: 'Aucun critère',
            invalidatedCriteriaField: "Certains critères d'exécution n'étaient plus valides suite à la suppression d'un critère dans l'automatisation. Ils ont été modifiés afin d'utiliser le déclencheur \"À l'atteinte de tous les critères\"",

            disabledForRecurringExecutionTooltip: "Les critères d'exécution ne peuvent s'appliquer sur les actions avec récurrence",
            clearExecutionCriteriaWhenAddingRecurrence: "L'ajout d'une récurrence sur l'action va désactiver les critères d'exécution. Souhaitez-vous continuer avec ces changements?",

            conditionalExecution: "Sera exécuté {executionContext} <b>si</b> cette date satisfait les critères d'exécution suivants:",
            executionBasedOnExecutionCriteria: "Sera exécuté à la date la plus proche satisfaisant tous les critères d'éxécution suivants (ou immédiatement si la date obtenue est dans le passé):",

            quantifier: {
                label: 'Quantificateur',

                options: {
                    at_least: 'Au moins',
                    less_than: 'Moins de',
                },
            },
        },

        delayType: {
            label: 'Type',

            options: {
                none: 'Au moment de',
                exact_time: 'À cette date',
                same_day: 'Même jour que',
                exactly: 'Exactement',
            },
        },

        tooltips: {
            direction: "Détermine si la récurrence s'exécute dans le futur ou dans le passé",
            directionButtonFuture: "Changer la recurrence pour s'éxécuter dans le passé",
            directionButtonPast: "Changer la recurrence pour s'éxécuter dans le futur",
            iconInvalidField: "Votre délai n'était plus valide suite à la suppression d'un critère, il a été modifié pour utiliser l'option \"À l'atteinte de tous les critères\"",
            caslActionRequiresLimitDateField: 'Cette action requiert ce champ comme déclencheur',
        },
        direction: {
            future: 'futur',
            past: 'passé',
        },
        recurrence: {
            now: 'Exécuté immédiatement',
            daily: 'Quotidien',
            weekly: 'Hebdomadaire',
            monthly: 'Mensuel',
            yearly: 'Annuel',
        },
        typeOfTime: {
            field: 'À la même heure',
            custom: 'Heure personnalisée',
        },
        label: {
            unset: "Sélectionner le type d'exécution...",
            now: 'Executé',
            recurring: 'répété {occurence} fois dans le {direction} | répété {occurence} fois dans le {direction}',
            delay: '{amount} {unit} {direction}',
            noDelay: 'Aucun délai',
            field: 'à la {field}',
            fieldShort: 'à {field}',
            time: 'à {time}',
            exactTime: 'le {time}',
            theField: 'la {field}',
            at: 'à',
        },
        modal: {
            title: "Paramètres d'exécution",
            applyButton: 'Appliquer',
            noPreview: 'Saisir au moins une occurrence...',
            label: {
                recurrence: 'Récurrence',
                recurrence_direction: 'Direction',
                occurence: 'Répétition',
                delay: 'Délai',
                delay_direction: 'Moment du délai',
                field: 'Basé sur le moment de...',
                type_of_time: 'Heure de la tâche',
                time_of_day: 'À cette heure',
                schedule_aware: 'Horaire',
                exact_time: 'À ce moment précis',
                unit: 'Unité',
                during: 'pendant',
            },
        },
        fields: {
            trigger: "L'atteinte de tous les critères",

            customer: {
                casl_consent: {
                    confirmed_at: 'Date de la double adhésion',
                    limit_date: "Date de l'adhésion tacite pour communiquer",
                },
            },
            lead: {
                appointment_date: 'Date de rendez-vous',
                appt_call_date: 'Date de rendez-vous téléphonique',
                appt_confirmation_date: 'Date de confirmation de rendez-vous',
                available_date: "Date d'arrivée du véhicule",
                birth_date: "Date d'anniversaire",
                call_date: "Date d'appel",
                created_at: 'Date de création',
                deliverable_date: 'Date @:lexicon.deliverable.singular',
                delivered_date: 'Date de @:lexicon.delivery.singular',
                delivery_date: 'Date de @:lexicon.delivery.singular prévu',
                discounted_date: "Date d'escompte",
                end_contract_date: 'Date de fin de contrat',
                end_service_date: 'Date de la fin de son service',
                paperwork_date: 'Date de dossier',
                presented_date: 'Date de visite',
                promised_date: 'Heure promise',
                refinanced_date: 'Date de rachat',
                repair_date: 'Date de réparation',
                road_test_date: "Date d'essaie routier",
                sale_date: 'Date de @:lexicon.sale.singular',
                status_updated_at: 'Date mise à jours de son status',
                take_over_date: 'Date de Take over',
                be_back_date: 'Date du B-back',
                last_presented_date_normalized: 'Date de dernière visite',
                work_order_closure_date: 'Date de fermeture du W.O.',

                latest_task_date_criteria: 'Dernière tâche (†)',
                latest_completed_task_date_criteria: 'Dernière tâche complétée (†)',
                latest_uncompleted_task_date_criteria: 'Dernière tâche non-complétée (†)',
                latest_event_date_criteria: 'Dernier événement (†)',
                latest_completed_event_date_criteria: 'Dernier événement complété (†)',
                latest_uncompleted_event_date_criteria: 'Dernier événement non-complété (†)',
                latest_task_event_date_criteria: 'Derniere tâche/événement (†)',
                latest_completed_task_event_date_criteria: 'Derniere tâche/événement complété (†)',
                latest_uncompleted_task_event_date_criteria: 'Derniere tâche/événement non-complété (†)',
                latest_communication_date_criteria: 'Dernière communication (†)',
            },
            wantedVehicles: {
                created_at: 'Date de création',
                end_contract_date: 'Date de fin de contrat',
                end_warranty_date: 'Date de fin de garantie',
                purchase_date: "Date d'achat",
                recorded_date: "Date d'enregistrement",
            },
            exchangeVehicles: {
                created_at: 'Date de création',
                end_contract_date: 'Date de fin de contrat',
                end_warranty_date: 'Date de fin de garantie',
                purchase_date: "Date d'achat",
                recorded_date: "Date d'enregistrement",
                sold_date: 'Date de vente',
            },
            taskEvent: {
                start_at: 'Date de début',
                end_at: 'Date de fin',
            },
        },
    },
    errors: {
        fetchCommunicationAutomation: "Une erreur est survenue lors de la récupération de l'automatisation",
    },
};
