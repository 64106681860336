export default {
    alerts: {
        index: {
            error: 'Échec de la récupération des utilisateurs',
        },
        store: {
            success: 'Utilisateur créé avec succès',
            error: "Échec de la création de l'utilisateur",
        },
        show: {
            error: "Échec de la récupération de l'utilisateur",
        },
        update: {
            success: 'Utilisateur mis à jour avec succès\n',
            error: "Échec de la mise à jour de l'utilisateur",
        },
        destroy: {
            success: 'Utilisateur en cours de suppression...',
            error: "Échec de la suppression de l'utilisateur",
        },
        updateStatus: {
            success: 'Statut mis à jour avec succès\n',
            error: 'Échec de la mise à jour de votre statut',
        },
        mfaEnrollment: {
            success: 'Inscription MFA avec succès',
            error: "Erreur lors de l'activation du MFA. Veuillez réessayer",
        },
        mfaRemove: {
            success: 'Désactivation du MFA avec succès',
            error: 'Erreur lors de la désactivation du MFA. Veuillez réessayer',
        },
    },
    edit: {
        accessRenewalDirector: 'Accès directeur @:lexicon.portfolio.singular',
        accessRenewalDirectorTooltip: 'Permets de voir tous les leads de @:lexicon.portfolio.singular, peu importe l\'assignation.',
        account: 'Compte',
        accountFrequencyWarning: "Attention les notifications seront perdues si elles sont reçues à l'extérieur de l'horaire du concessionnaire. Vous pouvez choisir de les conserver en tout temps.",
        accountInformation: 'Informations liées au compte',
        accountMustHaveImapEmail: "Le compte doit avoir l'option de Courrier Imap pour activer cette option.",
        accountSchedule: 'Horaire du concessionnaire',
        activateMfaButton: "Activer l'authentification multifacteur",
        activeOptionAccount: "Activez l'option dans le compte",
        activityReport: "Rapport d'activités",
        addPhoto: 'Cliquez ici pour ajouter une photo de profil',
        allLead: 'Tous mes clients',
        allowAdvancedAutomationAccess: 'Automatisation avancé',
        allowAnalyticsAccess: 'Analytique (Outils BI)',
        allowAutomationAccess: 'Automatisation',
        allowEventCreation: "Autoriser la création d'événements",
        allowExportAccess: "Autoriser l'accès à l'export",
        allowImportAccess: "Autoriser l'accès à l'import",
        answer: 'Réponse',
        answerDescription: 'Temps pour répondre',
        answeredLeads: 'Leads répondu (par vos collègues)',
        answeredLeadsDesc: "Recevez une alerte chaque fois qu’un de vos collègues d'une même division répond à un lead en attente",
        anytime: 'Tout temps',
        approved: 'Approuvé',
        approvedAssignedTooltip: 'Recevez une alerte en temps réel lorsqu’un client sur lequel vous êtes assigné est approuvé au crédit',
        approvedCredit: 'Approuvé au crédit',
        approvedTooltip: 'Recevez une alerte en temps réel lorsqu’un client est approuvé au crédit',
        assignationLeadFollow: 'Suivi des assignations',
        assignationLeadFollowTooltip: 'Recevez une alerte pour chaque conseiller que vous assignez dans une fiche client, afin de faciliter vos suivis.',
        assignedLeads: 'Lead/Tâche/Événement assigné',
        assignedLeadsTooltip: 'Activez cette option pour recevoir une notification chaque fois qu’un client, une tâche ou un rendez-vous vous a été assigné',
        assignedLeadUpdated: 'Modification lead assigné',
        assignedLeadUpdatedDescription: "Activez cette option pour recevoir une notification lorsque quelqu'un modifie un lead qui vous est assigné",
        assignedLeadVehicleCreated: 'Nouveau véhicule pour un lead assigné',
        assignedLeadVehicleCreatedTooltip: 'Recevez une notification lorsqu\'un véhicule est créé pour un lead qui vous est assigné.',
        assignedLeadVehicleCreatedManager: 'Nouveau véhicule pour tous les leads',
        assignedLeadVehicleCreatedManagerTooltip: 'Recevez une notification lorsqu\'un véhicule est créé pour des leads de vos divisions.',
        assignedLeadVehicleUpdated: 'Véhicule modifié pour un lead assigné',
        assignedLeadVehicleUpdatedTooltip: 'Recevez une notification lorsqu\'un véhicule est modifié pour un lead qui vous est assigné.',
        assignedLeadVehicleUpdatedManager: 'Véhicule modifié pour tous les leads',
        assignedLeadVehicleUpdatedManagerTooltip: 'Recevez une notification lorsqu\'un véhicule est modifié pour des leads de vos divisions.',
        assignedNotice: 'Réponse à une note',
        assignedNoticeTooltip: "Recevez une alerte lorsque quelqu'un répond à votre note dans une fiche client",
        equityAlert: 'Équité',
        hideInUserSelect: 'Caché dans le compte "enfant"',
        noReassignationNotification: 'Pas de notification de réassignation',
        removeMfaButton: "Retirer l'authentification multifacteur",
        renewalAlert: '@.capitalize:lexicon.portfolio.singular',
        renewalAlertEquity: 'Équité positive',
        renewalAlertEquityDescription: 'Notification lorsqu\'un véhicule est en équité positive',
        renewalAlertEndLocation: 'Fin de location',
        renewalAlertEndLocationDescription: 'Notification lorsqu\'un contrat de location arrive à échéance',
        renewalAlertEndWarranty: 'Fin de garantie',
        renewalAlertEndWarrantyDescription: 'Notification lorsqu\'une garantie arrive à échéance',
        renewalAlertExcessMileage: 'Dépassement du kilométrage sous garantie prévu',
        renewalAlertExcessMileageDescription: 'Notification lorsqu\'on estime qu\'un client dépassera le kilométrage de sa garantie',
        auth: 'Authentification externe',
        authorizedDealer: 'Concessionnaire autorisé',
        automaticLeadInfo: 'Info lead automatique',
        automaticLeadInfoTooltip: 'Activez cette option pour recevoir un SMS au moment d’appuyer sur le 5 lorsque vous traitez une demande WebBoost',
        automationNotification: 'Notifications automatisées',
        automationNotificationTooltip: "(Nom de l'automatisation) - (Nom du modèle) - (Destinataire)",
        automationActionNotificationTooltip: "Vous serez notifié si vous êtes {1} sur un lead qui répond au critères de l'automatisation '{0}'",
        automationActionNotificationYouTooltip: "Vous serez notifié pour chaque lead qui répond au critères de l'automatisation '{0}'",
        availableAssignedTooltip: 'Recevez une alerte en temps réel lorsqu’un véhicule sur lequel vous êtes assigné a une date de disponibilité planifiée',
        availableDate: 'Véh. ici le',
        availableTooltip: 'Recevez une alerte en temps réel lorsqu’un véhicule a une date de disponibilité planifiée',
        bdcSuperAccess: 'Super accès BDC',
        bdcSuperAccessTooltip: "Donne à l'agent bdc les mêmes accès qu'un directeur BDC pour ce qui est de l'accès aux leads",
        before: 'avant',
        bellEventOption: 'Icône des tâches colonne "Clients"',
        bellEventOptionTooltip: "Faire afficher les tâches et les événements d'un lead dans les dashboards (sous forme d'icônes)",
        businessHours: "Heures d'affaire concessionnaire",
        calendar: 'Calendrier',
        calendar_options: 'Calendrier',
        campaign: 'Campagne',
        ccCommInbound: 'CC communications entrantes',
        ccCommInboundDescription: 'Les notifications de communication SMS et courriel entrantes seront aussi envoyées aux personnes sélectionnées',
        cellphone: 'Cellulaire',
        clientAnswer: "Réponse d'un client",
        clientAnswerTooltip: 'Recevez une alerte en temps réel lorsqu’un client répond à un de vos courriels envoyés avec l’outil de réponse du CRM',
        commentFollow: 'Suivi des commentaires',
        commentFollowTooltip: 'Recevez une alerte pour chaque commentaire que vous ajoutez dans une fiche client, afin de faciliter vos suivis.',
        commercialDirector: 'Directeur commercial',
        communicationInformation: 'Informations de communication',
        confirmation: 'Confirmation',
        copy: 'Copier',
        copyModifs: 'Copier les modifications',
        confirm: 'Confirmer',
        confirmationApptOption: 'Confirmation de rendez-vous',
        confirmationWhen: 'Quand le message de confirmation sera-t-il envoyé?',
        credit: 'Crédit',
        crmMax: 'CRM $',
        daily: 'Journalier',
        defaultAccount: 'Compte par défaut',
        defaultAccountTooltip: 'Sélectionnez le compte par défaut lors de la connexion',
        dateOptions: 'Date sur mesure',
        dateRangeTooltip: "Configurer l'écart de mois dans l'option de date des dashboards",
        declinedAssignationLead: 'Demande d\'assignation refusée',
        declinedAssignationLeadTooltip: "Recevez une alerte en temps réel lorsque votre demande d'assignation à un lead appartenant à un autre conseiller est refusée",
        defaultDashboard: 'Tableau de bord',
        defaultDashboardServiceTooltip: 'Le tableau de bord service est obligatoire si seul la division service est selectionné',
        defaultDashboardTooltip: 'Choisir le tableau de bord vers lequel vous serez redirigé lors de votre connexion',
        defaultTasksReminder: 'Rappel tâches par défaut',
        defaultNotification: 'Mes divisions',
        deleteUser: "Supprimer l'utilisateur",
        deletingUser: "Suppression en cours, peut prendre jusqu'à 1 minute",
        delivered: '@.capitalize:lexicon.delivered.singular',
        deliveredAssignedTooltip: 'Recevez une alerte en temps réel lorsqu’un véhicule sur lequel vous êtes assigné est @:lexicon.delivered.singular',
        deliveredTooltip: 'Recevez une alerte en temps réel lorsqu’un véhicule est @:lexicon.delivered.singular',
        deliveryAssignedTooltip: 'Recevez une alerte en temps réel lorsqu’un véhicule sur lequel vous êtes assigné a une date de @:lexicon.delivery.singular planifiée',
        deliveryDate: '@:lexicon.deliveryDate.singular',
        deliveryTooltip: 'Recevez une alerte en temps réel lorsqu’un véhicule a une date de @:lexicon.delivery.singular planifiée',
        departmentMoney: 'Dashboard $',
        disableMailClient: 'Désactiver le client de messagerie',
        division: 'Division',
        email: 'Courriel',
        emailVideo: 'Courriel vidéo',
        emailReadAlert: 'Confirmation de lecture',
        emailReadDescription: "Recevez une notification à l'ouverture de votre courriel par le destinataire",
        emailReadDisabled: "Incompatible avec l'option de courriel IMAP",
        endContractRequired: 'Fin de contrat obligatoire',
        endContractRequiredTooltip: "Force l'utilisateur à entrer une date de fin de contrat au moment de la @:lexicon.delivery.singular",
        event: 'Événement',
        eventBadService: 'Alerte de mauvais service',
        eventBadServiceDescription: "Notification qui vous sera envoyée lorsqu'un client a mentionné avoir reçu un mauvais service",
        eventCanceledAppointment: 'Rendez-vous annulé',
        eventCanceledAppointmentDescription: "Notification qui vous sera envoyée lorsqu'un rendez-vous de type événement est annulé",
        eventConfirmedAppointment: 'Rendez-vous confirmé',
        eventConfirmedAppointmentDescription: "Notification qui vous sera envoyée lorsqu'un rendez-vous de type événement est confirmé",
        eventLeadToReach: 'Client veut être rejoint',
        eventLeadToReachDescription: "Notification qui vous sera envoyée lorsqu'un client souhaite être rejoint dans le contexte d'un événement",
        eventNewAppointment: 'Nouveau rendez-vous',
        eventNewAppointmentDescription: "Notification qui vous sera envoyée lorsqu'un rendez-vous de type événement est créé",
        eventNewPhoneAppointment: 'Nouveau rendez-vous téléphonique',
        eventNewPhoneAppointmentDescription: "Notification qui vous sera envoyée lorsqu'un rendez-vous téléphonique de type événement est créé",
        every: 'Chaque ',
        externalNotificationMediumRequired: 'Au moins une option parmi courriel, sms et mobile doit être activée',
        fAndIState: 'État F&I',
        feedback: 'Retour d\'évaluation',
        feedbackTooltip: 'Recevoir une alerte lors de la réception d\'une évaluation',
        firstName: 'Prénom',
        freezeData: 'Bloquer les données $',
        freezeFinancialData: 'Bloquer les données financières',
        freezeFinancialDataTooltip: "Donne la permission de bloquer les données financière d'une fiche client",
        frequencySituation: 'Fréquence / Situation',
        gas: 'Plein effectué',
        gasAssignedTooltip: 'Recevez une alerte en temps réel lorsque le plein est effectué sur un véhicule sur lequel vous êtes assigné',
        gasTooltip: 'Recevez une alerte en temps réel lorsque le plein est effectué sur un véhicule',
        gender: 'Sexe',
        general: 'Général',
        goUser: "Accéder à l'utilisateur",
        group: 'Groupe',
        hasParentUser: "Ce champ doit être modifié à partir du profil de l'utilisateur parent",
        hideFIProfits: 'Cacher profits F&I',
        hideNumber: 'Cacher numéro (xxxx)',
        homepage: 'Acceuil',
        ignore: 'Ignorer',
        imapEmail: 'Courriel IMAP',
        incompleteTasksReminder: 'Rappel tâche incomplète',
        inspected: 'Inspecté',
        inspectedAssignedTooltip: 'Recevez une alerte en temps réel lorsqu’un véhicule sur lequel vous êtes assigné est inspecté',
        inspectedTooltip: 'Recevez une alerte en temps réel lorsqu’un véhicule est inspecté',
        inTurn: 'Tour de rôle',
        inTurnEdit: 'Modification Tour de rôle',
        inTurnEditTooltip: 'Permet un libre accès aux modifications du module Tour de rôle',
        inTurnRemoved: 'Utilisateur se retire du tour de rôle',
        invalidAlert: 'Leads invalides',
        invalidAlertTooltip: 'Recevez une alerte pour chaque lead mis comme invalide.',
        language: 'Langue',
        lastName: 'Nom',
        leadAddedToSalesJourney: 'Lead ajouté à un parcours de vente',
        leadDeletion: 'Suppression/restauration de clients',
        leadMerging: 'Fusion de clients',
        leadSeparatedFromSalesJourney: 'Lead séparé d\'un parcours de vente',
        leadTransfer: 'Transfert des leads',
        leadWaitingInRoom: 'Client en attente de conférence',
        leadWaitingInRoomTooltip: "Notification lorsqu'un client vous attend dans la vidéoconférence.",
        leadsWithoutDivision: 'Leads sans division',
        leadsWithoutPhone: 'Leads sans téléphone',
        leadsWithoutPhoneDescription: "Notification qui vous sera envoyée quand un lead WebBoost est sans téléphone valide et que leadXpress n'est pas activer",
        leadXpress: 'Lead Web',
        limitAudioAccess: 'Mes audios uniquement',
        limitAudioAccessDesc: "Cette fonction limite l'utilisateur à accèder à ses audios uniquement",
        limitCalendarTaskAccess: 'Mes tâches uniquement',
        limitCalendarTaskAccessTooltip: "Limite l'utilisateur à consulter uniquement ses tâches dans le calendrier",
        lostAlert: 'Leads perdus',
        lostAlertTooltip: 'Recevez une alerte pour chaque lead mis comme perdu.',
        loyalty: 'Loyauté',
        newLeadLoyaltyNotif: 'Nouveau lead loyauté reçu',
        newLeadLoyaltyNotifTooltip: 'Recevez une alerte en temps réel lorsqu’une nouvelle demande internet de type Loyauté est reçue',
        newLeadLoyaltyDuplicateNotif: 'Nouveau lead loyauté indiqué doublon reçu',
        newLeadLoyaltyDuplicateNotifTooltip: 'Recevez une alerte en temps réel lorsqu’une nouvelle demande internet de type Loyauté indiquée doublon est reçue',
        mailClientAlreadyActivated: 'Le client de messagerie est déjà activé',
        masterAccountPreferences: 'Les préférences des utilisateurs "parents" seront dupliquées par défaut à la création dans les comptes enfants. Il est possible de les modifier individuellement par la suite.',
        mentions: 'Mentions',
        mentionsTooltip: 'Recevez une alerte quand on vous mentionne dans une note',
        messenger: 'Facebook Messenger',
        mfaCanOnlyBeUpdatedBySelf: "L'authentification multifacteur ne peux être modifiée que par l'utilisateur lui-même",
        mfaEnforced: 'La concession exige une authentification multifacteur',
        mfaTitle: 'Authentification multifacteur',
        mfaUnableRetrieveStatus: "Impossible de récupérer le status d'inscription",
        missedWbTooltip: 'Recevez une alerte si un appel WebBoost est manqué',
        push: 'Mobile',
        modulesAndOptions: 'Modules & options',
        name: 'Nom',
        needEquityAlert: 'Équité doit être actif',
        needRenewal: '@.capitalize:lexicon.portfolio.singular doit être activé',
        needRenewalAlert: 'Alerte @:lexicon.portfolio.singular doit être activé',
        needRenewalAlertConfig: 'La configuration de l\'alerte doit être complétée dans les préférences du compte',
        needEvent: 'Événement doit être activé',
        needInTurn: 'Tour de rôle doit être activé',
        needLeadXpress: 'Lead Web doit être activé',
        needLeadXpressAndNotWebBoost: 'Vous devez avoir Lead Web sans WebBoost pour activer cette option',
        needNioText: 'NioText doit être activé',
        needService: 'Service doit être activé',
        needToLogOnceBeforeActivatingPush: 'Vous devez d\'abord vous connecter dans l\'application avant de pouvoir activer les notifications mobiles',
        needVideoConference: 'Vidéoconférence doit être activé dans le compte',
        needWebBoost: 'WebBoost doit être activé',
        needWebBoostAndNotLeadXpress: 'Vous devez avoir WebBoost sans Lead Web pour activer cette option',
        newerThan: 'Moins de X mois',
        newLeadAlert: 'Avis nouveau lead',
        newLeadNotificationTooltip: 'Vous faites partie de la procédure Lead Web, vous devez avoir au moins un moyen de contact',
        newLeadDuplicateReceived: 'Nouveau lead doublon reçu',
        newLeadDuplicateReceivedTooltip: 'Recevez une alerte en temps réel lorsqu’une nouvelle demande internet indiquée doublon est ajoutée dans Lead Web',
        newLeadReceived: 'Nouveau lead reçu',
        newLeadReceivedTooltip: 'Recevez une alerte en temps réel lorsqu’une nouvelle demande internet est ajoutée dans Lead Web',
        newNoteOnAssignedLead: 'Nouvelle note sur un lead qui vous est assigné',
        newNoteOnAssignedLeadTooltip: 'Recevez une alerte quand une note est laissé sur un lead qui vous est assigné',
        nextInTurn: 'Prochain à répondre',
        nextStep: 'Prochaine étape',
        nextStepMandatory: 'Prochaine étape obligatoire',
        nextStepMandatoryTooltip: 'La fenêtre "Prochaine étape" doit absolument être remplie',
        nextStepTooltip: 'Affiche l\'option "Prochaine étape"',
        nextToReply: 'Prochain à répondre',
        nioText: 'NioText',
        nioTextIncoming: 'SMS entrant',
        nioTextIncomingDescription: 'Notification qui vous sera envoyée quand un client vous répond',
        notAnsweredLead: 'Appel WebBoost manqué',
        notifications: 'Notifications',
        notificationByDivision: 'Divisions',
        notificationsTransfer: 'Transfert de notifications de communication',
        notificationsTooltip: 'Ce transfert est obligatoire',
        noUserInTurn: 'Aucun conseiller disponible',
        obtainedMeeting: 'Rendez-vous obtenu',
        obtainedMeetingTooltip: 'Recevez une alerte en temps réel lorsqu’un rendez-vous est obtenu pour un client',
        obtainedSale: '@.capitalize:lexicon.sale.singular obtenue',
        obtainedSaleAssignedTooltip: 'Recevez une alerte en temps réel lorsqu’une @:lexicon.sale.singular est obtenue pour un client sur lequel vous êtes assigné',
        obtainedSaleTooltip: 'Recevez une alerte en temps réel lorsqu’une @:lexicon.sale.singular est obtenue pour un client',
        deposit: 'Dépôt obtenu',
        depositAssignedTooltip: 'Recevez une alerte en temps réel lorsqu’un dépôt est obtenu pour un client sur lequel vous êtes assigné',
        depositTooltip: 'Recevez une alerte en temps réel lorsqu’un dépôt est obtenue pour un client',
        olderThan: 'Plus de X mois',
        optionsMoney: 'Options',
        paperwork: 'Dossier',
        paperworkAssignedTooltip: 'Recevez une alerte en temps réel lorsque le dossier d\'un client sur lequel vous êtes assigné est complété',
        paperworkTooltip: 'Recevez une alerte en temps réel lorsque le dossier est complété',
        password: 'Mot de passe',
        personalInformation: 'Informations personnelles',
        phoneNumber: 'Téléphone',
        phoneProviderWarning: 'Désactiver cet utilisateur retirera son profil du système téléphonique d\'Activix. Voulez-vous continuer?',
        phoneUp: 'Phone-up',
        post: 'Poste',
        preferences: 'Préférences',
        preferred: 'Par défaut',
        preferredTooltip: "Utiliser pour choisir le numéro à afficher pour l'attribut téléphone conseiller dans les modèles de lettre",
        preparation: 'Préparation',
        prepared: 'Préparé',
        preparedAssignedTooltip: 'Recevez une alerte en temps réel lorsqu’un véhicule sur lequel vous êtes assigné est préparé',
        preparedTooltip: 'Recevez une alerte en temps réel lorsqu’un véhicule est préparé',
        profilePictureTooLarge: 'La résolution de votre image de profile doit être moins de 2000px/2000px',
        reassignationLeadDemand: 'Réassignation du lead',
        reassignationLeadDemandTooltip: "Recevez une alerte en temps réel lorsqu'un conseiller fait une demande pour s'assigner sur un de vos leads.",
        reenableMailClient: 'Ré-activer le client de messagerie',
        renewal: '@.capitalize:lexicon.portfolio.singular',
        restrictedNotifications: 'Les notifications ont été modifiées de SMS à Écran et Mobile à la demande de la direction. Communiquez avec nous pour les réactiver.<br><br>support@activix.ca ou 1 866 430-6767',
        restrictedNotificationsWarning: 'Attention, les notifications ont été modifiées de SMS à Écran et Mobile à la demande de la direction.',
        restrictInterdealerLeads: 'Cacher leads interconcessionnaires inaccessibles',
        restrictEmailTemplateEdition: 'Modifier les modèles de courriel',
        returnToMarket: 'Retour sur le marché',
        returnToMarketTooltip: 'Recevez une alerte quand un lead retourne sur le marché',
        ringtone: 'Sonnerie',
        ringtoneDescription: 'Temps de sonnerie',
        role: 'Rôle',
        roles: 'Rôles',
        saleTable: 'Tableau des @:lexicon.sale.plural',
        screen: 'Écran',
        selectAll: 'Tout sélectionner',
        selectUser: 'Sélectionner l\'utilisateur qui va reçevoir les données',
        service: 'Service',
        serviceMyClientsAppointments: 'Rendez-vous service "Mes Clients @:lexicon.sold.plural"',
        serviceMyClientsAppointmentsDescription: "Notification qui vous sera envoyée lorsqu'un de vos clients @:lexicon.sold.plural (en tant que conseiller ou BDC) obtient un rendez-vous au service",
        serviceMyClientsVehiclePickUp: 'Ramassage du véhicule "Mes Clients services"',
        serviceMyClientsVehiclePickUpDescription: 'Notification qui vous sera envoyée au moment où la date de récupération prévue est entrée pour un de vos clients service',
        serviceTooltip: 'La division service doit être sélectionnée pour que vous puissiez activer cette option',
        serviceSuperAccess: 'Super accès service',
        serviceSuperAccessTooltip: "Donne à l'agent service accès à tous les leads",
        serviceVehiclePickUp: 'Ramassage du véhicule',
        serviceVehiclePickUpDescription: "Notification qui vous sera envoyée au moment où la date de récupération prévue est entrée pour n'importe quel client service",
        showEstimatevalue: 'Valeur estimée des véhicules',
        showEstimatevalueTooltip: 'Afficher la valeur estimée des véhicules ($)',
        showInbox: 'Boite de réception',
        showInboxTooltip: 'Donne accès à la boite de réception dans le menu',
        signature: 'Signature',
        signature_en: 'Signature EN',
        signature_fr: 'Signature FR',
        sms: 'SMS',
        smsCosts: 'Frais SMS',
        stateChanged: 'État du lead mis à jour',
        stateChangedTooltip: "Recevez une alerte en temps réel lorsque l'état est mis à jour pour un client",
        supplier: 'Fournisseur',
        supplierInformation: 'Informations de fournisseurs',
        taskEventUnassigned: 'Tâche/Événement créé sans assignation',
        taskEventUnassignedToolTip: 'Activez cette option pour recevoir une notification chaque fois qu’une tâche, un rendez-vous ou un @:lexicon.delivery.singular sans assignation est créé.',
        taskDeleted: 'Tâche/évenement supprimé',
        taskEventTransfer: 'Transfert des tâche et événements',
        remindersTransfer: 'Transfert des rappels',
        sharedViewsTransfer: 'Transfert des vues partagées',
        taskEventDatesUpdated: 'Changement de date sur une tâche/événement',
        taskEventStatusUpdated: 'Changement de statut sur une tâche/événement',
        team: 'Équipe',
        teamCalendar: "Calendrier d'équipe",
        title: 'Titre',
        toDeliverOn: '@.capitalize:lexicon.deliveryDate.singular',
        tradeReport: "Rapport d'achat",
        tradeReportTooltip: 'Donne accès aux champs du module rapport d\'achat ("Valeur", "Valeur réelle", "Achat", "Notes")',
        transfer: 'Transfert',
        transferModalTitle: 'Transfert des données de l\'utilisateur',
        transferType: 'Sélectionner les types de transferts (Irréversible)',
        unselectAll: 'Tout déselectionner',
        untreatedLead: 'Alerte lead en attente',
        untreatedLeadDuplicate: 'Alerte lead doublon en attente',
        untreatedLeadDuplicateTooltip: 'Recevez une alerte lorsqu’un lead web doublon reste en attente',
        untreatedLeadTooltip: 'Recevez une alerte lorsqu’un lead web reste en attente',
        updateChildModalTitle: 'Copier les modifications aux utilisateurs enfants des comptes sélectionées',
        userFrequencyWarning: "Attention les notifications seront perdues si elles sont reçues à l'extérieur de votre horaire. Vous pouvez choisir de les conserver en tout temps.",
        users: 'Utilisateurs',
        username: "Nom d'utilisateur",
        userSchedule: "Horaire de l'utilisateur",
        vAutoUsernamePlaceholder: 'Utilisateur vAuto',
        vAutoUsernameTooltip: "Votre nom d'utilisateur vAuto afin d'être affecté comme vendeur lors des soumission de véhicule à vAuto",
        video: 'Vidéo',
        videoconference: 'Vidéoconférence',
        walkIn: '@.capitalize:lexicon.walkIn.singular',
        warningSmsActivation: 'Attention, l\'envoi d\'alerte SMS engendre des frais, assurez-vous de les utiliser seulement si nécessaire.<br><br><span class="italic text-base">Vous pouvez utiliser les notifications mobiles avec l\'application sans frais.</span>',
        webBoost: 'WebBoost',
        weekly: 'Hebdomadaire',
        webOrder: 'Commande Web',

        accountInfos: {
            childAccount: 'Comptes "enfants"',
        },
        disabledTooltips: {
            mandatoryOptions: 'Certaines options sont obligatoires',
            onlyDirectors: 'Seuls les directeurs peuvent modifier cette option',
            optionNotAvailable: 'Cette option n\'est pas disponible pour ce compte',
            webCrmRequired: 'Le CRM Lead Web est requis',
            webOrderRequiresIt: 'Le dashboard Commande Web nécessite cette option',
        },
        options: {
            canCreateChild: 'Création d\'utilisateur "enfant"',
        },
        personalAccessToken: {
            createNewToken: 'Créer un nouveau jeton',
            createToken: 'Créer jeton',
            custom: 'Autre...',
            newTokenMessage: "Voici votre nouveau jeton d'authentification personnel. C'est la seule fois où il sera affiché, ne le perdez pas! Vous pouvez maintenant utiliser ce jeton pour faire des requêtes API.",
            noAccessToken: "Aucun jeton d'authentification",
            revoke: 'Révoquer',
            revoked: 'Révoqué',
            revokeToken: "Révoquer ce jeton d'authentification?",
            scopes: 'Permission',
            title: "Jeton d'authentification personnel",
            notAllowedForParentUsers: 'Les clés API ne peuvent pas être créées pour les utilisateurs parent',
        },
    },
    global: {
        goToAccount: 'Accéder à la fiche du compte',
        impersonate: 'Se connecter en tant que',
    },
    list: {
        deleteModal: 'Voulez-vous vraiment supprimer cet utilisateur ?',
        hasSuspendedUsers: 'Impossible d\'ajouter de nouveau utilisateur durant une suspension',
        hideInactive: 'Cacher inactif',
        showInactive: 'Afficher inactif',
        table: {
            headings: {
                name: 'Nom',
                phones: 'Téléphones',
                email: 'Courriel',
                role: 'Rôle',
                divisions: 'Divisions',
                locale: 'Langue',
                lastActive: 'Dernière activité',
            },
        },
    },
};
