<template>
    <div class="w-full" :class="{ 'flex items-center': sideBySide }">
        <div class="w-full px-3 label | block" :class="statusClass" v-if="readOnly">
            {{ statusString }}
        </div>

        <activix-popover
            class="status-picker | flex"
            trigger="click"
            placement="bottom"
            ref="ResultTimePopover"
            v-else-if="
                !onlyTime &&
                    !lead.isResultSet() &&
                    !guest &&
                    statusPickerIsEditable &&
                    authUser.hasAccessToEditAccount(lead.account_id)
            "
        >
            <template #trigger>
                <div class="label | flex-1 inline-flex justify-center items-center" :class="statusClass">
                    {{ statusString }}
                    <icon name="bold/arrow-down-1" class="ml-2" scale="0.55" />
                </div>
            </template>
            <template #default>
                <div class="w-27 p-1 justify-items-center">
                    <activix-button class="w-18 mb-2 mt-1 !p-0 border-none" @click="onResultSelected('pending')" v-if="pendingAvailable">
                        <span class="w-18 label label-warning hover:bg-orange-600 | block ">{{ $t('clientCard.pending') }}</span>
                    </activix-button>
                    <activix-button class="w-18 mb-2 !p-0 border-none" @click="onResultSelected('attempted')">
                        <span class="w-18 label label-primary hover:bg-blue-600 | block">{{ $t('clientCard.attempted') }}</span>
                    </activix-button>
                    <activix-button class="w-18 mb-2 !p-0 border-none" @click="onResultSelected('reached')">
                        <span class="w-18 label label-success hover:bg-green-600 | block">{{ $tc('clientCard.reached') }}</span>
                    </activix-button>
                </div>
            </template>
        </activix-popover>

        <activix-tooltip :content="tooltip('status')" v-else-if="showStatus">
            <div class="label | block" :class="statusClass">
                {{ statusString }}
            </div>
        </activix-tooltip>

        <div class="flex" :class="sideBySide ? 'ml-1' : 'mt-1'" v-if="showTime">
            <activix-tooltip :content="tooltip('response_time')">
                <span
                    class="label | flex-1"
                    :class="{ 'label-default': responseTimeApplicable || responseTimeNotApplicableMerged }"
                >
                    {{ responseTimeString }}
                </span>
            </activix-tooltip>

            <activix-tooltip :content="tooltip('first_update_time')">
                <span class="label label-default | flex-1 ml-1">
                    {{ firstUpdateTimeString }}
                </span>
            </activix-tooltip>
        </div>

        <add-communication :opened.sync="addCommunicationModalOpened" :lead="lead" />

        <slot name="right" />
    </div>
</template>

<script>
    import { padStart, camelCase } from 'lodash-es';

    import { mapActions, mapState } from 'pinia';

    // Components
    import { ActivixPopover, ActivixButton } from '@autosync/atx-activix-ui';
    import AddCommunication from '../modals/AddCommunication.vue';

    // Entities
    import LeadStatus from '../../entities/LeadStatus.js';
    import LeadType from '../../entities/LeadType.js';
    import DashboardType from '../../entities/DashboardType.js';
    import CommunicationMethod from '../../entities/CommunicationMethod.js';

    // Pinia
    import { useClientCardStore } from '../../store/modules/clientCard/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            AddCommunication,
            ActivixPopover,
            ActivixButton,
        },

        props: {
            lead: {
                type: Object,
                required: true,
            },
            sideBySide: {
                type: Boolean,
                default: false,
            },
            onlyTime: {
                type: Boolean,
                default: false,
            },
            dashboardType: {
                type: String,
                default: '',
            },
            readOnly: {
                type: Boolean,
                default: false,
            },
            computedResult: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                addCommunicationModalOpened: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            showTime() {
                return (
                    this.lead.calculate_time &&
                    !this.lead.created_by_user &&
                    [LeadType.EMAIL, LeadType.WEB_ORDER, LeadType.SMS].includes(this.lead.lead_type_id)
                );
            },

            responseTimeString() {
                let responseTime = empty(this.lead.merged_leads) ? '-' : 'N/A';

                if (this.lead.response_time !== null) {
                    responseTime = this.resultTimeHuman(this.lead.response_time);
                }

                return responseTime;
            },

            firstUpdateTimeString() {
                let firstUpdateTime = '-';

                if (this.lead.first_update_time !== null) {
                    firstUpdateTime = this.resultTimeHuman(this.lead.first_update_time);
                }

                return firstUpdateTime;
            },

            leadStatus() {
                if (this.computedResult) {
                    return this.lead.computed_result;
                }

                return this.lead.getResult();
            },

            statusString() {
                switch (this.leadStatus) {
                    case 'reached':
                        return this.$t('result.reached');
                    case 'attempted':
                        return this.$tc('result.attempted');
                    default:
                        return this.$t('result.pending');
                }
            },

            statusClass() {
                switch (this.leadStatus) {
                    case 'reached':
                        return 'label-success';
                    case 'attempted':
                        return 'label-primary';
                    default:
                        return 'label-warning';
                }
            },

            showStatus() {
                return !this.onlyTime && this.dashboardType != DashboardType.WEB_BOOST;
            },

            responseTimeApplicable() {
                return this.lead.response_time || (this.responseTimeString && empty(this.lead.merged_leads));
            },

            responseTimeNotApplicableMerged() {
                return !this.lead.response_time && !empty(this.lead.merged_leads);
            },

            connectedCommunications() {
                return this.lead.communications.filter(communication => {
                    return !['interrupted', 'error', 'invalid', 'pending'].includes(communication.status);
                });
            },

            hasOutgoingConnectedCommunications() {
                return this.connectedCommunications.some(communication => {
                    return communication.communication_type_id == 1 && communication.connected;
                });
            },

            hasMessengerCommunications() {
                return this.lead.communications.some(communication => {
                    return communication.communication_method_id == CommunicationMethod.MESSENGER;
                });
            },

            pendingAvailable() {
                return !this.connectedCommunications.some(communication => {
                    return (
                        communication.communication_type_id == 2 ||
                        (communication.communication_type_id == 1 &&
                            !['unanswered', 'pending'].includes(communication.status))
                    );
                });
            },

            statusPickerIsEditable() {
                return this.lead.account.manually_status;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            onResultSelected(newResult) {
                const currentResult = this.lead.getResult();

                if (newResult && currentResult) {
                    this.updateLeadAction(this.lead.id, { result: newResult });

                    if (
                        (currentResult === 'pending' && ['attempted', 'reached'].includes(newResult)) ||
                        (currentResult === 'attempted' && newResult === 'reached')
                    ) {
                        useClientCardStore().currentCommunication = {};
                        this.addCommunicationModalOpened = true;
                    }
                }
                this.$refs.ResultTimePopover.close();
            },

            tooltip(type) {
                switch (type) {
                    case 'status':
                        if ([LeadStatus.LOST, LeadStatus.INVALID, LeadStatus.DUPLICATE].includes(this.lead.status)) {
                            return `<strong> ${this.$t(`result.${camelCase(this.lead.status)}`)}</strong><br>${
                                this.lead.status == LeadStatus.LOST ? this.lead.lost_reason : ''
                            }`;
                        }

                        break;

                    case 'response_time':
                        if (this.responseTimeApplicable) {
                            return this.$t('clientCard.durationTooltip');
                        }
                        if (this.responseTimeNotApplicableMerged) {
                            return this.$t('clientCard.responseTimeNotApplicableMergedTooltip');
                        }

                        break;

                    case 'first_update_time':
                        if (!empty(this.firstUpdateTimeString)) {
                            return this.$t('clientCard.firstUpdateTimeTooltip');
                        }

                        break;
                }

                return '';
            },

            resultTimeHuman(timeInSeconds) {
                if (timeInSeconds == 'N/A') {
                    return '-';
                }

                // Calculate seconds
                if (timeInSeconds >= 0 && timeInSeconds < 60) {
                    return `${timeInSeconds} s`;
                }

                // Calculate minutes
                const minutes = Math.round(timeInSeconds / 60);

                if (minutes > 0 && minutes < 60) {
                    return `${minutes} m`;
                }

                // Calculate hours
                const hours = Math.floor(timeInSeconds / 3600);

                if (hours > 0) {
                    timeInSeconds -= hours * 3600;

                    const timeInMinutes = timeInSeconds >= 0 ? Math.round(timeInSeconds / 60) : 0;

                    return `${hours}h${padStart(timeInMinutes, 2, 0)}`;
                }

                return '-';
            },
        },
    };
</script>
