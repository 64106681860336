export default {
    dateTooltip: 'Date of the event: {eventDate}<br/>Date of the attribution: {attributionDate}',
    salesJourneyEvents: 'Sales journey events',
    viewSalesJourneyEvents: 'View sales journey events',

    replacePrimaryEvent: {
        confirmation: 'This attribution will replace some information in the destination lead.<br/>@:general.areYouSure',

        currentInformation: 'Current information',
        afterAttribution: 'After attribution',
    },
};
